import axios from "axios";
import React, { useEffect, useState } from "react";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import ModalStyle from "../../data/ModalStyle";
import SetPage from "../../data/SetPage";
import Localbase from "localbase";
import MockTests from "../../components/skeletons/MockTests";
import SearchIcon from "@mui/icons-material/Search";
const MockTestCategories = ({ account }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection("paths")
      .doc("mock_test_categories")
      .get()
      .then((data) => {
        setcategories(data.mock_test_categories);
        setloading(false);
      })
      .catch((error) => {});
  }, []);

  let db = new Localbase("db");
  const [categories, setcategories] = useState(null);

  const [search, setSearch] = useState("");

  const GetQuizCategories = async (payload) => {
    setloading(true);
    const data = new FormData();

    const res = await axios.post(
      ENDPOINT + "mock-test-categories.php",
      data,
      SecurityHeaders
    );

    if (res) {
      setloading(false);
      if (res.data.status === "success") {
        setcategories(res.data.data);
        setResponse(res.data.data);
        setInititalData(res.data.data);
        db.collection("paths").add(
          {
            mock_test_categories: res.data.data,
          },
          "mock_test_categories"
        );
      }
    }
  };

  useEffect(() => {
    GetQuizCategories();
    SetPage("Mock Tests");
  }, []);

  useEffect(() => {
    if (account) {
      if (account.purchases && account.purchases.length > 0) {
        setaccess(true);
      } else {
        setaccess(false);
      }
    }
  }, [account]);

  const [access, setaccess] = useState(false);
  const [modal, setmodal] = useState(false);

  const ViewQuiz = () => {
    if (access) {
    } else {
      setmodal(true);
    }
  };
  const [loading, setloading] = useState(true);

  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2" sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Student Access Only !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Login into your account to access this section
            <div className="d-flex justify-content-between mt-4">
              <button
                onClick={() => setmodal(false)}
                className="btn btn-sm  btn-outline-danger rounded-pill"
              >
                Cancel
              </button>
              <Link
                to="/login"
                className="btn btn-sm  btn-primary rounded-pill"
              >
                Login
              </Link>
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="d-flex sm-col-reverse flex-wrap mock-test-bg text-light w-100 min-vh-50">
        <div className="col-lg-7 ">
          <div className="col-lg-9 sm-p-15 pt-5 mx-auto">
            <h2>Expertly Crafted Mock Tests to Crack Your Dream Job</h2>
            <p className="text-light">
              Attempt a mock test to test your skills and know your strengths.
              Worry less about your mistakes , our expertised team will help you
              to correct your mistakes and prepare yourself for your upcoming
              challenges in future interviews and exams{" "}
            </p>
          </div>
        </div>

        <div className="col-lg-5 ">
          <img
            src="https://skillsuprise.com/resources/images/prepare-mock.png"
            className="w-100"
          />
        </div>
      </div>

      <div className="min-vh-100  interview-praparation pb-5">
        <div className="d-flex justify-content-end mt-5 mb-3 container">
      


          <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg-4 ">
            <SearchIcon className="ri-search-line  text-secondary" />
              <input
                onChange={handleSearch}
                type="text"
                className="border border-0"
                placeholder="Search"
              />
      
            </div>
        </div>

        <div className=" container-fluid container-lg container-xl container-xxl mt-4 d-flex  flex-wrap">
          {response ? (
            <>
              {response.map((item) => (
                <>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-11 p-4 sm-p-0 mx-auto">
                    <div className=" quiz-category  p-2">
                      <img
                        className="w-100 mb-3 br-4 prep-image-category"
                        src={item.image}
                      ></img>
                      <h5>{item.title}</h5>

                      <div className="d-flex text-secondary justify-content-between align-items-center">
                        <p className="sm-13">
                          <b>Total quizzes : </b>
                          {item.total_quizzes}
                        </p>
                        <p className="sm-13">
                          <b>Level : </b>
                          {item.level}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex text-primary justify-content-between mt-3">
                        <span></span>
                        {access ? (
                          <>
                            <Link
                              to={"/test-topics/mock-test/" + item.course_url}
                              className="btn text-primary btn-sm"
                            >
                              View Tests
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              to={"/test-topics/mock-test/" + item.course_url}
                              className="btn text-primary   btn-sm"
                            >
                              View Tests
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="mt-5 w-100 text-center">
                    <MockTests />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MockTestCategories;