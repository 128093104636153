import React, { useState } from 'react'
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Contact from '../../actions/support/Contact'
import TextField from '@mui/material/TextField';
import ModalStyle from '../../data/ModalStyle';
const DemoRequestModal = ({visibility,HandleClose,type}) => {

    const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [subject,setsubject] = useState("course-details-page")
const [username,setusername] = useState(null)
const [message,setmessage] = useState(null)
const [contact,setcontact] = useState(false)
const NewContact = async ()=>{

     const res = await Contact({payload:{
          username:username,
          phone:phone,
          email:email,
          subject:subject,
          message:message
     }})
     if(res){
           //  //console.log("contacted "+JSON.stringify(res))
     }
}
  return (
    <>
    
    <Modal
  open={visibility}
  onClose={HandleClose}

>
  <Box sx={ModalStyle} className="col-lg-6  p-2 rounded  p-0 col-md-6 col-sm-7 col-11 br-5">
  <div className=" ">
 
<div className="d-flex">
    <div className="col-lg-6 d-none d-sm-block position-relative p-0 demo-left ">

<img src={type==="contact"?"https://skillsuprise.com/resources/images/contact-girl.png":"https://skillsuprise.com/resources/images/demo-girl.png"} className='w-100 position-absolute left-0 bottom-0'></img>
    </div>
    <div className="col-lg-6 col-12 col-sm-6 col-md-6 col-12 p-3">
    <div className="d-flex justify-content-between">
{type==="contact"?
<>
<h4>Contact Us !</h4>
</>:
<>
<h4>Request A Free Demo !</h4>
</>}
<CloseIcon className="click" onClick={HandleClose}/>
     </div>
     <hr></hr>
    <form onSubmit={(e)=>{e.preventDefault();NewContact()}}>

<div className="">
 <TextField
  id="outlined-name"
  label="Full Name"
  className="w-100 mb-3"
  value={username}
  required
  type="text"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setusername(e.target.value)}
/>

<TextField
  id="outlined-name"
  label="Email"
  className="w-100 mb-3"
  value={email}
  required
  type="email"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setemail(e.target.value)}
/>
 </div>

 <div className="mb-3 d-flex">

 <TextField
  id="outlined-name"
  label="Phone"
  className="w-100 mb-3"
  value={phone}
  required
  type="number"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setphone(e.target.value)}
/>



 </div>

 <TextareaAutosize
 className="w-100  mb-3"
 value={message}
 type="text"
 onChange={(e)=>setmessage(e.target.value)}
  minRows={3}
  placeholder="Enter your message..."

/>

<button type="submit" className="btn btn-primary w-100">{type==="contact"?"Send Message":"Request Demo"}</button>


</form>
    </div>
</div>



 
    
   </div>
  </Box>
</Modal>

    </>
  )
}

export default DemoRequestModal