import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetEnrolledCourses from '../../actions/GetEnrolledCourses'
import LinearProgressWithLabel from '../../components/progress/LinearProgressWithLabel'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'
import VideoRecommendations from './VideoRecommendations'
import { Skeleton } from '@mui/material'
import Rating from '@mui/material/Rating';
import GettingStartedDashboard from '../intros/GettingStartedDashboard'
const DashboardCourseProgress = ({account}) => {
const {user} = useContext(AuthContext)
const [mycourses,setmycourses]= useState(null)
let db = new Localbase("db")
const NewGetMyCourses = async()=>{

  const res = await GetEnrolledCourses({user,limit:2})
  if(res){
    setloading(false)
    if(res.status==="success"){

      setmycourses(res.data)
      db.collection("paths").add({
        my_courses:res.data
      },"my_courses")
    }
   
    
  }
}
const [loading,setloading] = useState(true)
useEffect(()=>{
  db.collection("paths").doc("my_courses").get().then(data=>{
    setmycourses(data.my_courses)
  }).catch(error=>{
    
  })

  
  NewGetMyCourses({user})
},[])
  return (
    <div className="w-100 p-3 rounded bg-white">

{mycourses?
<>
<div className="d-flex w-100 mb-4 justify-content-between align-items-center">
        <h4 className="mb-0 pb-0">My Courses</h4>
        <Link className=" d-sm-block" to="/my-courses">View All</Link>
      </div>




{mycourses.map((course,index)=>(
  <>

<div  className="d-flex text-dark ">
  <div className="col-lg-4 col-md-4 col-sm-4 col-5 mr-10">
    <img  src={course.course_cover} className="w-100 br-4 course-progress-cover"/>
  </div>
     <div className="col-7   position-relative ">
          <h5 className="truncate truncate2 sm-16">{course.course_title}</h5>
          <div className="w-100">
          <LinearProgressWithLabel value={course.progress} />
          </div>
          {/* <div className="d-flex d-none text-center mb-2 align-items-center">
            <div className="mr-10">
            <h6 className="">Completed</h6>
           <p>{course.progress}%</p>
            </div>
            <div className="">
            <h6>Next Class</h6>
            <p>23rd July @ 6pm</p>
         
            </div>
         
          </div> */}
        <div className=" d-none d-md-flex align-items-center">
        <Rating name="read-only" value={0}  />
        <p className="p-0 m-0 ms-1">Submit Rating</p>
        </div>
          <Link to={"/course-dashboard/"+course.course_url+"/"+course.batch_id} className="btn-continue   d-sm-block">Continue Couse</Link>
     </div>
</div>





{index===(mycourses.length-1)?
<>

</>
:<>
<hr className="mb-2 mt-2"/>
</>}




  </>
))}







</>:

<>
<div className="md-mb-4 mb-0">
<GettingStartedDashboard/>
</div>
{loading?
<>

<Skeleton variant="rectangular" height={90} className="w-100 mt-2 mb-2"/>


<Skeleton variant="rectangular" height={90} className="w-100 mt-2 mb-2"/>



</>

:
<>

</>}
{/*  <VideoRecommendations account={account}/>
*/}
</>}












    </div>
  )
}

export default DashboardCourseProgress