import React, { useContext, useEffect, useState } from 'react'
import GetVideos from '../../actions/GetVideos'
import SetPage from '../../data/SetPage'
import VideosList from './VideosList'
import Localbase from 'localbase'
import CircularProgress from '@mui/material/CircularProgress';
import DetectDevice from '../../data/DetectDevice'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import { AuthContext } from '../../context/AuthContext'
import { Helmet } from 'react-helmet'
import ShortsSkeleton from '../../components/skeletons/Shorts'
const VideosPage = ({account}) => {
const db = new Localbase()
const device = DetectDevice()
const [videos,setvideos] = useState(null)
const [suggestions,setsuggestions] = useState(null)
const [limit,setlimit] = useState(device==="mobile"?9:12)
const [totalvideos,settotalvideos] = useState(0)
const [loading,setloading] = useState(true)
const [nomore,setnomore] = useState(false)

const {user} = useContext(AuthContext)
useEffect(()=>{
  window.scrollTo(0,0)
  },[])
  const NewVideos = async()=>{
setloading(true)



    const res = await GetVideos(
      query?
      {payload:{limit:limit,query:query,SessionId:user.SessionId}}
      :
      {payload:{limit:limit,SessionId:user?user.SessionId:null}}
    )
    
    if(res){
      setloading(false)
    }
    if(res.status==="success"){
     
      setvideos(res.videos)
      if(res.videos){

        if(res.videos.length===res.total_videos){
          setnomore(true)
        }
        settotalvideos(res.videos.length)
      }
    
     
      setsuggestions(res.suggestions)
db.collection("paths").add({
  videos:res.videos
},"videos")




     
    }
  }
  useEffect(()=>{

   db.collection("paths").doc("videos").get().then(data=>{
    setvideos(data.videos)
   }).catch(error=>{

   })

if(videos){
setloading(false)
}


   SetPage("Videos")
NewVideos()
  },[limit])
const [query,setquery] = useState(null)

const SearchVideos = (e)=>{
  setvideos(null)
e.preventDefault()
NewVideos()
}


const LoadVideos = ()=>{

  const container = document.getElementById("container")
  if(container){

    const containerheight = container.clientHeight
    const scrolledheight = window.scrollY
    if(scrolledheight>(containerheight-700)){
     
    if(nomore){
    
    }
    else{
      if(device==="mobile"){
        setlimit(limit+9)
      }else{
        setlimit(limit+12)
      }
    
    }
    }
      
    
 

  }

}

useEffect(()=>{

  // window.addEventListener("scroll", LoadVideos);
  

},[])

  return (
    <div className="videos-page min-vh-90  pb-5 mb-3">

<Helmet>‍
        <title>Video Tutorials - Skills Uprise</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <div className=" w-100 tutorials-crumb-container">
      <div className="container mx-auto">
      <div className="videos-crumb  mx-auto">
<div className="text-center pt-4 text-white">
  <h3>Video Tutorials</h3>
  <div className="col-lg-7 mx-auto">
  <p className="light-grey">Learn more with comprehensive video tutorials on trending topics and crack job opportunities in an easiest way.</p>
  <Paper
      component="form"
      onSubmit={(e)=>SearchVideos(e)}
      className="mt-3 col-lg-9 col-md-9 col-12 mb-4 mx-auto"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
    >
     
      <InputBase
      onChange={(e)=>setquery(e.target.value)}
      value={query}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Videos"
        inputProps={{ 'aria-label': 'search videos' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      {query?
      <>
      <CloseIcon onClick={()=>setquery(null)}/>
      </>:  <SearchIcon />}
      </IconButton>
     
    </Paper>
  </div>
 </div>
</div>
      </div>
      </div>
     
    

     <div id="container" className="container sm-p-0 pt-2 pb-5 grid-1 grid lg-grid-4">
      {videos?
      <>
       <VideosList account={account} videos={videos}/>
      </>:null}
    
{loading?
<>
<ShortsSkeleton/>

</>:

<>
<div className="mb-5 mt-5 d-flex align-items-center justify-content-center">


</div>


</>}



   
     </div>
     <div className="mb-5 mt-5 d-flex align-items-center justify-content-center w-100">
   

     {query?
<>
{videos?
<>


</>:
<>
<p className="text-center">No results found for "<b>{query}</b>"</p>
  
</>}
</>:
<>

</>}


   {nomore?
   <>
   <p className="text-center">You are all caught up</p>
   </>:
   <>
   {loading?
   <>
       <CircularProgress  />
   </>:null}

   
   </>}
   
   
   </div>
    </div>
  )
}

export default VideosPage