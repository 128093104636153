import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import DetectDevice from '../../data/DetectDevice'
import SetPage from '../../data/SetPage'
import UserAccount from '../account/UserAccount'
import PersonalBadges from '../badges/PersonalBadges'
import QuizSection from '../quiz/QuizSection'
import DashboardCourseProgress from './DashboardCourseProgress'
import refer from '../../assets/images/REFER.svg'
import WorkshopsSuggestions from './WorkshopsSuggestions'
import referbanner from '../../assets/images/referral-banner.svg'
import WhatsappBotActivate from '../whatsapp/WhatsappBotActivate'
import VideosSlider from '../tutorials/VideosSlider'
import HackingInternshipBanner from './HackingInternshipBanner'
import CommunitySuggestions from '../community/CommunitySuggestions'
import Connections from '../connections/Connections'
import { Helmet } from 'react-helmet'
import GettingStartedDashboard from '../intros/GettingStartedDashboard'
import GetWalkthroughs from '../../actions/GetWalkthroughs'
import CourseSuggestions from '../community/CourseSuggestions'
const Dashboard = ({account,page}) => {

  const device = DetectDevice()
  useEffect(()=>{
    window.scrollTo(0,0)
 SetPage("Dashboard")
  },[])
  return (
  <>
  <Helmet>‍
        <title>Dashboard</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
  {account?
  <>
  <div className="grey-body">

  <div className="container promoters sm-p-0 pb-5 mb-5">
     <div className="flex-wrap justify-content-between pb-5 d-flex">

<div className="col-lg-7 col-md-12 col-sm-12 col-12 pt-0 sm-pt-0 pb-3">
{account?
<>
<UserAccount account={account}/>

</>:null}




<div className="mt-0 sm-mt-0 ">
{account?
<>

{device==="mobile"?
<>

</>:
<>

</>}


{account&&device==="mobile"?
<>
{account.purchases&account.purchases>0?
<>


</>:
<>


</>}


</>:
<>


</>}




<div className="d-flex sm-col-reverse">

{device==="mobile"?
<>
{/* <div className="mb-0 mt-3 lg-border sm-border-none br-5 sm-shadow-none  p-2 w-100">
<WorkshopsSuggestions account={account}/>
</div> */}
</>:null}


<div className="mt-0 mt-md-4 rounded border border-0 border-md-1 rounded w-100">
<DashboardCourseProgress account={account}/>
</div>



</div>



</>:null}

</div>


{device==="mobile"?
<>
<div className="mt-0 sm-p-15 mx-auto  md-mb-5 mb-0 d-flex">
  <Link to="/referral-program" className="d-flex align-items-center">
  <img className="w-100 br-5  " src={refer}/>

  </Link>

 
</div>

<div className=" sm-p-15 mx-auto">
<HackingInternshipBanner/>

</div>


</>:
<>

</>}

<div className="mt-5 py-3 bg-white sm-mt-10 rounded container">

<div className="d-flex justify-content-between">
<h4>My Badges</h4>
<Link to="/badges">View All</Link>
</div>
<hr></hr>
<div className="grid lg-grid-4  md-grid-4 grid-2 mt-3">

<PersonalBadges limit={device==="mobile"?6:12}/>
</div>

</div>


{device==="mobile"?
<>
{/* <div className="mt-5 sm-p-15 mb-3">
<HackingInternshipBanner/>
</div> */}


</>:
<>

</>}


</div>

<div className="col-lg-4 col-md-12 col-sm-12 col-12 pt-1">




{device==="mobile"?
<>

</>:
<>
<div className="mb-3 sm-mt-0 bg-white p-3 rounded">
<CourseSuggestions limit={4}/>
</div>





<div className="mt-3   d-flex">
  <Link to="/referral-program" className=" w-100 d-flex align-items-center">
  <img className="w-100 br-5 " src={refer}/>

  </Link>

 
</div>


{account?<>


<div className="mt-4 bg-white mb-5 p-3 sm-p-15">


  <CommunitySuggestions account={account} limit={10} />
</div>
 

</>:
<>


</>}


{/* <div className="mb-3 sm-mt-0 mt-3 bg-white p-3 rounded">
<WorkshopsSuggestions/>
</div> */}
<div className="mt-3   d-flex">
  <Link to="/ethical-hacking-internship" className="d-flex align-items-center">
  <img className="w-100 br-5  " src="https://skillsuprise.com/resources/images/hacking-internship.png"/>

  </Link>

 
</div>




</>}





</div>
     </div>

    </div>
  </div>
  
  
  
  </>
  :null}
  
  </>
  )
}

export default Dashboard