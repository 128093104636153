import axios from "axios";


import React, { useEffect, useState } from "react";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import Box from "@mui/material/Box";
import Localbase from "localbase";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";
import ModalStyle from "../../data/ModalStyle";
import SetPage from "../../data/SetPage";
import { PlayCircleOutline } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";

const InterviewPreparations = ({ account }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection("paths")
      .doc("interview_preparations")
      .get()
      .then((data) => {
        setcategories(data.interview_preparations);
        setloading(false);
      })
      .catch((error) => { });
  }, []);

  const [categories, setcategories] = useState(null);
  let db = new Localbase("db");
  const GetQuizCategories = async () => {
    setloading(true);
    const data = new FormData();
    const res = await axios.post(
      ENDPOINT + "get-interview-prep-categories.php",
      data,
      SecurityHeaders,
    );

    if (res) {
      setloading(false);
      if (res.data.status === "success") {
        setResponse(res.data.data)
        setInititalData(res.data.data)
        setcategories(res.data.data);
        db.collection("paths").add(
          {
            interview_preparations: res.data.data,
          },
          "interview_preparations",
        );
      }
    }
  };

  useEffect(() => {
    GetQuizCategories();
    SetPage("Interview Preparation");
  }, []);
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  return (
    <>
      <Helmet>
        ‍<title>Interview Preparation Kit - Skills Uprise</title>‍
        <meta
          name="description"
          content="Get ready to ace your next job interview with our comprehensive Interview Preparation Kit - Skills Uprise. Elevate your interview game with expertly crafted resources, from mock interviews and resume optimization to effective communication strategies. Boost your confidence, enhance your skills, and impress potential employers. Prepare for success today!"
        />
        <meta
          name="twitter:card"
          content="Get ready to ace your next job interview with our comprehensive Interview Preparation Kit - Skills Uprise. Elevate your interview game with expertly crafted resources, from mock interviews and resume optimization to effective communication strategies. Boost your confidence, enhance your skills, and impress potential employers. Prepare for success today!"
        />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="col-lg-4 col-md-5 col-sm-6 col-11 p-2 br-5"
          sx={ModalStyle}
        >
          <div className="modal-header">
            <h4> Student Access Only !</h4>
            <CloseIcon className="click" onClick={() => setmodal(false)} />
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {account ? (
              <>Please register a course to access this section</>
            ) : (
              <>Login into your account to access this section</>
            )}

            <div className="d-flex justify-content-between mt-4">
              <button
                onClick={() => setmodal(false)}
                className="btn btn-sm  btn-outline-danger rounded-pill"
              >
                Cancel
              </button>
              {account ? (
                <>
                  <Link
                    to="/courses"
                    className="btn btn-primary btn-sm  rounded-pill"
                  >
                    Browse Courses
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="btn btn-primary btn-sm  rounded-pill"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="dark-cover text-light min-vh-30 pt-4 ">
        <div className="container align-items-center justify-content-between sm-col-reverse d-flex flex-wrap">
          <div className="col-lg-7 col-sm-12 col-12 ">
            <h1>
              Ace your next job interview with our comprehensive interview
              preparation kits
            </h1>
            <h6 className="font-weight-normal mt-2 subtitle light-subtitle">
              Tailored for students to crack jobs from start-up's to MNC
              companies. From resume building and cover letter writing, to
              practice interview questions and mock interviews, we've got you
              covered
            </h6>
          </div>
          <div className="col-lg-4 col-sm-12 col-12">
            <img src="https://skillsuprise.com/resources/images/success-cover.png" className="w-100"></img>
          </div>
        </div>
      </div>

      <div className="min-vh-100 interview-praparation pb-5">
      <div className="d-flex justify-content-end mt-5 mb-3 container">
      <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg-4 ">
            <SearchIcon className="ri-search-line  text-secondary" />
              <input
                onChange={handleSearch}
                type="text"
                className="border border-0"
                placeholder="Search"
              />
      
            </div>
        </div>
        <div className="container mt-4 d-flex flex-wrap">
          {response ? (
            <>
              {response.map((item) => (
                <>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 p-4 sm-p-0 sm-mb-20">
                    <div
                      onClick={() => setmodal(true)}
                      className=" interview-prep-item   p-0"
                    >
                      <img
                        className="w-100 br-5 prep-image "
                        src={item.image}
                      />
                      <div className="p-3 mt-2">
                        <ul>
                          <li>{item.point1}</li>
                          <li>{item.point2}</li>
                          <li>{item.point3}</li>
                        </ul>
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="d-flex justify-content-between mt-1">
                        <button className="btn  rounded-pill btn-sm">
                          <PlayCircleOutline className="text-danger" /> Watch
                          Tutorial
                        </button>
                        <button className="btn  btn-sm rounded-pill text-primary">
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="mt-5 w-100 text-center">
                    <CircularProgress />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InterviewPreparations;
