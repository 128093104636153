import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import GetSacVideos from '../../actions/GetSacVideos';
import DetectDevice from '../../data/DetectDevice';
import Localbase from 'localbase';
import SlidingVideoItem from './SlidingVideoItem';

const VideosSlider = ({header,account,props,left,right,handleLeft,handleRight}) => {


  const db = new Localbase()
  

useEffect(()=>{
  db.collection("paths").doc("shorts").get().then(data=>{
    setcourses(data.shorts)
   }).catch(error=>{
  
   })
},[])

    const NewGetCourses = async()=>{
        const res = await GetSacVideos({payload:{
          mode:"live",
          limit:8,
          
        }});
        if(res){
        if(res.status==="success"){

        //console.log(res.videos)
          setcourses(res.videos)
          setaccess(res.access)
          db.collection("paths").add({
            shorts:res.videos
          },"shorts")
          
        }
        
         
        }
      }
const [access,setaccess] = useState(false)
      const [courses,setcourses] = useState(null);
useEffect(() => {
  NewGetCourses()
}, []);






var scrollAmount ;

  
const buttonclick = ()=> {
     var container = document.getElementById('container10');
     sideScroll(container,'right',15,300,10);
 };
 
 
 const back = ()=> {
     var container = document.getElementById('container10');
     sideScroll(container,'left',15,300,10);
 };
 
 function sideScroll(element,direction,speed,distance,step){
     scrollAmount = 0;
     var slideTimer = setInterval(function(){
         if(direction === 'left'){
             element.scrollLeft -= step;
         } else {
             element.scrollLeft += step;
         }
         scrollAmount += step;
         if(scrollAmount >= distance){
             window.clearInterval(slideTimer);
         }
     }, speed);
 }
 


const device = DetectDevice()


useEffect(()=>{
buttonclick()
},[left])
useEffect(()=>{
  back()
  },[right])
  const Animate = ()=>{
    const items = document.getElementsByClassName("animate")
   
    const screenheight = window.innerHeight;
    for (var i =0;i<=items.length;i++){
  const currentelement = items[i]
  
  if(currentelement){
    const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed())
   
    if(elementposition<screenheight){
      if(items[i].classList.contains("animation-active")){
    

        
      }else{
        items[i].classList.add("animation-active")
      }
    }
  
  }

  
  
  
     
    }
  }

  
  useEffect(()=>{
   // window.addEventListener("scroll",Animate)
      },[])
  return (
    <div className="w-100 pb-2">
    
{courses&&header?
<>
<div className="d-flex mb-2 justify-content-between align-items-center">
    <h4 className="left-blue-border">Popular Short Videos</h4>
    <Link to="/shorts" className="sm-10 btn btn-outline-primary rounded-pill">View All</Link>
    </div>
</>:
<>

</>}
   <div id="container10" className="courses-container mt-3 slider-videos ">
   <div   className="mt-1 courses-slider animate p-1">
{courses?
<>
{courses.map((course,index)=>(

    <>
    <SlidingVideoItem className={"fade-in-bottom delay0"+(4+index)+"s"} account={account} access={access} course={course}/>
    </>
)
    
)}

</>:null}

</div>

    </div>


{device==="mobile"?
<>

</>:
<>

<div className="d-flex d-none mt-3 justify-content-center">
<div className="mx-auto d-flex">
 
  
  <ChevronLeft onClick={()=>back()} className="slider-arrow me-2 click text-dark"/>

  <ChevronRight onClick={()=>buttonclick()}  className="slider-arrow ms-2 click text-dark"/>
</div>
</div>

</>}



    </div>
  )
}

export default VideosSlider
