import Avatar from '@mui/material/Avatar';
import ThumbDownIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOffAlt';
import React, { useContext } from 'react'
import RateReviewIcon from '@mui/icons-material/RateReview';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dateFormat from 'dateformat'
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useEffect, useState } from 'react'
import Rating from '@mui/material/Rating';
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../../data/ENDPOINT'
import { Offcanvas, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import { AuthContext } from '../../../context/AuthContext'
import SetPage from '../../../data/SetPage';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ProjectSubmit from './ProjectSubmit'
import ModalStyle from '../../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import { Feedback } from '@material-ui/icons';
import SubmitClassFeedback from '../../../actions/course/SubmitClassFeedback';
import TrainerSectionMain from '../../courses/TrainerSectionMain';
import { Download, RateReview, Share } from '@mui/icons-material';
import DetectDevice from '../../../data/DetectDevice';
import SocialShare from '../../../components/SocialShare';
import CommunityChat from '../../chat/CommunityChat';
import CourseBatchChat from '../CourseBatchChat';
const Recordings = ({ account, props }) => {
  const params = useParams();

  const videoid = params.videoid;
  const batchid = params.batch_id;
  const courseurl = params.course_url;
  const [read, setread] = useState(false)
  const { user } = useContext(AuthContext)
  const [currentduration, setcurrentduration] = useState(null);
  const [currenttopic, setcurrenttopic] = useState(null);
  const [currenttopicid, setcurrenttopicid] = useState(null);
  const [suggestions, setsuggestions] = useState(null)
  const [videos, setvideos] = useState(null);
  const [tags, settags] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currenttopic])

  useEffect(() => {
    getvideos()
  }, [params.videoid])

  const getvideos = async () => {
    fetch(`${ENDPOINT}get-recording.php?id=${videoid}&session=${user.SessionId}&batch_id=${batchid}&course_url=${courseurl}`)
      .then(r => r.json())
      .then(result => {
        setloading(false)
        //  //console.log(JSON.stringify(result))
        setvideos(result.current_video);
        setsuggestions(result.suggestions)
        setpreviousclasses(result.previous_classes)
        settags(result.tags)
        if (result) {
          if (videos) {

            let videoplayer = document.getElementById("videoplayer");
            videoplayer.play();
          }
        }
      })
  };
  useEffect(() => {

    // Add an event listener to the contextmenu event
    document.addEventListener('contextmenu', function (event) {
      // Prevent the default right-click behavior
      event.preventDefault();
    });
    getvideos();
  }, [])//get all topics when chapter changes
  useEffect(() => {
    const videoplayer = document.getElementById("videoplayer");
    videoplayer.play();
  }, [videos])//automatically play video when topic changes




  const changetopic = (e) => {
    e.preventDefault();

    if (currenttopicid) {
      if (document.getElementById(currenttopicid).classList.contains("video-active")) {
        document.getElementById(currenttopicid).classList.remove("video-active")
      }

    }
    ////  //  //console.log(videos[e.currentTarget.id].data_url)
    setcurrenttopic((videos[e.currentTarget.id]))
    setcurrenttopicid(e.currentTarget.id)
    document.getElementById(e.currentTarget.id).classList.add("video-active")
  }
  const hovered = (e) => {
    const currentvideo = e.target.id;
    const video = document.getElementById(currentvideo);
    video.controls = true;
    video.play();
  }

  if (currenttopic) {
    if ((currenttopic.data_url).ondurationchange) {
      ////  //  //console.log("changed")
    }
  }






  const durationChange = (e) => {




    const video = document.getElementById("videoplayer");

    video.ontimeupdate = function () {
      setcurrentduration(Math.round(video.currentTime))
      //  //  //console.log("rounded div"+Math.round(video.currentTime)%5)
      if ((Math.round(video.currentTime) % 10) === 0) {
        updateDuration({ duration: Math.round(video.currentTime) })


      }
    };










  }



  //update video watch duration
  const updateDuration = async () => {
    const formData = new FormData();
    formData.append('video_id', videoid);
    if (document.getElementById("videoplayer")) {
      formData.append('duration', document.getElementById("videoplayer").currentTime);
    }
    formData.append('session', user.SessionId)


    const res = await axios.post(ENDPOINT + "update-video.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  useEffect(() => {
    SetPage("Recordings")
  }, [])




  const addFavourite = async ({ vid, favid }) => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);

    formData.append('video_id', vid);


    const res = await axios.post(ENDPOINT + "add-favourite.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      if (res.data.status === "success") {
        const favitem = document.getElementById(favid);

        if (res.data.action === "liked") {

          favitem.classList.add("text-secondary")
          favitem.classList.add("text-danger")
          settoastmessage("Video added to favourites")
          settoast(true)
        }
        else if (res.data.action === "disliked") {
          settoastmessage("Video removed from favourites")
          favitem.classList.remove("text-danger")
          favitem.classList.add("text-secondary")
          settoast(true)
        }

      }
      else {

      }
    }
  }








  const [toastmessage, settoastmessage] = useState(null)
  const [previousclasses, setpreviousclasses] = useState(null)




  //add watch later


  const [toast, settoast] = useState(false)


  const addWatchLater = async ({ vid, markid }) => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);

    formData.append('video_id', vid);


    const res = await axios.post(ENDPOINT + "add-watch-later.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      if (res.data.status === "success") {
        const markitem = document.getElementById(markid);

        if (res.data.action === "added") {
          settoastmessage("Video added to watch later")
          markitem.classList.remove("text-secondary")
          markitem.classList.add("text-primary")
          settoast(true)
        }
        else if (res.data.action === "removed") {
          settoastmessage("Video removed from watch later")
          markitem.classList.remove("text-primary")
          markitem.classList.add("text-secondary")
          settoast(true)
        }

      }
      else {

      }
    }
  }

  const [submitting, setsubmitting] = useState(false)
  const SubmitFeedback = async () => {


    setsubmitting(true)
    const res = await SubmitClassFeedback({
      payload: {
        session: user.SessionId,
        class_id: classid,
        review: review,
        rating: value,
        batch_id: batchid,
        course_url: courseurl
      }
    })

    if (res) {
      setsubmitting(false)
      if (res.status === "success") {

        settoastmessage("Feedback submitted successfully")
        setmodal(false)
        getvideos()
        settoast(true)
      } else {
        settoastmessage("Something went wrong ")

        settoast(true)
      }
    }
  }

  const [loading, setloading] = useState(true)
  const [classid, setclassid] = useState(null)
  const [modal, setmodal] = useState(false)
  const [value, setValue] = useState(null);
  const [review, setreview] = useState(null)
  const [sharemodal, setsharemodal] = useState(false)
  const device = DetectDevice()
  const [path, setpath] = useState(null)

  useEffect(() => {

    if (sharemodal === false) {
      setpath(null)
    }
  }, [sharemodal])
  return (



    <>


      {device === "mobile" ?
        <>
          <Offcanvas className="share-post-canvas" show={sharemodal} placement={"bottom"} onHide={() => { setpath(null); setsharemodal(false) }} {...props}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Share Recording</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

              <>
                <SocialShare path={path} />

              </>

            </Offcanvas.Body>
          </Offcanvas>


        </> :

        <>
          <Modal
            open={sharemodal}
            onClose={() => { setpath(null); setsharemodal(false) }}
          >
            <Box sx={ModalStyle} className='p-3 rounded'>
              <div className="d-flex align-items-center justify-content-between">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Share Recording
                </Typography>
                <Close className='click' onClick={() => setsharemodal(false)} />

              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                <SocialShare path={path} />



              </Typography>
            </Box>
          </Modal>


        </>}


      <Modal
        open={modal}
        onClose={() => { setmodal(false) }}
      >
        <Box sx={ModalStyle} className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5">
          <div className="modal-header">
            <h4>Submit Class Feedback</h4>
            <Close className="click" onClick={() => setmodal(false)} />
          </div>
          <hr />
          <p className="mb-3"> {parseInt(value) === 1 ?
            <>
              Poor
            </> :
            <>
              {parseInt(value) === 2 ?

                <>
                  Below Average
                </> : <>

                  {parseInt(value) === 3 ?

                    <>
                      Average
                    </> : <>

                      {parseInt(value) === 4 ?

                        <>
                          Good
                        </> : <>

                          {parseInt(value) === 5 ?

                            <>
                              Excellent
                            </> : <>


                            </>
                          }
                        </>
                      }
                    </>
                  }
                </>
              }
            </>}</p>
          <form onSubmit={(e) => { e.preventDefault(); SubmitFeedback() }}>


          <input value={review} onChange={(e) => setreview(e.target.value)} className="mt-3 mb-3 w-100 form-control" placeholder="Write your feedback"></input>

            <Rating
              size="large"
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />

           

            <button className="btn mt-4  btn-primary btn-sm w-100" disabled={submitting}>{submitting ? "Submitting..." : "Submit Class Feedback"}</button>


          </form>
        </Box>
      </Modal>

      <div className="container-fluid position-fixed bottom-60 left-0 justify-center">
        <Toast className="bg-warning" show={toast} delay={6000} autohide onClose={() => settoast(false)}>
          <Toast.Header className='bg-warning'>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastmessage}</strong>

          </Toast.Header>
        </Toast>
      </div>


      <div class="video-page">
        <div className="container-fluid mt-20 d-flex flex-wrap">
          {/*video item start*/}
          <div className="col-lg-8 col-md-12 col-sm-12 col-12 video-container">
            <video id="videoplayer"
              className="w-100 bg-dark"

              src={videos ? videos.recording : ""} controlsList="nodownload" onMouseOver={hovered} onDurationChange={durationChange} ></video>



            <div className="flex current-video lg-mb-20 mt-2 sm-mb-0">
              <div className="video-data w-100">
                <h3 className="sm-18">{videos ? videos.class_title : ""}</h3>
                <p className="sm-12 truncate truncate3">{videos ? videos.class_description : null}</p>
                <hr />
                {videos && videos.class_starts ?
                  <>
                    <div className="flex w-100 justify-content-between align-items-center">
                      <div className="d-flex flex-wrap w-100 justify-content-between">
                        <div className="d-flex col-lg-6 col-md-6 col-12">
                          <div className="mr-15">

                            <div className=" sm-text-small d-flex">
                              <b>Date : </b>
                              <p>{dateFormat(videos ? videos.class_starts : null, 'dS mmm yyyy')}</p>
                            </div>

                          </div>



                          <div className="sm-text-small d-flex">
                            <b>Time : </b>
                            <p>{dateFormat(videos ? videos.class_starts : null, 'hh:mm tt')} - {dateFormat(videos ? videos.class_ends : null, 'hh:mm tt')}</p>
                          </div>

                        </div>

                        <div className="d-flex  col-lg-6 col-md-6 col-12 sm-mt-10">
                          {/*
  
  <a className='click' href={videos?videos.recording:null} download="file"><Chip className="click me-3" label={<><Download/>Download </>}/></a>
    
*/}
                          <Chip onClick={() => setsharemodal(true)} className="click me-3 " label={<><ShareIcon className='me-2'/>Share </>} />
                          <Chip onClick={()=>setmodal(true)} className="click" label={<><RateReview className='me-2' />Submit Feedback </>} />
                        </div>

                      </div>
                      {videos ? videos.notes ?
                        <>
                          <a href={videos.notes} className="btn btn-primary btn-sm">Download Notes</a>

                        </> : null : null}
                    </div>
                  </> :
                  <>
                  </>}

                {/*description section*/}



                <hr />
                {read ?

                  <>
                    <div className="container-fluid mt-3">


                    </div>
                  </>
                  : <>
                  </>



                }


              </div>
            </div>

          </div>




          {/*video container end*/}

          <div className="col-lg-4  col-md-12 col-sm-12 col-12 sm-p-0 suggestions-column ">


            <div className="bg-dark p-2">
              <h4 className='text-light'>Previous Class Recordings</h4>

            </div>
            <div >




              <hr className='mt-1' />


            </div>

            <div className="video-suggestions">


              {/*suggestion item*/}





              {previousclasses ? previousclasses.map((topic, index) => (


                <div topic={topic.id} id={index} className={topic.id === videoid ? "flex suggestion-item w-100 p-1 active-suggestion" : "flex suggestion-item w-100 p-1"} >

                  <div className="suggestion-cover-container mr-2">
                    <div type="button" className="rec-suggestion rounded" style={{ backgroundImage: `url(${topic.cover_pic})` }}>
                      <Link className="text-white" to={"/recordings/" + courseurl + "/" + batchid + "/" + topic.id}>
                        <div className="suggestion-cover rounded flex-column d-flex align-items-center justify-content-center  p-2" >

                          <h6>{dateFormat(topic.class_starts, "dS mmm  ")}</h6>
                          <hr className="mb-1" />

                          <div className="d-flex">

                            <p className="extra-small text-secondary mb-0">{dateFormat(topic.class_starts, "hh:mm tt ")}</p>
                            -
                            <p className="extra-small text-secondary mb-0">{dateFormat(topic.class_ends, "hh:mm tt ")}</p>

                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div class="suggestion-info pr-2 w-100">
                    <div className=" topic-title pr-2">
                      <div className="d-flex justify-content-between">

                        <Link to={"/recordings/" + courseurl + "/" + batchid + "/" + topic.id} type="button">
                          <h6 className=" topic-title  truncate truncate2">{topic.class_title}</h6>

                        </Link>


                      </div>

                      <p className="truncate truncate2 text-12 mb-0">{topic.class_description && topic.class_description !== "null" ? topic.class_description : null}</p>
                      <div className="d-flex mt-2">
                        {/*
    
    <a href={topic.recording} download="file">

    <Download className="me-3 click text-secondary"/>
    </a>
    */}
                        <Share onClick={() => { setpath('https://skillsuprise.com/recordings/' + courseurl + '/' + batchid + '/' + topic.id); setsharemodal(true) }} className="me-3 click text-secondary" />
                        {topic.feedback ?
                          <>
                            <RateReview className=" text-success" />

                          </> :
                          <>
                            <RateReview className="click text-secondary" onClick={() => { setclassid(topic.id); setmodal(true) }} />
                          </>}

                      </div>
                    </div>


                    <div className="flex d-none suggestion-actions">


                      <BookmarkIcon id={"mark" + topic.video_id} type="button" className={topic.watch_later ? "text-primary click" : "text-secondary click"} onClick={() => addWatchLater({ vid: topic.video_id, markid: 'mark' + topic.video_id })} />
                      <FavoriteIcon id={"fav" + topic.video_id} type="button" className={topic.favourite ? "text-danger click" : "text-secondary click"} onClick={(e) => { addFavourite({ vid: topic.video_id, favid: 'fav' + topic.video_id }) }} />

                    </div>

                  </div>



                </div>





              )) :
                <div className="w-100">


                  {loading ?
                    <>
                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                      <Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100" />

                    </> :
                    <>

                      <div className="p-2">
                        <p>No previous classes found</p>
                      </div>

                    </>}


























                </div>
              }













            </div>








          </div>

        </div>

        {/*video row end*/}

      </div>



    </>



  )
}

export default Recordings
