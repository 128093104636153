import React, { useEffect, useState } from "react";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import divider from "../../assets/bg/divider.png";
import Skeleton from "@mui/material/Skeleton";
import { Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import SecurityHeaders from "../../data/SecurityHeaders";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SetPage from "../../data/SetPage";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button';


const StudentReviews = () => {
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    SetPage("Reviews");
    const getReviews = async () => {
      try {
        const data = new FormData();
        data.append("limit", 30);

        const res = await axios.post(ENDPOINT + "get-reviews.php", data, SecurityHeaders);
        if (res) {
          if (res.data.status === "success") {
            setReviews(res.data.data);
          }
        } else {
          console.log("Fetching Reviews Error");
        }
      } catch (error) {
        console.log("get reviews Error ", error);
      }
    };
    getReviews();
  }, []);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const emptyStars = 5 - fullStars;
    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <StarIcon key={index} style={{ color: "gold" }} />
          ))}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <StarBorderIcon key={index} style={{ color: "gold" }} />
          ))}
      </>
    );
  };

  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  const value = 3.5;

  return (
    <>
      <Helmet>
        <title>Skills Uprise - Reviews | Students Feedbacks</title>
        <meta
          name="description"
          content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
        />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta
          name="twitter:description"
          content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
        />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta
          property="og:description"
          content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <div className="container-fluid pt-5 pt-md-0 bg-dark review-crumb">
        <div className="flex-wrap sm-p-15 tab-reverse pb-0 pt-5">
          <div className="col-lg-5 position-relative sm-pt-0 pt-5">
            <img
              src={"https://skillsuprise.com/resources/images/girl-smile.png"}
              className="w-75 position-absolute bottom-0 left-0"
              alt="Smiling girl"
            />
          </div>
          <div className="col-lg-7 col-sm-12 pt-5 sm-p-15">
            <div className="col-md-7 mx-md-auto">
              <h6 className="light-grey sm-text-center">Respected by professionals &</h6>
              <h1 className="sm-22 sm-text-center text-light">Loved By Everyone</h1>
              <p className="light-grey sm-text-center">
                This is what most of our students say about our trainings
              </p>
            </div>
            <hr className="text-light col-lg-8 col-sm-12" />
            <div className="col-lg-8 col-md-7 mx-md-auto col-sm-12 col-12 pb-5">
              <video
                controls
                controlsList="play pause volume seeking nodownload foobar"
                loop
                muted
                poster="https://skillsuprise.com/data/images/poster2.png"
                autoPlay
                id="prime-video1"
                className="w-100 bg-white br-5"
                preload="true"
                src="https://skillsuprise.com/data/videos/placement-training.mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <h2 className="sm-25 text-center text-primary">
          Here's What
          <br /> Our Students Say About Us!
        </h2>
        <div className="divider mb-3">
          <img src={divider} alt="divider" />
        </div>
      </div>

      <div className="container pt-5 col-lg-12 d-flex ">
        
          <div className="row col-12  ">
         
              <div className="col-lg-4 col-md-6 col-sm-12 col-12" >
                <div className="col-12 p-2">
                  <video className="w-100 rounded bg-dark" controls>
                    {/* <source   src={https://skillsuprise.com/data/videos/reviews/review${index + 1}.mp4} /> */}
                    {/* <source poster={https://skillsuprise.com/data/videos/reviews/review${index + 1}.png}
></source> */}
                  </video>

                </div>
              </div>


           </div>

      </div >




      <div className='continer-fluid '>
        <div className=' col-lg-11 col-md-10 col-sm-9 col-12 m-5 border p-4'>
          <div className='d-flex justify-content-between'>
            <div>
              <h2 className='ms-5 text-secondary'>STUDENTS REVIEW</h2>
              
            </div>
            <Button
              variant="contained"
              color="primary"
              className="h-25"
              startIcon={<BorderColorIcon />}
            >
              Write a review
            </Button>
          </div>
          <div className='p-3'>
            {reviews ? reviews.map((review, index) => (
                <div key={index}>
                  <div className='p-2'>
                    <div className='d-flex'>
                      <Avatar src={review.profile_pic} sx={{ width: 50, height: 50 }} />
                      <div className='ms-2'>
                        <h5>{review.name}</h5>
                        <div>
                          <Box
                            sx={{
                              width: 200,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Rating
                              name="text-feedback"
                              value={review.rating}
                              readOnly
                              precision={0.5}
                              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            <Box sx={{ ml: 2 }}>{labels[value]}</Box>
                          </Box>
                        </div>
                        <p className='mt-2'>
                          {review.review}
                        </p>
                        {/* <div className='d-flex'>
                          <ThumbUpOffAltIcon sx={{ width: 25, height: 25 }} />
                          <h5>(0)</h5>
                        </div> */}

                        <hr></hr>
                      </div>
                    </div>
                  </div>
                </div>

              ))
            :null
            

            }

          </div>

        </div>

      </div>
    </>
  );
};

export default StudentReviews;