import { Facebook, Instagram, LinkedIn, WhatsApp, YouTube } from '@material-ui/icons'
import React from 'react'
import { useEffect } from 'react'
import divider from '../../assets/bg/divider.png'
import { useState } from 'react'
import GetTrainer from '../../actions/course/GetTrainer'
import { Link } from 'react-router-dom'
const TrainerSectionMain = () => {
const [trainer,settrainer] = useState(null)
     const NewTrainer = async()=>{

const trainer_id = 1
const res = await GetTrainer({payload:{trainer_id:1}
     })
if(res){
     if(res.status==="success"){

          settrainer(res.data[0])
     }
   
}



     }


useEffect(()=>{
NewTrainer()
},[])

     return (
    <>
    
    <div className="container  mb-5 mt-5 mx-auto">
     <div className="text-center ">
          <h3 className='secondary-text'>Learn From the Best Trainer</h3>
          <h1 className='primary-text'>Who Trained DSP's of Andhra Pradesh</h1>
          </div>
          <div class="divider">
<img src={divider}  alt="divider"></img>
</div>
  

     <div className=" mt-3 d-flex justify-content-evenly flex-wrap sm-p-0 br-5 p-3">
  <div className="col-lg-6">
<img src="https://skillsuprise.com/resources/images/trainer.png" className="w-100" />
<div className="d-flex mt-3 mb-3 align-items-center">
     <h6 className="mb-0 pb-0 me-3">Follow on Social Media : </h6>
          <a className="me-3" target="_blank" href="https://www.linkedin.com/in/manojkoravangi/">
          <LinkedIn />
          </a>
          <a  className="me-3"  target="_blank"  href="https://api.whatsapp.com/send/?phone=%2B919490174876&text=hello&type=phone_number&app_absent=0">
  
          <WhatsApp className="text-success"/>
</a>
<a  className="me-3"  target="_blank"  href="https://www.instagram.com/manojkoravangi/">
<Instagram  className="text-danger"/>
</a>

<a  className="me-3"  target="_blank"  href="https://www.youtube.com/@manojkoravangi">
<YouTube  className="text-danger"/>
</a>

     
          
     </div>
  </div>

  <div className="col-lg-5">

    <a href="https://www.linkedin.com/in/manojkoravangi/" target="_blank">
    <h4>Manoj Kumar Koravangi</h4>
    </a>
     
     <h6>Founder & CEO | <a href="https://awetecks.com" target="_blank">Awetecks</a> | <Link target="_blank" to="/">Skills Uprise</Link></h6>

    
     <p>Manoj Kumar Koravangi have been empowering law enforcement and police personnel with cutting-edge training in ethical hacking and cyber security.He is a corporate trainer helping students, individuals and corporate teams to build a successful career by training them over 20+ on-demand technologies. He has 3+ years experience in successfully leading 20+ software development teams
           and trained over 5000+ individuals with 4.8/5 average rating in last 3 years. 
           
</p>

   <div className="d-none d-md-block">
   <b>Area's of Expertise</b>
     <br></br>
     <ul>
          <li>Ethical hacking & cyber security</li>
          <li>Data science </li>
          <li>Android development</li>
          <li>Website development - MERN & MEAN Stack, Python & Django</li>
          <li>Artificial Intelligence (AI) & Machine Learning (ML)</li>
          <li>Human Resource Management - HRM</li>
          <li>Internet of Things - IoT</li>
          <li>Block Chain & Cloud Computing </li>
          <li>Software Testing</li>
          <li>UI/UX Design</li>
          <li>Digital Marketing</li>
          
     </ul>
   </div>
     

 </div>

     </div>


    </div>
    </>
  )
}

export default TrainerSectionMain