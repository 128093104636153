import { Avatar } from '@mui/material'
import React from 'react'
import { useState,useEffect } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom'
import * as timeago from 'timeago.js';
import Box from '@mui/material/Box';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../context/AuthContext';
import ModalStyle from '../../data/ModalStyle';
import { Close, FavoriteBorder, MoreVert, Send } from '@material-ui/icons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FormatViews from './FormatViews';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Offcanvas from 'react-bootstrap/Offcanvas';
import StudentAccessModal from '../../components/modals/StudentAccessModal';
import ShareIcon from './ShareIcon';
import SocialShare from '../../components/SocialShare';
import SocialShareModal from '../../components/modals/SocialShareModal';
import { useHistory } from "react-router-dom";
const ShortItem = ({video,account,fullview}) => {

const {user} = useContext(AuthContext)
// has no dependency - this will be called on-component-mount



const [alert,setalert] = useState(false)

const [access,setaccess] = useState(false)


useEffect(()=>{
if(account){
  if(account.purchases){
    if(account.purchases.length>0){
      setaccess(true)
    }
  }
}
},[account])

const CloseStudentAccessModal = ()=>{

  setalert(false)
  }

  return (

    <>




<StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal}/>











    {account?

















    <>
    {access?
    <>
    {fullview?
    <>
      <Link  to={"/watch-short/"+video.video_id}  >

<Design video={video} setalert={setalert}/>
</Link>
    </>:
    <>
  
<Design video={video} setalert={setalert}/>

    
    </>}
  
    
    </>:
    <>

<Design video={video} setalert={setalert}/>
    
    </>}
     
    </>:
    <>
    

    <Design video={video} setalert={setalert}/>
    </>}
    
    </>
   
  )
}

export default ShortItem


const Design = ({video,setalert})=>{


  const history = useHistory();
  const [showcaption,setshowcaption] = useState(false)

const VideoClicked =()=>{
  if(showcaption){
    setshowcaption(false)
  }
}
const [share,setshare] = useState(false)
const [liked,setliked] = useState(false)
return (<>  


{/* <div className="click d-none" onClick={()=>{setalert(true)}}  >

<div id={video.video_id} className="video-item">
<div className="video-cover-container">
<img src={video.cover_image} className="w-100 "/>
 

</div>

<div className="info mt-2 text-dark">

<div className="d-flex">

</div>
<div className="d-flex w-100">
<Avatar src={video.profile_pic}/>
<div className="ml-15">
<h6 className="b-0 pb-0 sm-14 truncate truncate2 w-100">{video.title}</h6>
<p className="truncate truncate2">{video.description}</p>
<div className="d-flex align-items-center justify-content-between">
<p  className="extra-small">{video.posted_by}</p>
<p className="extra-small">{timeago.format(video.upload_date,"dS mmm")}</p>
</div>

</div>

</div>

</div>

</div>
</div> */}
<SocialShareModal handleClose={()=>setshare(false)} visibility={share} data={{title:"Share video",path:"https"}}  />
<Offcanvas placement='bottom' className="caption-canvas  inactive-caption" show={showcaption} onHide={()=>setshowcaption(false)}>
  
        <Offcanvas.Body onClick={()=>setshowcaption(false)} className='d-flex justify-content-start'>
        <div className=" position-relative caption-inner">
<div className="d-flex align-items-center">
<Avatar src={video.profile_pic} sx={{height:28,width:28}}/>
<div>   
  <h6 className='ms-1 p-0 m-0 text-light'>{video.posted_by}</h6>
</div>
</div>
<div className="pt-1 click" onClick={()=>setshowcaption(true)}>
<p  className='text-12 text-light'>{video.description?video.description:null}</p>

</div>
</div>
 {video.caption?video.caption:null}
        </Offcanvas.Body>
      </Offcanvas>


<div className="reel-item">   
<div className="reel-top d-flex align-items-center">
 <div onClick={()=>{history.goBack()}} className="container click d-flex align-items-center">
 <BackIcon className='text-light'/>
 <h6 className="p-0 m-0 ms-2">Short Videos</h6>
 </div>
</div>
<video onClick={()=>VideoClicked()} className='w-100'  src={video.url}></video>

{showcaption?<></>:<div className="reel-title ">
<div className="d-flex align-items-center">
<Avatar src={video.profile_pic} sx={{height:28,width:28}}/>
<div>   
  <h6 className='ms-1 p-0 m-0 text-light'>{video.posted_by}</h6>
</div>
</div>
<div className="pt-1 click" onClick={()=>setshowcaption(true)}>
<p  className='text-12 text-light truncate truncate2 '>{video.description?video.description:null}</p>

</div>
</div>}

<div className="reel-actions text-light">
 <div>
<div className="click " onClick={()=>setliked(!liked)}>{!liked? <FavoriteBorder />:<FavoriteIcon className='text-danger'/>}</div>
 <p>{video.likes?video.liked:0}</p>
 </div>
 <div onClick={()=>setshare(true)} className='mt-2'>   <ShareIcon className='send'/>
 <p>{video.shares?video.shares:0}</p>
 </div>
<div>  
  <MoreVert/>
</div>

</div>

</div>
 </>)

}