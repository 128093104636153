import Skeleton from "@mui/material/Skeleton";
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import CourseRewards from "./mycourses/CourseRewards";
import ProgressTracker from "./mycourses/ProgressTracker";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import dateFormat from "dateformat";
import defaultdp from "../../assets/icons/defaultdp.svg";
import DetectDevice from "../../data/DetectDevice";
import CourseDashboardStats from "../../actions/CourseDashboardStats";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";
import Localbase from "localbase";
import SetPage from "../../data/SetPage";
import TrackJourney from "../../actions/course/TrackJourney";
import ModalStyle from "../../data/ModalStyle";
import myclasses from "../../assets/dashboard/classes.svg";
import notesicon from "../../assets/dashboard/notes.svg";
import assignmentsicon from "../../assets/dashboard/assignments.svg";
import recordingsicon from "../../assets/dashboard/recordings.svg";
import projectsicon from "../../assets/dashboard/projects.svg";
import rewardsicon from "../../assets/dashboard/rewards.svg";
import supporticon from "../../assets/dashboard/support.svg";
import placementicon from "../../assets/dashboard/placement.svg";
import Alert from "@mui/material/Alert";
import ClassItem from "./mycourses/ClassItem";
import TeamLeaderboard from "./mycourses/TeamLeaderboard";
import { Download } from "@mui/icons-material";
import CourseBatchChat from "./CourseBatchChat";
import teamicon from "../../assets/images/teamicon.svg";
import ClassesCalander from "./mycourses/ClassesCalander";
import PreviousScheduleItem from "./PreviousScheduleItem";
import UploadStudentIdCard from "./UploadStudentIdCard";
import IDCardRequest from "./IDCardRequest";
const MyCourseDashboard = ({ account }) => {
  const params = useParams();
  const course_url = params.course_url;
  const batch_id = params.batch_id;
  const { user } = useContext(AuthContext);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    CourseJourney();
  }, []);
  let db = new Localbase("db");
  const [course, setcourse] = useState(null);
  const [classes, setclasses] = useState(null);
  const [recordings, setrecordings] = useState(null);
  const [lessons, setlessons] = useState(null);
  const [learnings, setlearnings] = useState(null);
  const Statistics = async () => {
    const res = await CourseDashboardStats({
      payload: {
        user: user.SessionId,
        course_url: course_url,
        batch_id: batch_id,
      },
    });

    if (res) {
      //  //  //console.log("dash "+JSON.stringify(res))
      if (res.status === "success") {
        setlessons(res.lessons);
        setcourse(res.course);
        setclasses(res.classes);
        setrecordings(res.recordings);
        setlearnings(res.learnings);
        setloading(false);

        let path = "course_" + course_url;
        db.collection("paths").add(
          {
            my_course: res.course,
          },
          "my_course",
        );
        db.collection("paths").add(
          {
            classes: res.classes,
          },
          "classes",
        );

        db.collection("paths").add(
          {
            recordings: res.recordings,
          },
          "recordings",
        );

        Animate();
      }
      //  //  //console.log("dash "+JSON.stringify(res))
    }
  };

  useEffect(() => {
    SetPage("My Course");
    db.collection("paths")
      .doc("my_course")
      .get()
      .then((course) => {
        setcourse(course.my_course);
      })
      .catch((error) => {});

    db.collection("paths")
      .doc("classes")
      .get()
      .then((course) => {
        setclasses(course.classes);
      })
      .catch((error) => {});

    db.collection("paths")
      .doc("recordings")
      .get()
      .then((course) => {
        setrecordings(course.recordings);
      })
      .catch((error) => {});
    Statistics();
  }, []);

  const newdevice = DetectDevice();

  const [cart, setsestate] = useState();

  const [journey, setjourney] = useState(null);

  const CourseJourney = async () => {
    const res = await TrackJourney({
      payload: {
        user: user.SessionId,
        course_url: course_url,
        batch_id: batch_id,
      },
    });
    if (res) {
      if (res.status === "success" && res.data != null) {
        setjourney(res.data);
      }
      //   //  //console.log("journey "+JSON.stringify(res))
    }
  };

  const Animate = () => {
    const items = document.getElementsByClassName("animate");

    const screenheight = window.innerHeight;
    for (var i = 0; i <= items.length; i++) {
      const currentelement = items[i];

      if (currentelement) {
        const elementposition = parseInt(
          currentelement.getBoundingClientRect().top.toFixed(),
        );

        if (elementposition < screenheight) {
          if (items[i].classList.contains("animation-active")) {
          } else {
            items[i].classList.add("animation-active");
          }
        }
      }
    }
  };
const device = DetectDevice()
  useEffect(() => {
    SetPage("My Course");
  }, []);

  const [modal, setmodal] = useState(false);

  const DownloadIdCard = ({ data }) => {
    const imageUrl = data.id_card;

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement("a");
        a.href = url;

        // Set the download attribute to specify the filename for the downloaded file
        a.download = "skillsupriseethical-hacking-specialization.png";

        // Trigger a click event on the anchor element to start the download
        a.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  return (
    <div className="pb-5">
      {course ? (
        <>
          <CourseBatchChat data={course} course_title={course.course_title} />

          <Modal open={modal} onClose={() => setmodal(false)}>
            <Box className="col-lg-4 col-11 p-3" sx={ModalStyle}>
              <div className="modal-header">
                <h4>Student access only !</h4>
                <CloseIcon className="click" onClick={() => setmodal(false)} />
              </div>

              <div className="p-2">
                <p>
                  {course.mode === "free" &&
                  course_url === "website-development" ? (
                    <>
                      You can access these resources only after your classes are
                      commenced.
                    </>
                  ) : (
                    <>Please register a paid course to access this feature.</>
                  )}
                </p>

                <div className="mt-3 modal-header">
                  <button
                    onClick={() => setmodal(false)}
                    className="btn btn-danger rounded-pill btn-sm"
                  >
                    Cancel
                  </button>
                  <Link
                    to="/courses"
                    className="btn btn-primary rounded-pill btn-sm"
                  >
                    Browse Courses
                  </Link>
                </div>
              </div>
            </Box>
          </Modal>

          <div className="container sm-mt-0 lg-mt-10 sm-p-0  d-flex flex-wrap justify-content-between">
            <div className="col-lg-6">
              {account ? (
                <>
                  <div className="border sm-border-none rounded rounded-2 sm-shadow-none sm-p-15 sm-mt-0">
                    <div className="p-3 sm-p-0">
                      <div className="d-flex">
                        <img
                          src={
                            account.profile_pic
                              ? account.profile_pic
                              : defaultdp
                          }
                          className="course-userdp mr-15"
                        ></img>
                        <div>
                          <h4 className="truncate truncate1 mb-2">
                            {account.first_name + " " + account.last_name}
                          </h4>
                          <h6 className="truncate mb-2 truncate2">
                            {course.course_title}
                          </h6>
                          <div className="">
                            <p className="mr-15 sm-14 mb-0">
                              Student ID : {course.purchase_id}
                            </p>

                            <p className="mr-15 mt-1 sm-14 mb-0">
                              {" "}
                              {course.id_card ? (
                                <a
                                target="_blank"
                                  href={course.id_card}
                                  onClick={() =>
                                    DownloadIdCard({ data: course })
                                  }
                                  className="click"
                                >
                                  <Download /> Download ID Card
                                </a>
                              ) : (
                                "ID Card: NA"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="d-flex course-dash-options  pl-3 pr-3 justify-content-between">
                        <div className="d-flex d-none align-items-center">
                          <img className="" src={teamicon}></img>
                          <div>
                            <h4 className="pb-0">{course.team_id}</h4>
                            <p>Team ID</p>
                          </div>
                        </div>
                        <div className="mr-15">
                          <h5 className="pb-0">{course.rank}</h5>
                          <p>Rank</p>
                        </div>
                        <div className="mr-15">
                          <h5 className="pb-0">{course.points}</h5>
                          <p>Points</p>
                        </div>
                        <div className="mr-15">
                          <h5 className="pb-0">
                            {course.badges_earned ? course.badges_earned : 0}
                          </h5>
                          <p>Badges</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {journey ? (
                <>
                  <div className="lg-mt-10 sm-mt-0 sm-p-15 progress-tracker">
                    <ProgressTracker
                      journey={journey}
                      progress={course.progress}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className=" p-2">
                    <Alert severity="info">
                      Your progress tracker should be active within 48 hours of
                      your course registration. Please contact your trainer if
                      not active.
                    </Alert>
                  </div>
                </>
              )}

              {device==="mobile"&&classes?
              <>
              <div className="my-3 p-2 p-md-0">
<ClassesCalander classSchedules={classes} className="w-100"/>
</div>
              </>:
              <>
              
              </>}

              <div className="container mt-1 ">
                <>
                  <div className="grid lg-grid-4 animate course-options grid-3 mt-3 mb-3">
                    {course.mode === "selfpaced" ||
                    (course.mode === "free" && lessons) ? (
                      <>
                        <Link
                          to={
                            "/lessons/" +
                            course_url +
                            "/" +
                            lessons[0].topics_chapter_id +
                            "/" +
                            lessons[0].id
                          }
                          className="btn w-100 fade-in-bottom delay2s rounded-pill btn-sm p-0"
                        >
                          <div className="text-center  bg1">
                            <img
                              className="w-30 mb-2 mx-auto"
                              src={myclasses}
                            />
                            <p>Classes</p>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          className="fade-in-bottom delay2s"
                          to={"/classes/" + course_url + "/batch" + batch_id}
                        >
                          <div className="text-center bg1 ">
                            <img
                              className="w-30 mb-2 mx-auto"
                              src={myclasses}
                            />
                            <p>Classes</p>
                          </div>
                        </Link>

                        <Link
                          className="fade-in-bottom delay2s"
                          to={
                            "/recordings/" + course_url + "/" + batch_id + "/0"
                          }
                        >
                          <div className="text-center bg2 ">
                            <img
                              className="w-30 mb-2 mx-auto"
                              src={recordingsicon}
                            />
                            <p>Recordings</p>
                          </div>
                        </Link>
                      </>
                    )}

                    <Link
                      className="fade-in-bottom delay2s"
                      to={"/notes/" + course_url}
                    >
                      <div className="text-center  bg3">
                        <img className="w-30 mb-2 mx-auto" src={notesicon} />
                        <p>Notes</p>
                      </div>
                    </Link>

                    <Link
                      className="fade-in-bottom delay2s"
                      to={"/assignments/" + course_url + "/" + batch_id}
                    >
                      <div className="text-center  bg4">
                        <img
                          className="w-30 mb-2 mx-auto"
                          src={assignmentsicon}
                        />
                        <p>Assignments</p>
                      </div>
                    </Link>

                    <Link
                      className="fade-in-bottom delay3s"
                      to={"/projects/" + course_url + "/" + batch_id}
                    >
                      <div className="text-center  bg5">
                        <img className="w-30 mb-2 mx-auto" src={projectsicon} />
                        <p>Projects</p>
                      </div>
                    </Link>

                    <Link
                      className="fade-in-bottom delay3s"
                      to={"/course-rewards/" + course_url}
                    >
                      <div className="text-center  bg6">
                        <img className="w-30 mb-2 mx-auto" src={rewardsicon} />
                        <p>Rewards</p>
                      </div>
                    </Link>

                    <Link
                      className="fade-in-bottom delay3s"
                      to={"/trainer-support/" + course_url + "/" + batch_id}
                    >
                      <div className="text-center  bg7">
                        <img className="w-30 mb-2 mx-auto" src={supporticon} />
                        <p>Support</p>
                      </div>
                    </Link>

                    <Link
                      className="fade-in-bottom delay3s"
                      to={"/placement-cell/" + course_url + "/" + batch_id}
                    >
                      <div className="text-center bg8">
                        <img
                          className="w-30 mb-2 mx-auto"
                          src={placementicon}
                        />
                        <p>Placements</p>
                      </div>
                    </Link>

                    {newdevice === "mobile" ? (
                      <>
                        <Link onClick={() => setmodal(true)}>
                          <div className="text-center bg1">
                            <img
                              className="w-30 mb-2 mx-auto"
                              src={placementicon}
                            />
                            <p>Leaderboard</p>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="d-flex animate mt-4 mb-4 justify-content-between">
                    <Link 
                      to={"/test-topics/mock-test/" + course_url}
                      className="col fade-in-bottom delay4s text-center br-5 text-light p-3 m-1 bg-image test-bg"
                    >
                      <h4 className="sm-16">Mock Tests</h4>
                    </Link>

                    <Link
                      to={"/resources/" + course_url + "/" + batch_id}
                      className="col fade-in-bottom delay4s text-center br-5 text-light p-3 m-1 bg-image resource-bg"
                    >
                      <h4 className="sm-16">Resources</h4>
                    </Link>
                  </div>
                </>

                {course.course_url === "ethical-hacking-free" ||
                course.course_url === "ethical-hacking" ||
                course.course_url == "ethical-hacking-specialization" ? (
                  <>
                    <div className="shadow lab-cover mt-2  p-3 lab-card mb-5">
                      <h5 className="hack-green">Hack like new before !</h5>
                      <p className="hack-green-light">
                        Learn by doing. Learn to hack with online hacking lab
                        packed with top-notch tools and real-world hacking
                        scenarios and targets.
                      </p>
                      <a
                        target="_blank"
                        href="https://globalhackersunited.com"
                        className="btn btn-primary btn-sm rounded-pill mt-3"
                      >
                        Access Hacking Lab
                      </a>
                    </div>
                  </>
                ) : (
                  <>{course.course_url === "website-development"}</>
                )}

                {classes ? (
                  <>
                    <div className="mt-4 d-none">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>
                          Upcoming {classes.length > 1 ? "classes" : "class"}
                        </h4>
                        <Link
                          className="btn btn-outline-primary rounded-pill sm-12"
                          to={"/classes/" + course_url + "/batch" + batch_id}
                        >
                          View All
                        </Link>
                      </div>
                      {classes.map((item, index) => (
                        <>
                          <ClassItem
                            key={item.id}
                            item={item}
                            index={index + 1}
                          />
                        </>
                      ))}
                    </div>
                  </>
                ) : null}

                {course.mode === "selfpaced" ? (
                  <></>
                ) : (
                  <>
                    {recordings ? (
                      <>
                        <div className="mt-5 d-none ">
                          <h4>Previous Classes</h4>

                          {recordings.map((item, index) => (
                            <>
                        <PreviousScheduleItem item={item} index={index} course_url={course_url} batch_id={batch_id}/>
                            </>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-5 container col-md-6 col-sm-12 col-12">
              {/* <CourseBatch more={false}/> */}
              <div className=" p-3 pt-0 pb-0 shadow d-flex br-5 text-white hacking-line bg-primary">
                <div className="col-8 py-3">
                  <h5>Become Job Ready !</h5>
                  <p className="extra-small light-grey">
                    Learn the skills that helps you to crack your dream job and
                    earn extra points for every successful milestone.
                  </p>

                  <Link
                    to={"/milestones/" + course_url + "/" + batch_id}
                    className="btn btn-primary rounded-pill btn-sm mt-3"
                  >
                    Start Now
                  </Link>
                </div>
                <div className="col-4">
                  <img src="https://skillsuprise.com/resources/images/extra-girl.png" className="w-100"></img>
                </div>
              </div>

{
  course.id_card ?
 null: <div className="mt-3">

  <UploadStudentIdCard/>
</div>
}
             

{classes&&device!="mobile"?
<>
<div className="my-3">
<ClassesCalander classSchedules={classes} className="w-100"/>
</div>
         
</>:
<>
</>}  
              <TeamLeaderboard
                courseUrl={course_url}
                batchId={batch_id}
                teamId={course.team_id}
                limit={4}
              />

              <div className="mt-3  p-3 pt-0 pb-0 shadow d-flex br-5 text-white grey-cta bg-primary">
                <div className="col-8 py-3">
                  <h5>Earn Course Certificates</h5>
                  <p className="extra-small light-grey">
                    Add an X-Factor to your resume by earning your course
                    certificates. Take up tests and showcase your talents
                  </p>

                  <Link
                    to={"/test-topics/course-certification/" + course_url}
                    className="btn btn-primary rounded-pill btn-sm mt-3"
                  >
                  Get Certified Now
                  </Link>
                </div>
                <div className="col-4">
                  <img src="https://skillsuprise.com/resources/images/cert-cta.png" className="w-100"></img>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="d-flex mb-3 mt-5 align-items-center justify-content-between">
              <h4>Course Rewards</h4>
              <Link to={"/course-rewards/" + course_url}>View All</Link>
            </div>

            <div className="grid lg-grid-6 grid-2">
              <CourseRewards />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container d-flex justify-content-between">
            <div className="col-lg-6">
              <Skeleton className="mb-2 " variant="text" height={400} />

              <div className="grid lg-grid-4 course-options grid-3 mt-3 mb-3">
                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />

                <Skeleton className="mb-2 mt-2" variant="text" height={200} />
              </div>
            </div>

            <div className="col-lg-5">

            <Skeleton className="mb-2 mt-2" variant="text" height={200} />


            <div className="my-3">
            <Skeleton
             className="mb-2 mt-2" variant="text" height={80} />

<Skeleton
             className="mb-2 mt-2" variant="text" height={80} />

<Skeleton
             className="mb-2 mt-2" variant="text" height={80} />

<Skeleton
             className="mb-2 mt-2" variant="text" height={80} />

            </div>
            <Skeleton className="mb-2 mt-2" variant="text" height={200} />

            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyCourseDashboard;