import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import GetCourseDetails from '../../actions/GetCourseDetails'
import Rating from '@mui/material/Rating';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { Chip } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import dateFormat from 'dateformat';
import circle from '../../assets/icons/circle.svg'
import LearnIntern from '../../components/LearnIntern';
import FeaturesList from './FeaturesList';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SyllabusAccordion from './SyllabusAccordion';
import Reviews2 from '../reviews/Reviews2';
import Reviews1 from '../reviews/Reviews1';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../../context/AuthContext';
import RegisterCourse from '../../actions/RegisterCourse';
import Toast from 'react-bootstrap/Toast'
import SetPage from '../../data/SetPage';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Contact from '../../actions/support/Contact';
import ModalStyle from '../../data/ModalStyle';
import TextField from '@mui/material/TextField';
import ContactSection from '../../components/ContactSection';
import CourseDetailsLoadingTemplate from '../../templates/CourseDetailsLoadingTemplate';
import DetectDevice from '../../data/DetectDevice';
import { useRef } from 'react';
import divider from '../../assets/bg/divider.png'
import TrainerSectionMain from './TrainerSectionMain';

import LoginStatus from '../../actions/LoginStatus';
import { Helmet } from 'react-helmet';
import SaveUserEvent from '../../actions/SaveUserEvent';
import { ArrowDropDown, CheckBox } from '@material-ui/icons';
import SuccessStories from '../home/SuccessStories';
import DemoRequestModal from '../../components/modals/BasicNotification';
import SocialShare from '../../components/SocialShare';
import CourseDetailsLocalSEO from './CourseDetailsLocalSEO';
import { useActionsContext } from '../../context/ActionsContext';
const CourseDetails = () => {
     const {sidebar} = useActionsContext()
     const params = useParams()
const featuressection = useRef()
const overviewsection = useRef()
const certificatesection = useRef()
const reviewssection = useRef()
const syllabussection = useRef()
const projectssection = useRef()
const {search} = useActionsContext()
const contactsection = useRef()
 const [enrolled,setenrolled] = useState(false)
     const {user} = useContext(AuthContext)
     const course_url = params.course_url
     const mode = params.mode
const [course,setcourse] = useState(null)
useEffect(() => {
     window.scrollTo(0, 0)
 
  
   }, [course_url,mode])
const [loading,setloading] = useState(false)

     const GetCourse = async()=>{

         const res =await GetCourseDetails(course_url,mode,user)
 //  //console.log("param "+params.course_url)
         if(res.status==="success"){
       
         //  //console.log("chapters details "+JSON.stringify(res.chapters))
        setcourse(res.course[0])
        setprojects(res.projects)
        setchapters(res.chapters)
        setenrolled(res.enrolled)

         }
       
     }

     const [loggedin,setloggedin] = useState(false)
      useEffect(()=>{
          SetPage("Course details")
          if(user!==null||user!=="null"){
               setloggedin(true)
          }
GetCourse()
      },[course_url])

    const [projects,setprojects] = useState(null)
    const [chapters,setchapters] = useState(null)



const NewRegisterCourse =  async()=>{
     setregistering(true)
     sessionStorage.setItem("redirect","/course/"+course_url+"/"+mode)

     settt("Registering !")
     settm("Please wait for a while...")
     settoast(true)
     var loginstatus = LoginStatus()
     if(loginstatus===true){

          const res = await RegisterCourse({payload:{
               user:user.SessionId,
               course_url:course_url,
               mode:mode
          }})
     if(res){
          setregistering(false)
          if(res[0].status==="success"&&res[0].enrollment==="pending"){
               settt("Redirecting to payment page")
               settm("Please wait for a while...")
               settoast(true)
               setloading(false)
               
               if(mode==="free"){
                   setmodal(true)
                   window.location.replace("/my-courses")
               }else{
                    window.location.replace("/Payment")
           
               }
             
          }else{
               setloading(false)
              if(mode==="free"){
                  setmodal(true)
               }else{
                  
                    window.location.replace("/Payment")
           
               } 
          }
     }

     }else{
          setloading(false)
         SaveUserEvent(
               {payload:{
                    session: user!=null||user!=undefined?user.SessionId:null,
              
                 page:window.location.pathname,
                 action:"CourseDetails-registerclicked-notloggedin"
               }});
        
             sessionStorage.setItem("next","/course/"+course_url+"/"+mode)
          sessionStorage.setItem("redirect","/course/"+course_url+"/"+mode)
window.location.replace("/login")
     }
    
}


const [toast,settoast] = useState(false)
const [tm,settm] = useState(null)
const [tt,settt]= useState(null)



const [contact,setcontact] = useState(false)

const [type,settype] = useState("contact");
const InitiateContact = ({type})=>{
settype(type)
     setcontact(true)
}


const device = DetectDevice();

const ToggleShortcuts = (e)=>{

const shortcuts = document.getElementById("shortcuts")
 //  //console.log("scrolled "+window.scrollY)
 //  //console.log("page height"+window.outerHeight)

if(device!=="mobile"&&window.scrollY>500){
     if(shortcuts){

          shortcuts.style.top = "0px";
          shortcuts.style.transition = "0.5s"
     }
}
else{
     if(shortcuts){
          if(device!=="mobile"){

               shortcuts.style.top = "-70px";
               shortcuts.style.transition = "0.5s"
     
          }

     }
   
}

}

const Navigate = (section)=>{
     section.current.scrollIntoView() 
}

useEffect(()=>{
 
     window.addEventListener("scroll", ToggleShortcuts);
   //window.addEventListener("scroll",Animate)
   })

   const Animate = ()=>{
     const items = document.getElementsByClassName("animate")
    
     const screenheight = window.innerHeight;
     for (var i =0;i<=items.length;i++){
   const currentelement = items[i]
   
   if(currentelement){
     const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed())
    
     if(elementposition<screenheight){
       if(items[i].classList.contains("animation-active")){
     
       }else{
         items[i].classList.add("animation-active")
       }
     }
   
   }
   
   
      
     }
   }
   
   const [registering,setregistering] = useState(false)
const [modal,setmodal] = useState(false)

const PausePromo = ()=>{

     const promo = document.getElementById("promo")
     if(promo){
          promo.pause()
     }
}

const CloseDemoModal = ()=>{


     setcontact(false)
}
const [suggestlogin,setsuggestlogin] = useState(false)
  return (

     <>
{course?
<>
<Helmet>‍
        <title>{course.course_title+" "+mode+" Training | Course"}</title>‍
        <meta name="description" content={course.course_description} />       
        <meta name="twitter:card" content={course.course_cover_url} />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                <meta name="twitter:title" content={course.course_title} />   
        <meta name="twitter:description" content={course.course_description} />  
        <meta name="twitter:image" content={course.course_cover_url}/>     
        <meta property="og:title" content={course.course_title} />  
        <meta property="og:description" content={course.course_description} />        
        <meta property="og:image" content={course.course_cover_url}/>
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

</>

:
<>
<CourseDetailsLocalSEO course_url={course_url} mode={mode}/>

</>}

<DemoRequestModal type={type} visibility={contact} HandleClose={CloseDemoModal}/>

<Modal
  open={modal}
  onClose={()=>setmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11">
  <div className="p-2 ">
  <div className="modal-header">
     <h4>Registration Successful !</h4>
     <CloseIcon onClick={()=>setmodal(false)}/>
  </div>
  <p>You have successfully registered for the course.</p>

  <div className="d-flex mt-4 justify-content-between">
     <button className="btn btn-danger rounded-pill btn-sm" onClick={()=>setmodal(false)}>Cancel</button>
  
  <Link to="/my-courses"  className="btn btn-primary rounded-pill btn-sm">Start Course</Link>
  </div>
   </div>
  </Box>
</Modal>






<Modal
  open={suggestlogin}
  onClose={()=>setsuggestlogin(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11">
  <div className="p-2 ">
  <div className="modal-header">
     <h4>Login !</h4>
     <CloseIcon onClick={()=>setsuggestlogin(false)}/>
  </div>
  <p>Login into your account to complete registration.</p>

  <div className="d-flex mt-4 justify-content-between">
     <Link to="/signup" className="btn btn-danger rounded-pill btn-sm" >SignUp & Proceed</Link>
  
  <Link to="/login"  className="btn btn-primary rounded-pill btn-sm">Login & Proceed</Link>
  </div>
   </div>
  </Box>
</Modal>




     <div className="toast-container">


     <Toast className="bg-warning " onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
           
            <strong className="me-auto">{tt}</strong>
         
          </Toast.Header>
          <Toast.Body>{tm}</Toast.Body>
        </Toast>
     </div>






{device==="mobile"?
<>

</>
:
<>

<div id="shortcuts" className="container-fluid navbar-bg course-top-nav">


     <div className="container course-shortcuts  w-100 justify-content-between text-white d-flex align-items-center">



<div className="d-flex ">
<h6 onClick={()=>{Navigate(overviewsection)}}>Overview</h6>

<h6 onClick={()=>{Navigate(featuressection)}}>Features</h6>

<h6 onClick={()=>{Navigate(certificatesection)}}>Certificate</h6>
<h6 onClick={()=>{Navigate(syllabussection)}}>Syllabus</h6>

<h6 onClick={()=>{Navigate(projectssection)}}>Projects</h6>
<h6 onClick={()=>{Navigate(reviewssection)}}>Reviews</h6>

<h6 onClick={()=>{Navigate(contactsection)}}>Contact Us</h6>

</div>
<div className="d-flex h-100 align-items-center">
     <div>

     <h5 className="mr-15 mb-0 pb-0">₹ {course?course.course_price:null}</h5>
<p className="extra-small light-grey "><s>₹ {course?course.course_cutoff_price:null}</s></p>


     </div>
     {registering?
     <>
        <button disabled className="btn btn-register-course"><CircularProgress size={20} className="text-light mr-5" />Registering...</button>

     </>:
     <>
{course?enrolled===true?
<>
<Link  className="btn btn-register-course">Continue Course</Link>


</>:
<>
<button onClick={()=>{NewRegisterCourse()}}  className="btn btn-register-course"> {mode==="specialization"?"Register Course @ ₹ 5000":"Register Course "}</button>


</>:<button onClick={()=>{NewRegisterCourse()}}  className="btn btn-register-course"> {mode==="specialization"?"Register Course @ ₹ 5000":"Register Course "}</button>
}

     
     </>}
  </div>

     </div>
</div>



</>}

     <div ref={overviewsection} className="course-details">
     {course?
     <>
      <div className="w-100 sm-pt-15 pt-50 bg-dark ">
<div className="container pt-3  pb-5 text-white">
     <div className="flex-wrap pb-5 justify-content-between d-flex">

<div className="col-lg-6">
    
<h2 className="font-weight-bold text-white">{course.course_title}</h2>
<p className="course-desc">{course.course_description?course.description:null}</p>
<div className="d-flex light-grey mt-3 font-weight-normal align-items-center">
    <h6 className="pb-0 mb-0"> {course.rating}</h6>
   
<Rating
  name="simple-controlled"
  value={course.rating}
  readOnly

/>
<h6 className="pb-0 mb-0">({course.ratings} Ratings)</h6>

</div>



<div className="d-flex flex-wrap mt-4">
{mode==="free"?
<>
<Chip className="chip mb-2" label={<><h6 className=" sm-14 pb-0">{course.course_duration+" hours"}</h6></>} />

</>:
<>
<Chip className="chip mb-2" label={<><h6 className=" sm-14  pb-0">{course.course_duration+" days"}</h6></>} />

</>}
<Chip className="chip mb-2" label={<><h6 className=" sm-14  pb-0">{"certified training"}</h6></>} />

<Chip className="chip mb-2" label={<><h6 className=" sm-14  pb-0">{course.seats_left+" seats left"}</h6></>} />
</div>

<hr/>
{mode==="free"?
<>

</>:<>
<h6 className="light-grey"><b className="text-warning">Batch Timings :</b> Morning, Afternoon & Evening</h6>

</>}

<div className="d-flex flex-wrap mt-4">
     <button 
     
     onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
              
            page:window.location.pathname,
            action:"CourseDetails-requestdemo"
          }});
          InitiateContact({type:"demo"})
        }
        }
         
     
    className="btn btn-success rounded-pill btn-lg btn-sm mr-15">Request Free Demo</button>

     <button
      onClick={()=>{SaveUserEvent(
          {payload:{
               session: user!=null||user!=undefined?user.SessionId:null,
              
            page:window.location.pathname,
            action:"CourseDetails-requestcontact"
          }});
          InitiateContact({type:"contact"})
        }
        }
        
     
     className="btn btn-primary rounded-pill btn-lg btn-sm"><PhoneInTalkIcon/> Contact Us</button>
</div>


{device==="mobile"?
<>

</>:
<>
<div className="w-fit mt-4">
<hr></hr>
<h5>Share this course</h5>
<SocialShare path={window.location.href}/>
</div>
</>}

</div>
<div className="col-lg-4 sm-mt-30">
<div className="shadow br-5 p-2">
<h4 className="text-dark">Course Preview</h4>

{course.course_promo_url===null?
<>

<img className="w-100" src={course.course_cover_url}/>
</>:

<>
<video id="promo" controlsList='nodownload' src={course.course_promo_url} poster={course.promo_poster} className="w-100"></video>

</>}
     <div className=" p-2">
          {course.mode==="free"?
          <>
            <div className="d-flex mb-3 align-items-center">
          <h3 className="mr-3 price pb-0">Free</h3>
          <s>₹ {course.course_cutoff_price}</s>
           </div>

           <p className="mb-3">Offer Ends On : {dateFormat(course.offer_ends,"dS mmmm ")}</p>
          </>:
          <>
          
          <div className="d-flex align-items-center">
          <h5 className="col">Upcoming Batches : </h5>
          <DropdownButton variant="light" title={<>{dateFormat(course.batch1_starts,"dS mmmm, yyyy ")} <ArrowDropDown/></>}>
      <Dropdown.Item href="#/action-1">{dateFormat(course.batch1_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
      <Dropdown.Item href="#/action-2">{dateFormat(course.batch2_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
      <Dropdown.Item href="#/action-3">{dateFormat(course.batch3_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
{course.course_category==="integrated"?
<>
</>:
<>
<Dropdown.Item href="#/action-1">{dateFormat(course.batch4_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
      <Dropdown.Item href="#/action-2">{dateFormat(course.batch5_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
      <Dropdown.Item href="#/action-3">{dateFormat(course.batch6_starts,"dS mmmm, yyyy ")}</Dropdown.Item>
</>}
    </DropdownButton>
        
          </div>
        

          <div className="d-flex mb-3 align-items-center">
          <h3 className="mr-3  price pb-0 course-price">₹ {course.course_price}</h3>
          <s>₹ {course.course_cutoff_price}</s>
           </div>
           <p className="mb-2"><b>Offer Ends On :</b> {dateFormat(course.offer_ends,"dS mmmm ")}</p>
          
          </>}

{parseInt(course.money_back_guarantee)===1?
<>
<div className="money-back mb-3 d-flex align-items-center">
               <img src="https://skillsuprise.com/resources/images/guarantee.png"/>
               <p>7 Day Money Back Guarantee</p>
          </div>
        
</>:
<>

</>}
         
          {registering?
     <>
         <button onClick={()=>{NewRegisterCourse()}} className="btn d-none d-sm-block btn-register-course"><CircularProgress size={20} className="text-light mr-5" />Registering...</button>
       
      
     </>:
     <>

     {enrolled===true?
     <>
          <Link to="/my-courses" className="btn d-none d-sm-block btn-register-course">Continue Course</Link>
    
     </>:
     <>
          <button onClick={()=>{NewRegisterCourse()}} className="btn d-none d-sm-block btn-register-course"> {mode==="specialization"?"Register Course @ ₹ 5000":"Register Course "}</button>
    
     
     
     </>}
         
     </>}




         {search||sidebar?
         <>
         
         </>:
         <>
          <div className="course-action">
<div className="prices">
{mode==="free"?
<>
<h5 className="mb-0 pb-0 price">Free</h5>
          <s>₹ {course.course_cutoff_price}</s>
    
</>:
<>

{course.mode==="specialization"?<>
<h5 className="mb-0 pb-0 price course-price">₹ {course.course_price}</h5>
          <p className="extra-small pb-0 sm-12 truncate truncate1">EMI Available</p>
    
</>:<>
<h5 className="mb-0 pb-0 price course-price">₹ {course.course_price}</h5>
          <s>₹ {course.course_cutoff_price}</s>
    

</>}

</>}   
</div>
          

          {enrolled===true?
          <>
              <button onClick={()=>{NewRegisterCourse()}} className="btn d-block d-sm-none btn-register-course">Continue Course</button>

          
          </>:
          <>
              <button onClick={()=>{NewRegisterCourse()}} className="btn d-block d-sm-none btn-register-course"> {mode==="specialization"?"Register Course @ ₹ 7,000":"Register Course "}</button>

          
          </>}
     
     </div>
         </>}
       
     </div>
</div>



{device==="mobile"?
<>
<div className="w-fit mt-4">

<h5>Share this course</h5>
<div className="mt-2">
     
<SocialShare path={window.location.href}/>
</div>
</div>
</>:
<>

</>}
</div>
     </div>
</div>







    </div>
     

    <div className="container pt-5 pb-5">

    <div className="">

    <LearnIntern novideo={true}/>
    </div>
</div>

<div className="container animate sm-p-0 d-none flex-wrap align-items-center justify-content-evenly pt-5 lg-mb-50 sm-mb-0 sm-pb-0">
<div className="col-lg-4 ">
<img src="https://skillsuprise.com/resources/images/hemasri.png" className="w-100 br-5 sm-br-0"/>
</div>
<div className="col-lg-5 d-none d-md-block  yellow-cover lg-mb-50 p-3 sm-mb-0">
     <h2>Guaranteed Internship & Placement Support !</h2>
     
<p>Master right set of skills and learn to showcase your talents to get a job with a min on 3LPA with our placement trainings power packed with resume development training, mock interviews,salary negotiation, communication skills development, group discussions, reasoning & aptitude and many more to get a job easier.
</p>

<div className="d-flex mt-3 align-items-center">
<img src={circle} class="arrow"></img>
<h6 class="mb-0 pb-0">Industry Ready Certification</h6>

</div>




<div className="d-flex mt-3 align-items-center">
<img src={circle} class="arrow"></img>
<h6 class="mb-0 pb-0">100% Guaranteed Internships & Placements</h6>
</div>




<div className="d-flex mt-3 align-items-center">
<img src={circle} class="arrow"></img>
<h6 class="mb-0 pb-0">Dedicated Trainer Support</h6>
</div>


<div className="d-flex mt-3 align-items-center">
<img src={circle} class="arrow"></img>
<h6 class="mb-0 pb-0">Assignments,Realtime Projects and many more</h6>
</div>




</div>



</div>


<div className="py-5 sm-pt-0 container">
<SuccessStories/>
</div>


<div ref={featuressection}>

<FeaturesList mode={mode} course={course}/>
</div>

<div  ref={certificatesection} className="certification-bg pt-5 sm-pt-0  pb-5">
<div className="container pt-5 pb-5  d-flex flex-wrap justify-content-evenly ">
     <div className="col-lg-5 sm-mt-20 text-left  order-2 order-md-1">
          <h1 className=" font-weight-bold  primary-text">Industry Ready Certification</h1>
          <h3 className="secondary-text">An X-Factor for you resume</h3>
          <p>Get certified and get a job ! Learn on-demand skills and showcase your worth with our industry ready certification. 
               Build skills that thousands of companies are looking for from the freshers and get your dream job today !</p>
             
   <hr></hr>          
<div className="hr-line-red red mb-2 mt-3 d-none ">

</div>

<div className="d-flex">
<h5><CheckBox className='text-success me-2'/> Certificate of Training</h5>
</div>

<div className="d-flex">
<h5><CheckBox className='text-success me-2'/> Top Performer Certificate</h5>
</div>
       
<div className="d-flex">
<h5><CheckBox className='text-success me-2'/> Project Completion Certificate</h5>
</div>

<div className="d-flex">
<h5><CheckBox className='text-success me-2'/> Industry Readiness Certificate</h5>
</div>
      
             
     </div>
     <div className="col-lg-5 sm-mt-20 order-1 order-md-2">
          <img className="w-100" src={course.demo_certificate}/>
     </div>
</div>

</div>


{chapters?
<>
<div  ref={syllabussection} className="w-100  pt-5 sm-pt-0 pb-5">
     <div className="container">
<div className="text-center">
   
     <h1 className="primary-text ">Experts Developed Course Content</h1>
     <div class="divider">
<img src={divider}  alt="divider"></img>
</div>

{course.syllabus_url?
<>
<div className="syllabus-section col-lg-7 mt-4  mx-auto col-sm-12 col-12">
    <div className="d-flex justify-content-between align-items-center flex-wrap sm-justify-content-center sm-text-center">
    <h5>Know more about the course and syllabus</h5>
    <a       onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
              
            page:window.location.pathname,
            action:"CourseDetails-downloadedsyllabus"+course.course_title
          }});
          InitiateContact({type:"contact"})
        }
        } href={course.syllabus_url} className="btn btn-primary rounded-pill" target='_blank'>Download Syllabus</a>

    </div>
    </div>

</>:null}
<div className="col-lg-7  col-sm-12 col-12 pt-5 mx-auto">
<SyllabusAccordion chapters={chapters} mode={mode}/>
</div>

</div>

     </div>
</div>

</>
:null}


{projects?
<>
<div ref={projectssection} className="w-100 pb-5">

     <div className="container pt-5 pb-5">
          <div className="text-center">
          <h1 className="secondary-text font-weight-bold">Work With Real-World Projects</h1>
<h2 className="primary-text">Get Industry Ready Skills</h2>
<div class="divider">
<img src={divider}  alt="divider"></img>
</div>
  
          </div>


          <div className="d-flex animate mt-4 justify-content-evenly flex-wrap">

{projects.map((project,index)=>(
     <>


     
     <div className={"col-lg-3 fade-in-bottom  p-2 delay"+(index*2)+"s"}>

<div className="shadow text-white project-item text-center p-1 br-5">
     <img src={project.image} className="w-100 mb-3"/>
<div className="p-2">
<h5 className="text-warning">{project.title}</h5>
     <p className="text-white truncate truncate5">{project.description}</p>
     </div>
     </div>
</div>



     </>
))}








          </div>

     </div>
</div>


</>

:
null}

<TrainerSectionMain/>



{device==="mobile"?
<>


</>:
<>
<div className="mb-5 pb-5">

</div>

</>}
<div  ref={reviewssection} className="pt-5 home-reviews pb-5">
<Reviews2/>

</div>

<div className="pt-5  home-reviews2">
<Reviews1/>
</div>





<div ref={contactsection} className="pt-5 pb-5 mb-5">

<ContactSection/>
</div>








     </>
     :
     <>
     


<CourseDetailsLoadingTemplate/>













     </>}
     </div>
 
     </>
   
  )
}

export default CourseDetails