import React from 'react'
import { Link } from "react-router-dom";
import BusinessContactModal from '../../components/modals/BusinessContactModal';
import { useState } from 'react';
import UpdatingModal from '../../components/modals/UpdatingModal';
import { Check } from '@material-ui/icons';
import CollegeContactModal from '../../components/modals/CollegeContactModal';
import CustomVideoPlayer from '../../components/elements/CustomVideoPlayer';
const CollegesBusinessCta = () => {
   const [modal,setmodal] = useState(false)
   const HandleClose = ()=>{
   setmodal(false)
   }
  
   const [updatemodal,setupdatemodal] = useState(false)
 
   const CloseUpdateModal = ()=>{
      setupdatemodal(false)
      }
 
   return (


<>


<CollegeContactModal visibility={updatemodal} HandleClose={CloseUpdateModal}/>

<div className="d-flex  bg-primary pb-5 rounded-0 pb-sm-3 p-lg-4 p-3 p-md-3 border-1 border-none border-lg border-lg-1 rounded-lg-2 col-lg-10 mx-auto flex-wrap">


<div className="col-lg-6 col-12">
<img src="https://skillsuprise.com/images/students-transformed.png" className='w-100 rounded'></img>

</div>

<div className="col-lg-6 sm-mt-10 col-12 text-light ps-lg-5 ps-0 ps-md-3">
<h3>For Colleges & Universities</h3>
<p className='text-light'>Increase Employability & Attract More Student With Our on-demand student development programs</p>

<div className="d-flex mt-2">
   <Check className='me-2'/>
   <p className='text-light'>On-Demand Courses</p>
  
</div>


<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>Industrial Trainings</p>
  
</div>


<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>Internship Programs</p>
  
</div>

<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>Workshops & Hackathons</p>
  
</div>
<div className="d-flex mt-4">
    <button onClick={()=>setupdatemodal(true)} className='btn btn-warning'>Contact Us</button>
    <Link to="/campus" className='btn ms-2 btn-outline-light'>Read More</Link>
</div>
</div>






</div>


</>    )
}

export default CollegesBusinessCta