import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton'
import defaultcover from '../../assets/icons/default-cover.png'
import Dropdown from 'react-bootstrap/Dropdown';
import GetAccount from '../../actions/GetAccount';
import { AuthContext } from '../../context/AuthContext';
import Localbase from 'localbase';
import CoverPictureEdit from './CoverPictureEdit';
import DpChange from './DpChange';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import optionsicon from '../../assets/icons/options.svg'
import AccountOptionsDrowdown from './AccountOptionsDrowdown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logout from '../authentication/Logout';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import ModalStyle from '../../data/ModalStyle';
import Close from '@mui/icons-material/Close';
const UserAccount = ({account}) => {
     const params = useParams()
     const username = params.username
let db = new Localbase("db")
const {user} = useContext(AuthContext)
     const NewGetAccount = async()=>{
          const res = await GetAccount({user})
          if(res){

                //  //console.log("account dash "+JSON.stringify(res))
          }
     }


     const UserStats = async()=>{
          const data = new FormData()
          data.append("session",user.SessionId)
          const res = await axios.post(ENDPOINT+"user-stats.php",data,SecurityHeaders)
          if(res){
if(res.data.status==="success"){
     setstats(res.data.data[0])
}
                console.log("STATS "+JSON.stringify(res)+" selft "+self+" acc "+account)
          }
     }
     useEffect(()=>{
          
NewGetAccount()
     },[user])


useEffect(()=>{
     if(!statsdisplay){
          UserStats()
     }
  
},[stats])


const ChangedCover =({image})=>{
      //  //console.log("image is "+JSON.stringify(image))
     if(image){
          setcroppedimage(URL.createObjectURL(image))
     }
     else{
          setcroppedimage(null)
     }

}
const [dp,setdp] = useState(null)

const DpChanged = ({image})=>{
     if(image){
          setdp(URL.createObjectURL(image))
     }
     else{
          setdp(null)
     }
    
}
const [stats,setstats] = useState(null)
const [statsdisplay,setstatsdisplay] = useState(false)
     const [croppedimage,setcroppedimage] = useState(null)


const [logoutmodal,setlogoutmodal] = useState(false)

const InstanceLogout = async({payload})=>{

const res = await Logout({payload});
if(res){
      //  //console.log("log instance"+JSON.stringify(res))
}

}

const [self,setself] = useState(true)
useEffect(()=>{
     if(username){
          if(username!==account.username){
               setself(false)
          }
     }

},[account,username])

  return (
    <div className="w-100 profile sm-shadow-none  sm-border-none  br-5">





<Modal
  open={logoutmodal}
  onClose={()=>setlogoutmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 col-lg-4 col-md-5 col-sm-6 col-11">
<div className="modal-header">
     <h5>Close Application !</h5>
     <Close className="click" onClick={()=>setlogoutmodal(false)}/>
</div>
<hr/>
<p>Are you sure to logout your account ?</p>


<div className="mt-3 d-flex justify-content-between">
<button className="btn btn-sm btn-danger rounded-pill"
onClick={()=>setlogoutmodal(false)}
>Cancel</button>


<button className="btn btn-sm btn-primary rounded-pill" 
onClick={()=>InstanceLogout({payload:{user:
user.SessionId}})}>Logout</button>
</div>
  </Box>
</Modal>



     <div className="user-cover-container d-flex align-items-center justify-content-benter">
<div className="position-relative w-100 d-flex align-items-center justify-content-center h-100">
{croppedimage?<>

    
</>:account.cover_pic?<>

<img src={account.cover_pic} className="w-100 h-100 user-cover-image"/>

</>:<>

{/*no cover pic */}

<div className="text-center  p-3">
     <h2 className="text-light"><b className="text-primary">Learn </b>-<b className="secondary-text"> Intern </b>-<b className="text-warning"> Get a Job</b></h2>
     <p className="sm-12 light-grey">Learn our 3 step mantra to land into you dream job</p>
</div>
</>}
    {self?
<>

<CoverPictureEdit self={true} ChangedCover={({image})=>{ChangedCover({image})}}/>
<DpChange DpChanged={({image})=>DpChanged({image})} account={account}/>
</>:
<>

<CoverPictureEdit self={false}/>
<DpChange self={false} account={account}/>
</>}
</div>


     </div>
   

   <div className="lg-p-3 sm-pb-0 bg-white">

   <div className=" ">
          {account?
          <>
          <div className="user-info  w-100 mt-4">
    
    <Link  to={"/u/"+account.username} className="text-dark">

    <h4 className="pb-0 d-sm-block">{account.first_name+" "+account.last_name}</h4>
 
    </Link>
      <Link to={"/u/"+account.username} className="d-sm-block">@{account.username}</Link>
  {self?
  <>
     <div className="d-flex mt-10  col-lg-12 col-12 col-md-11">
          <Link to="/my-posts" className="col ">
<h5  className="pb-0 sm-mb-0  text-dark">{account.posts}</h5>
<p>Posts</p>
          </Link>
          <Link to="/following" className="col ">
<h5  className="pb-0 sm-mb-0 text-dark">{account.following}</h5>
<p>Following</p>
          </Link>
          <Link to="/followers" className="col ">
<h5  className="pb-0 sm-mb-0  text-dark">{account.followers}</h5>
<p>Followers</p>
          </Link>

          <div className="col-2 text-center">
               {statsdisplay?
          <>
          <ArrowUpIcon className="click down-svg" onClick={()=>setstatsdisplay(false)}/>
          </>:
          <>
          <ArrowDownIcon className="click down-svg"  onClick={()=>setstatsdisplay(true)}/>
          </>}

          </div>
     </div>

  </>:
  <>
     <div className="d-flex mt-10  col-lg-12 col-12 col-md-11">
          <Link to={"/u/"+username+"/posts"} className="col ">
<h5  className="pb-0 sm-mb-0  text-dark">{account.posts}</h5>
<p>Posts</p>
          </Link>
          <Link to={"/u/"+username+"/following"} className="col ">
<h5  className="pb-0 sm-mb-0 text-dark">{account.following}</h5>
<p>Following</p>
          </Link>
          <Link to={"/u/"+username+"/followers"} className="col ">
<h5  className="pb-0 sm-mb-0  text-dark">{account.followers}</h5>
<p>Followers</p>
          </Link>

          
     </div>

  
  </>}


{statsdisplay && stats && self?
<>
<hr/>
     <div className="d-flex mt-4 pt-2 flex-wrap ">
<div className="col-lg-3 col-4 mb-4">
    <h5 className="pb-1">₹ {stats.earnings}</h5>
    <p>Earnings</p>
  </div>

  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">₹ {stats.balance}</h5>
    <p>Balance</p>
  </div>
  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">{stats.points}</h5>
    <p>Points</p>
  </div>

  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">{stats.badges}</h5>
    <p>Badges</p>
  </div>

  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">{stats.courses}</h5>
    <p>Courses</p>
  </div>
  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">{stats.projects}</h5>
    <p>Projects</p>
  </div>

  <div className="col-lg-3 col-4  mb-4">
    <h5 className="pb-1">{stats.workshops}</h5>
    <p>Workshops</p>
  </div>

  <div className="col-lg-3 col-4 mb-4">
    <h5 className="pb-1">{stats.internships}</h5>
    <p>Internships</p>
  </div>

  <div className="col-lg-3 d-block d-md-none col-4 mb-4">
    <h5 className="pb-1">{stats.certificates?stats.certificates:0}</h5>
    <p>Certifications</p>
  </div>

</div>




</>:
<>

</>}



</div>
          </>:null}


     </div>





     <hr />


     <div className="d-flex sm-14 justify-content-between">
     <Link className="link-item" to="/my-courses">My Courses</Link>

          <Link className="link-item" to={"/u/"+account.username}>My Account</Link>
          <Link className="link-item"  to="/my-internships">My Internships</Link>
     {account?
     <>
      <Dropdown className="dropdown me-2">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu className="p-3">
   
   
   <div className="mt-1 b-1">
   <Link  to={"/u/"+account.username}>
<Dropdown.Item as="button">My Account</Dropdown.Item>
      
</Link>
   </div>

   <div className="mt-1 b-1">
   <Link  to="/edit-profile">
<Dropdown.Item as="button">Edit Profile</Dropdown.Item>
      
</Link>
   </div>
  
   <div className="mt-1 b-1">
   <Link  to="/my-workshops">
<Dropdown.Item as="button">My Workshops</Dropdown.Item>
      
</Link>
   </div>


   <div className="mt-1 mb-1">
<Link to="/payments">
               <Dropdown.Item as="button">
              Payments</Dropdown.Item>
               </Link>
</div>   



<div className="mt-1 mb-1">
<Link to="/wallet">
               <Dropdown.Item as="button">
            My Wallet </Dropdown.Item>
               </Link>
</div>      
   




<div className="mt-1 mb-1">
<Link to="/settings">
               <Dropdown.Item as="button">
               Settings </Dropdown.Item>
               </Link>
</div>



          <div className="mt-1 mb-1">
     
          <Dropdown.Item onClick={()=>{setlogoutmodal(true)}} as="button">Logout</Dropdown.Item>
  
</div>




   
    </Dropdown.Menu>
</Dropdown>
     </>:null}
       
     </div>
     <hr className="d-block d-sm-none"/>
   </div>
    </div>
  )
}

export default UserAccount