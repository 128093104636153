import React, { useEffect } from 'react'
import ContactSection from '../../components/ContactSection'
import SetPage from '../../data/SetPage'
import { Helmet } from 'react-helmet'

const ContactPage = () => {

useEffect(()=>{
SetPage("Contact")
window.scrollTo(0,0)
},[])

  return (
    <div >
<Helmet>‍
        <title>Contact Us - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
     <div className="contact-page-crumb min-vh-100 pb-5">
     <ContactSection/>

     </div>
     
  
     
     </div>
  )
}

export default ContactPage