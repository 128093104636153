import React from 'react'
import defaultuser from '../../assets/user.png'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import { AuthContext } from '../../context/AuthContext'
import {  Avatar, Skeleton } from '@mui/material';
import axios from 'axios'
import ClearAll from '@mui/icons-material/ClearAll';
import ChevronRight from '@mui/icons-material/ChevronRight';
import preferenceicon from '../../assets/notification-settings.svg'
import nonotifications from '../../assets/no-notifications.svg'
import SetPage from '../../data/SetPage'
import DetectDevice from '../../data/DetectDevice'
import { Helmet } from 'react-helmet'
import 'firebase/database';

const NotificationsPage = ({bottommenu}) => {


 const [notifications, setNotifications] = useState("initial");






    const {user} = useContext(AuthContext)
const device = DetectDevice()
    const userdata = user;
     
             const [unread,setunread] = useState(0);

        const [interval,setinterval] = useState(0)
  


        const  getnotifications = async ()=>{
           const data = new FormData()
           data.append('session',user.SessionId)
        try{
          const res = await axios.post(ENDPOINT+"get-notifications.php",data,{headers:{
            'content-type':'multipart/form-data'
          }})

if(res){
const result = res.data;
  if(result[0].status==="success" && result[0].data!==null){
               

         
    setNotifications(result[0].data);
 setunread(result[0].unread)
    



}
else{
    setNotifications(null)
}
}

        

        }
        catch{

        }
            
            }    ;









        useEffect(()=>{
           
        window.scrollTo(0,0)
            SetPage("Notifications")
        getnotifications();
               
        }
        
        ,[interval])
        
        
        
        const [clearall,setclearall] = useState(false)
        
       const updateStatus = async ({id,clear})=>{
           
     
          try{
               
            
        const data = new FormData()
        data.append('notification',id)
        data.append('session',user.SessionId)
        if(clear){
          data.append('clear',clearall)
        }
        const res = await axios.post(ENDPOINT+"update-notification.php",data,{headers:{'content-type':'multipart/form-data'}})
           

        if(res){
          //  //  //console.log("clear "+clear+" "+JSON.stringify(res))
          if(res.data[0].status==="success"){

            getnotifications();
            setclearall(false)
          }
          else{
            
          }
        }
          }
          catch{

          }
         
       } ;
        
        
        




React.useEffect(() => {
  
    const interval = setInterval(() => {
      getnotifications();
    }, 5000);
  
    return () => {
     
      clearInterval(interval);
    };
  }, []); // has no dependency - this will be called on-component-mount




  const acceptRequest = async ({member})=>{

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
      formData.append('following_id',member.created_by);
    
      formData.append('notification_id',member.id);
    
    
    const res = await axios.post(ENDPOINT+"accept-request.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
    // //  //  //console.log("following "+JSON.stringify(res.data))
     if(res.data.status==="success"){
getnotifications()
     }
     else if(res.data.status==="error"){
     
     }
    }
    }

const ClearAll = ()=>{
  setclearall(true);
  updateStatus("nothing")
}
  
    return (
      
         <>
         
         

         <Helmet>‍
        <title>Notifications - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>



<div className="toast-container">

</div>





<div className="flex-wrap d-flex container mx-auto justify-content-between  sm-pt-0 min-vh-100 mt-2">









<div className="col-lg-7 col-12 sm-p-0 overflow-y vh-100">



{notifications==="initial"?

<>

{/*initial condition*/}
<div className="col-lg-12 col-md-12 col-sm-12">
<div className="flex container-fluid mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>



<div className="flex container-fluid mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>



<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>



<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>




<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>




<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>



<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>




<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>



<div className="flex container-fluid  mt-3 mb-3">
<Skeleton variant="circular" width={40} height={40} />
<div className="container-fluid">
<Skeleton variant="text" className="w-100" />
<Skeleton variant="text" className="w-100" />
</div>

</div>

</div>
</>
:<>
{notifications?
<>
{device==="mobile"?
<>


</>:
<>


<div className="d-flex justify-content-between align-items-center mb-2 p-3 bg-dark text0light">
  <h2 className="text-light">Notifications </h2>
  <p className="mb-0 pb-0 click text-light"  onClick={()=>{updateStatus({id:null,clear:true})}}>Mark All Read</p>
</div>


</>}


{notifications.map((notification)=>(

<>

{
notification.category==="connection"?

<>

{/*connection notification*/}
{notification.title==="Connection request accepted"?

<>
{/*accepted*/}

<div to={notification.action} type="button" className={" border-bottom notification-item  d-flex align-items-center align-center   p-2"+(notification.read_status==="true"?" read":" unread")} onClick={()=>{updateStatus({id:notification.id,clear:false})}}>

     <Avatar  src={notification.image?notification.image:defaultuser} />

<div className="d-flex w-100 align-items-center">
<div className="container-fluid pl-0">
<div className="container-fluid text-secondary p-0 position-relative ">
<p className="mb-0 col-12 p-0 truncate truncate2">{notification.description}</p>
</div>
 </div>

</div>
</div>
</>
:
<>
{/*connection notification*/}
<div to={notification.action} type="button" className={" border-bottom notification-item  d-flex align-items-center align-center   p-2"+(notification.read_status==="true"?" read":" unread")} onClick={()=>{updateStatus({id:notification.id,clear:false})}}>
<Avatar  src={notification.image?notification.image:defaultuser} />
<div className="flex w-100 align-items-center">
<div className="container-fluid pl-0">
<div className="container-fluid text-secondary p-0 position-relative ">
<p className="mb-0 col-12 p-0 truncate truncate2">{notification.description}</p>
</div>
 </div>
<div className="flex">
<button className="btn btn-danger mr-2">Reject</button>
<button onClick={()=>{acceptRequest({member:notification})}} className="btn btn-primary">Accept</button>
</div>

</div>
</div>

</>
}

</>
:
<>
{notification.category==="like"?


<>
{/*liked notification*/}

</>
:<>
{/*neither liked nor connection*/}
{notification.action?

<>
<Link to={notification.action} type="button" className={" border-bottom notification-item  d-flex align-items-center align-center  p-2"+(notification.read_status==="true"?" read":" unread")} onClick={()=>{updateStatus({id:notification.id,clear:false})}}>
<Avatar  src={notification.image?notification.image:defaultuser} />
<div className="container-fluid pl-0">
<h6 className=" sm-16 truncate truncate-1 text-dark">{notification.title}</h6>
<div className="container-fluid text-secondary p-0 position-relative ">
<p className="mb-0 col-12 p-0 truncate truncate-1">{notification.description}</p>
</div>
 </div>
</Link>
</>
:<>
<div type="button" className={" notification-item  d-flex align-items-center align-center   p-2"+(notification.read_status==="true"?" read":" unread")} onClick={()=>{updateStatus({id:notification.id,clear:false})}}>
<Avatar  src={defaultuser} />
<div className="container-fluid pl-0">
<h6 className=" sm-16 text-truncate">{notification.title}</h6>
<div className="container-fluid text-secondary p-0 position-relative ">
<p className="mb-0 col-12 p-0 text-truncate2">{notification.description}</p>
</div>
 </div>
</div>
</>
}
</>
}

</>
}

</>



))
}
</>:<>

<div className="justify-center text-center">
<div className="w-50">
<img alt="no notifications" src={nonotifications} className="w-100 mb-3"/>
<p>No Notifications</p>
</div>
</div>
</>}
</>
}


</div>





<div className="col-lg-5 d-none d-sm-block col-xl-4 col-sm-12 col-12 mt-4">
<div className="shadow text-center  col-12 p-3">

<h5 className="sm-18">Improve your notifications !</h5>
<Link to="/notification-settings/" className="sm-14" >Change Notification Settings <ChevronRight/> </Link>
<img src={preferenceicon} className="w-100 mb-3 mt-4" alt=""/>

</div>
</div>






</div>
         </>
     
    )
}

export default NotificationsPage
