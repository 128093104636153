import React, { useEffect, useState } from 'react';
import '../../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';


const Gallery = ({ limit }) => {

  const [more, setmore] = useState(false)
  const [show, setshow] = useState(false)
  const handleClose = () => {
    setshow(false)
  }

  const ShowImage = ({ image }) => {
    setimage(image)
    setshow(true)
  }
  const btn = 'btn-tranparent';
  const [image, setimage] = useState(null)
const [color,setColor]=useState({
  btn1:'btn-primary',
  btn2:btn,
  btn3:btn
});
  const [query, setQuery] = useState('');

  const [images, setImages] = useState([])

  const GetGallery = async () => {
    try {
      const data = new FormData();
      data.append('page', 'gallery')
      if (limit) {
        data.append('limit',limit)
      }
      if (query) {
        data.append('page', query)
      }

      const res = await axios.post(ENDPOINT+"get-gallery.php",data,SecurityHeaders);
      if (res) {

        if (res.data.status === 'success') {
          setImages(res.data.data);
          // console.log(res.data.data)

        }
      }
    }
    catch (err) {
      console.log("fetching gallery error", err.message);
    }

  }
  useEffect(() => {
    GetGallery()
  }, [query]);
  return (

 
    <div className='container mb-5 lg-mb-0'>
    
    <div className='d-flex align-items-center justify-content-between flex-wrap w-100'>
          <div>
            <h1><b>Latest Gallery of <br></br><span className="text-primary ms-lg-2 ms-0">Our Team In Action !</span></b></h1>
          </div>
          <div className='col-lg-5 col-md-5 pb-1 md-pb-0 col-12 d-flex overflow-x-auto m-0 m-md-4 justify-content-start justify-content-md-end'>
        <button className={`btn h-fit w-fit me-2 rounded shadow rounded-pill ${color.btn1}`} onClick={() => {setQuery("all");setImages(null);setColor({...color,btn1:'btn-primary',btn2:btn,btn3:btn,btn4:btn})}}>All Images</button>
        <button className={`btn h-fit w-fit me-2 rounded shadow rounded-pill ${color.btn2}`} onClick={() => {setQuery("teaching");setImages(null);setColor({...color,btn1:btn,btn2:'btn-primary',btn3:btn,btn4:btn})}}>Teaching</button>
        <button className={`btn h-fit w-fit me-2 rounded shadow rounded-pill ${color.btn3}`}onClick={() => {setQuery("team");setImages(null);setColor({...color,btn1:btn,btn2:btn,btn3:'btn-primary',btn4:btn})}}>Team</button>
        <button className={`btn h-fit w-fit me-2 rounded shadow rounded-pill ${color.btn4}`}onClick={() => {setQuery("office");setImages(null);setColor({...color,btn1:btn,btn2:btn,btn3:btn,btn4:'btn-primary'})}}>Office</button>
      </div>
        </div>
    <hr></hr>

    <div className='d-flex flex-wrap'>

{
  images ? <>
    {
      images.map((item, index) => (
        <div key={index} className='case-image p-2 col-lg-4 col-md-6 col-sm-12 col-6'>
          <img className=' w-100 rounded-1' src={item.image_url} alt='Research & Energy' />
       {item.tittle||item.description?
          <div className='hidden-case-1'>
          <h2>{item.tittle}</h2>
          <p className='text-white'>{item.description}</p>
        </div>:
          <></>
       }
        </div>
      ))
    }
  </> : <></>}


</div>
    </div>
  )
};

export default Gallery;
