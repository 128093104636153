import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactSection from "../../components/ContactSection";
import Reviews1 from "../reviews/Reviews1";
import Reviews2 from "../reviews/Reviews2";
import FeaturesList from "./FeaturesList";
import CoursesCategory from "./CoursesCategory";
import SetPage from "../../data/SetPage";
import CoursesHorizontalSlider from "./CoursesHorizontalSlider";
import DetectDevice from "../../data/DetectDevice";
import { CheckCircle } from "@material-ui/icons";
import { Helmet } from "react-helmet";
const AllCourses = () => {
  useEffect(() => {
    SetPage("Courses");
    window.scrollTo(0, 0);
  }, []);

  const device = DetectDevice();
  return (
    <div className=" pb-5 mb-5">
      <Helmet>
        ‍<title>All Courses - Job Ready Training Programs and Courses | Skills Uprise</title>‍
        <meta
          name="description"
          content="Login into your account and learn the best skills from our curated courses"
        />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="Login into your account and learn the best skills from our curated courses" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <div className="">
        <div className="  pt-5 pb-5">
          {device === "mobile" ? (
            <>
             
              <div className="container mt-3">
                <CoursesHorizontalSlider mode={"offline"} />
              </div>

              <div className="container mt-3">
                <CoursesHorizontalSlider mode={"live"} />
              </div>

              <div className="container mt-3">
                <CoursesHorizontalSlider mode={"selfpaced"} />
              </div>

              <div className="container mt-3">
                <CoursesHorizontalSlider mode={"specialization"} />
              </div>
            </>
          ) : (
            <>
              <div className="container bg-dark free-bg text-light justify-content-evenly p-5 sm-p-2 br-5 d-flex flex-wrap  mb-5">
                <div className="col-lg-3">
                  <img
                    src="https://skillsuprise.com/resources/images/girl-study.png"
                    className="w-100 d-none d-sm-none d-md-block d-lg-block"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="secondary-text ">
                    Learn On-Demand Skills For Free
                  </h2>

                  <div className="d-flex flex-wrap">
                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Pre-recorded
                        videos
                      </div>
                    </div>
                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Practical
                        course content
                      </div>
                    </div>

                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Certificate of
                        training
                      </div>
                    </div>

                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Placement
                        support
                      </div>
                    </div>

                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Beginner
                        friendly
                      </div>
                    </div>

                    <div className="col-6 mt-2 mb-2">
                      <div className="d-flex align-items-center">
                        <CheckCircle className="test-success" /> Life-time
                        access
                      </div>
                    </div>
                  </div>

                  <Link
                    to="/free-courses"
                    className="btn btn-primary rounded-pill mt-3"
                  >
                    Browse Free Courses
                  </Link>
                </div>
              </div>

              <CoursesCategory
                specialization={true}
                offline={true}
                selfpaced={true}
                live={true}
              />
            </>
          )}
        </div>
      </div>

      <div className="mt-5">
        <FeaturesList />
      </div>

      <div className="pt-5">
        <Reviews2 />
      </div>

      <div className="pt-5 pb-5">
        <Reviews1 />
      </div>

      <div className="pb-5">
        <ContactSection />
      </div>
    </div>
  );
};

export default AllCourses;
