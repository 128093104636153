import axios from 'axios'
import { useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const GetClasses = async ({payload})=>{

const data = new FormData()
data.append("course_url",payload.course_url)
data.append("status",payload.status)
data.append("batch_id",payload.batch_id)
if(payload.user){

     data.append("session",payload.user)
}

const res = await 

axios.post(ENDPOINT+`live-schedule.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}





export default GetClasses