import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import defaultdp from "../../assets/icons/defaultdp.svg";
import "tui-image-editor/dist/tui-image-editor.css";
import { useState } from "react";

import ModalStyle from "../../data/ModalStyle";

import Cropper from "react-easy-crop";
import classes from "../community/classes";
import Toast from "react-bootstrap/Toast";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";

import getCroppedImg from "../community/cropImage";
import { useCallback } from "react";
import Modal from "@mui/material/Modal";

import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

import ENDPOINT from "../../data/ENDPOINT";
import { Edit } from "@material-ui/icons";
import { Camera, CameraAlt } from "@mui/icons-material";
import SimpleNotificationModal from "../../components/modals/SimpleNotificationModal";

const IDCardRequest = ({ account, RefreshPosts, IDCardChanged, self }) => {
  const [file, setfile] = useState(null);
  const reader = new FileReader();

  const [status, setstatus] = useState(false);
  const [filetype, setfiletype] = useState(null);

  const [uploadprogress, setuploadprogress] = useState(0);

  const [filesize, setfilesize] = useState(0);

  const { user } = useContext(AuthContext);

  const FileChange = (e) => {
    setfile(e.target.files[0]);
    setpath(URL.createObjectURL(e.target.files[0]));
  };

  {
    /*


   formData.append('description',payload.desc);
     
     }
        formData.append('session',payload.user);
       
        formData.append('course_url',payload.course_url);
        formData.append('category',payload.category);
        formData.append('batch_id',payload.batch_id);
*/
  }
  const [posts, setposts] = useState(null);

  const UploadImage = async () => {
    setuploading(true);
    setfilesize(file.size);
    setfiletype(file.filetype);

    ////  //  //console.log("changed"+file.filetype)
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // //  //  //console.log("total "+progressEvent.total)
        setuploadprogress(percentCompleted);

        setfilesize(progressEvent.total / 1000000);
      },
    };

    const formData = new FormData();

    ////  //  //console.log("Type: " + file.type);
    ////  //  //console.log("Size: " + file.size + " bytes");
    //check for image
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    ) {
      formData.append("session", user.SessionId);

      formData.append("id_photo", croppedImage);

      const res = await axios.post(
        ENDPOINT + `upload-studentid-photo.php`,
        formData,
        config,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      if (res) {
        //  //console.log("dp uploaded"+JSON.stringify(res))
        setuploading(false);
        if (res.data.status === "success") {
          setuploadmodal(false);
          settoasttitle("ID Request Submitted !");
          settoastmsg("Your ID Card will be updated soon.");
          settoast(true);
        } else {
          settoasttitle("Something went wrong !");
          settoastmsg("Please try again after sometime.");
          settoast(true);
        }
      }
    } else {
      settoasttitle("Warning !");
      settoastmsg("Please upload a valid image type.");
      settoast(true);
    }
  };

  const [path, setpath] = useState(null);
  const [imagemodal, setimagemodal] = useState(false);

  const [showfilters, setshowfilters] = useState(false);
  const [filtermodal, setfiltermodal] = useState(false);

  const ShowFilters = () => {
    setimagemodal(false);
    setfiltermodal(true);
  };

  const StartUpload = () => {
    const button = document.getElementById("image-upload");
    button.click();
  };

  const dogImg = path;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const [croppedImageUrl, setcroppedImageUrl] = useState(null);
  const showCroppedImage = useCallback(async () => {
    setsettingimg(true);
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      );

      setcroppedImageUrl(URL.createObjectURL(file));
      //  //console.log("croppped url"+JSON.stringify(setcroppedImageUrl))
      //  //console.log(croppedImage)
      setCroppedImage(croppedImage);
      setuploadmodal(true);
      setfiltermodal(false);
      setsettingimg(false);
      IDCardChanged({ image: croppedImage });
    } catch (e) {
      setsettingimg(false);
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const [description, setdescription] = useState(null);

  const [state, setstate] = useState();

  const [settingimg, setsettingimg] = useState(false);
  const [uploadmodal, setuploadmodal] = useState(false);

  const CancelUpload = () => {
    setfile(null);
    setCroppedImage(null);
  };

  const [uploading, setuploading] = useState(false);

  const [toasttitle, settoasttitle] = useState(null);
  const [toastmsg, settoastmsg] = useState(null);
  const [toast, settoast] = useState(false);
  return (
    <>

    <SimpleNotificationModal show={toast} data={{title:toasttitle,description:toastmsg}} handleClose={()=>settoast(false)}/>
      <div className="toast-container">
        <Toast
          className="bg-warning"
          onClose={() => settoast(false)}
          show={toast}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmsg}</Toast.Body>
        </Toast>
      </div>

      <Modal
        open={imagemodal}
        onClose={() => {
          CancelUpload();
          setimagemodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyle}
          className="p-2 br-5 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-10 mx-auto"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="d-flex justify-content-between">
              <h5
                className="click"
                onClick={() => {
                  CancelUpload();
                  setimagemodal(false);
                }}
              >
                Cancel
              </h5>
              {file ? (
                <>
                  <h5
                    onClick={() => ShowFilters()}
                    className="click text-primary"
                  >
                    Next
                  </h5>
                </>
              ) : null}
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <img
              src={file ? URL.createObjectURL(file) : ""}
              className="w-100"
            />
            <input
              id="image-upload"
              className="btn-upload d-none"
              type="file"
              onChange={(e) => FileChange(e)}
            />

            <button
              onClick={() => StartUpload({ file: file })}
              className="btn-upload btn-sm btn-lg  w-100 mb-3 mt-3"
            >
              {file ? "Change Image" : "Select Image"}
            </button>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={uploadmodal}
        onClose={() => {
          setuploadmodal(false);
          CancelUpload();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyle}
          className="p-2 br-5 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-10 mx-auto"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="d-flex flex-wrap justify-content-between">
              <h5
                className="click"
                onClick={() => {
                  CancelUpload();
                  setuploadmodal(false);
                }}
              >
                Cancel
              </h5>
            </div>

            <div className="container-fluid">
              {croppedImage ? (
                <>
                  <img
                    src={URL.createObjectURL(croppedImage)}
                    className="w-100"
                  />
                </>
              ) : null}

              {uploading ? (
                <>
                  <button disabled className="btn-primary  btn w-100 mb-3 mt-3">
                    Updating...
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => UploadImage()}
                    className="btn-primary  btn w-100 mb-3 mt-3"
                  >
                    Upload Photo
                  </button>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={filtermodal}
        onClose={() => setfiltermodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyle}
          className="p-2 br-5 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-10 mx-auto"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="d-flex justify-content-between">
              <h5
                className="click"
                onClick={() => {
                  CancelUpload();
                  setfiltermodal(false);
                }}
              >
                Cancel
              </h5>
              <h5
                className="text-primary click"
                onClick={() => {
                  showCroppedImage();
                }}
              >
                Next
              </h5>
            </div>
          </Typography>

          <>
            <div className=" h-fit position-relative">
              {path ? (
                <>
                  <img src={path} className="w-100 op-0" />
                </>
              ) : null}
              <div className="crop-container">
                <Cropper
                  className="cropper"
                  image={dogImg}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </div>
          </>
          <div>
            <img src={croppedImage} />
          </div>

          <div className=" mt-3 ">
            <div className="mb-3 mt-3 crop-range">
              <p>Zoom Percentage</p>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="text-center">
                  <RotateLeftIcon
                    type="button"
                    onClick={() => setRotation(rotation - 90)}
                  />
                  <p className="extra-small">Rotate Left</p>
                </div>

                <div className="text-center ml-15">
                  <RotateRightIcon
                    type="button"
                    onClick={() => setRotation(rotation + 90)}
                  />

                  <p className="extra-small">Rotate Right</p>
                </div>
              </div>

              {settingimg ? (
                <>
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.cropButton }}
                  >
                    <CircularProgress
                      color="inherit"
                      className="mr-5"
                      size={20}
                      disableShrink
                    />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.cropButton }}
                  >
                    Done
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>


        <button className="border rouonded px-3 py-2 border-1" onClick={() => setimagemodal(true)}>Upload Photo</button>
      
    </>
  );
};

export default IDCardRequest;
