import { Chip, CircularProgress, Rating, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContactSection from '../../components/ContactSection'
import ThemeDivider from '../../components/ThemeDivider'

import AlumniSection from '../home/AlumniSection'

import Toast from 'react-bootstrap/Toast';
import { CheckBoxRounded, Close, PlayCircle } from '@mui/icons-material'
import process from '../../assets/images/admission-process.png'
import DetectDevice from '../../data/DetectDevice'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import SetPage from '../../data/SetPage'

import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

import { WhatsApp } from '@material-ui/icons'
import { Helmet } from 'react-helmet'
import WorkshopReviews from '../reviews/WorkshopReviews'
const HackingScholarship = () => {

    const device = DetectDevice()
    const [register,setregister] = useState(false)
    const [term,setterm] = useState("short")
    const [demo,setdemo] = useState(false)
useEffect(()=>{
SetPage("Hacking Internship")
},[])
    const [batch,setbatch] = useState("batch1")




const [list,setlist] = useState(["manoj","kumar"])




const [name,setname] = useState(null)
const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [firstname,setfirstname] = useState(null)
const [lastname,setlastname] = useState(null)
const [loading,setloading] = useState(false)
const Register =  async()=>{
    setloading(true)
setsubmit(true)
const data = new FormData()
data.append("name",firstname+" "+lastname)
data.append("email",email)
data.append("phone",phone)
data.append("term",term)
data.append("batch",batch)


const res= await axios.post(ENDPOINT+"hacking-internship-register.php",data,SecurityHeaders)
if(res){
    setloading(false)
setsubmit(false)
//console.log(res.data)
    if(res.data[0].status==="success"){

settoasttitle("Application Successful")
settoastmessage("Our team will contact you soon .")
setstatusmodal(true)
settoast(true)
setregister(false)
    }
    else{
        settoasttitle("Something went wrong !")
        settoastmessage("Please try again after sometime")
        settoast(true)
    }
}



}












const [input,setinput] = useState(null)

const [submit,setsubmit] = useState(false)

const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [statusmodal,setstatusmodal] = useState(false)
  return (
    <div className="w-100">
     

     <Helmet>

<title>Ethical Hacking & Cyber Security Internship | Skills Uprise</title>
<meta name="description" content="Get trained and intern as an ethical hacker or a cyber security by getting trained with Skills Uprise "/>

</Helmet>

<div className="toast-container">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
         
            <strong className="me-auto">{toasttitle}</strong>
        
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

</div>





<Modal
        open={statusmodal}
        onClose={()=>{setstatusmodal(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Application Successful !
         <Close className="click" onClick={()=>setstatusmodal(false)}/>
          </Typography>
       
       <p>Your application was successfully submitted. Our team will reach you within 48 hours to assist your further.</p>
        </Box>
      </Modal>















<div>

<Modal
        open={register}
        onClose={()=>{setregister(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Apply for Internship
         <Close className="click" onClick={()=>setregister(false)}/>
          </Typography>
       
<form method="post" onSubmit={(e)=>{e.preventDefault();Register()}}>
    
<TextField className="form-control mt-2 mb-2"
          type="text"  
          onChange={(e)=>setfirstname(e.target.value)} 
          label="First Name"
        required
        />

<TextField type="text"  className="form-control mt-2 mb-2"
          onChange={(e)=>setlastname(e.target.value)} 
         required
         label="Last Name"
       
       />
<TextField className="form-control mt-2 mb-2"
          onChange={(e)=>setemail(e.target.value)} 
         type="email"
         label="Email"
         
       required
       />
<TextField onChange={(e)=>setphone(e.target.value)} className="form-control mt-2 mb-2"
         type="number"
         required
         label="Phone Number"
       
       />
       <select required className="form-control mt-2 mb-2" value={term} onChange={(e)=>{setterm(e.target.value)}}>
<option>Internship Duration</option>

<option value="short">Short Term (2 months)</option>
<option value="mid">Mid Term (4 months)</option>
<option value="long">Long Term (6 months)</option>
       </select>

    

       <button  type="submit" disabled={loading} className="btn btn-primary w-100 mt-4 workshop-progress  align-content-center justify-content-center d-flex">{submit?<><CircularProgress sx={{color:"white"}}/> Submitting... </>:"Submit Application"}</button>
     
    </form>   </Box>
      </Modal>




      <Modal
        open={demo}
        onClose={()=>{setdemo(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
        Internship Demo
         <Close className="click" onClick={()=>setdemo(false)}/>
          </Typography>
       
      
<video src="https://skillsuprise.com/data/videos/hacking-internship.mp4" controls className="w-100"></video>

  </Box>
      </Modal>


      <div className="container-fluid min-vh-80 pt-2 pt-md-5 pb-5  hacking-internship-bg bg-dark text-light ">
       

       <div className="container flex-wrap flex-column-reverse flex-md-row justify-content-between d-flex">
<div className="col-lg-6 mt-4 mt-md-0">
<h1>Ethical Hacking & Cyber Security Training & Internship</h1>
<div className="d-flex my-1">
    
<Rating name="read-only" value={4.5} precision={0.5} readOnly />

<h6>4.8/5 (5,000+ Ratings)</h6>
</div><p className="text-desc mt-2">Learn Hacking from the best trainer who <span className="hacking-span">Trained DSP Rank Officers</span>  , College Students and Corporate Teams</p>
    
   <hr></hr>

<ul>
<li className="mt-3 text-secondary">Certified Training & Internship</li>
   
    <li className="mt-3 text-secondary">100% Practical Training</li>
    <li className="mt-3 text-secondary">Real-time Projects & Internship</li>

</ul>
 <div className="d-flex mt-4">
    <a href="https://wa.me/?number=919490174876&text=Ethical%20Hacking%Internship%20Details%Needed&type=custom_url&app_absent=0" target="_blank" className="btn me-4 btn-success"><WhatsApp/> Contact Us</a>
   
   {register?
   <>
   
   
   </>:
   <>
       <button onClick={()=>setregister(true)} className="btn btn-orange-red bottom-fixed-button">Apply For Internship</button>
 
   </>}
   
</div>
</div>

<div className="col-lg-5 p-2 h-fit bg-white rounded">
<img className="w-100" src="https://skillsuprise.com/resources/images/trainer.png"/>
</div>
       </div>

    

        </div>





        

        <div className="container  br-5 pb-5 mt-5 pt-5 mb-5">
    
        
           
          <div className="d-flex  flex-wrap justify-content-between">
<div className="col-lg-6">
<img className="w-100 rounded " src="https://skillsuprise.com/resources/images/notebook.jpg"/>
</div>
<div className="col-lg-6 ps-5 sm-p-0 sm-mt-20">
<h2 className="uppercase">Master Hackers Machine<br></br>From Scratch To Advanced Levels</h2>
            <p>Work with 100+ hacking tools, techniques and practical labs</p>


<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6 className="pb-0 ml-2 mb-0">Live instructor led training</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 ml-2 mb-0">Access to hacking Lab</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Premium tools and softwares</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">CTF events</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Bug bounty programs</h6>
</div>
</div>

          </div>
            
        </div>









        <div className=" container col-lg-9 col-md-10 col-sm-12 col-12 mx-auto   container">



<div className="container hacking-line bg-dark text-light mt-5 pt-5 pb-5 mb-5 shadow br-5">




<div className="container  d-flex flex-wrap">
    <div className="col-lg-8">
    <h2>Work With Real-World Hacking Projects</h2>
<p className="text-light">Perform Penetration testing on an E-Commerce website like Amazon using Kali Linux, Burp Suite, Recon-ng framework, etc to discover hidden flags and vulnerabilities.</p>

    </div>

    <div className="col-lg-4 mt-sm-3 d-none align-items-center justify-content-center">
<button className="rounded-circle play-hacking btn-lg"><PlayCircle className="text-light"/></button>
    </div>

</div>

</div>





<div className="d-flex br-5 mt-5 flex-wrap text-center flex-wrap justify-content-between">






<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-system.png" className="w-100  rounded-top"/>
    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 1</p>
     
    <p >You will learn to hack laptops and systems by using advanced hacking tools and techniques </p>
 
    </div>
      </div>
 
</div>







<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-wifi.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 2</p>
     
   
    <p>You will learn to hack wifi's and wireless networks using kali linux and few automated tools</p>
 
    </div>
      </div>
 
</div>


<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-mobile.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 3</p>
     
 
    <p>You will learn to hack mobiles using kali linux and latest hacking techniques</p>
 
    </div>
      </div>
 
</div>


<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/secure-company.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 4</p>
     
  
    <p>You will learn to hack real websites with the modern hacking techniques</p>
 
    </div>
      </div>
 
</div>




<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/fb-hacking.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 5</p>
   
    <p>You will learn the best 100% successful way to hack your target facebook accounts</p>
 
    </div>
      </div>
 
</div>




<div className="col-lg-4  p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/instagram-hacking.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 6</p>
     
    <p>You will learn the right techniques to hack into instagram accounts with best hacking tools</p>
 
    </div>
      </div>
 
</div>

</div>


    </div>




    <div className="mt-5 mb-5">


<div className="container d-flex flex-wrap justify-content-between">

<div className="col-lg-6   br-5 p-4 sm-p-0">
<img src="https://skillsuprise.com/assets/certificates/hacker.png" className="w-100"/>
       
</div>

<div className="col-lg-6 sm-mt-20  br-5 p-4 sm-p-0">
<h2>Training & Internship Certificate</h2>
<p>An X-Factor to your resume and the best way to prove your skills to land your dream job in cyber security</p>


<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of training</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of Internship</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Best Intern Certificate (top performers)</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Recommendation Letter (top performers)</h6>
</div>
</div>
</div>
    
    </div>





  



    
    <div className="my-5">
<WorkshopReviews/>


</div>

    <div className="w-100 batches-bg  ">
  <div className="container  pt-5   ">
            <div className="text-center">
                <h2>Flexible Batches & Timings</h2>
            </div>
            <ThemeDivider/>
            <div className="d-flex justify-content-center ">
                <div className="col-lg-12 d-flex flex-wrap p-3 ">
   
<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border  br-5">

    <div className="bg-dark rounded-top  hacking-line2 text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 1</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 15th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 13th May</p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>
<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border br-5">

    <div className="bg-dark rounded-top hacking-line2  text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 2</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 25th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 22nd May </p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>   









<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border br-5">

    <div className="bg-dark rounded-top hacking-line2 rounded-top text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 3</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 30th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 28th May</p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>
                </div>
            
            </div>
        </div>
        
  </div>









<div className="container    d-flex flex-wrap d-none">

<div className="col-lg-8 process p-3 ">
<div className="d-flex">

    <div className="p-3">
        <h2>Admission Process</h2>
   
    
    </div>
</div>
<div className="d-flex  justify-content-between flex-wrap">

<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">1</h5>
</div>
<p  className="mb-0 pb-0">Apply for internship</p>
 </div>

</div>


<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">2</h5>
</div>
<p  className="mb-0 pb-0">Attend a free demo</p>
 </div>

</div>



<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">3</h5>
</div>
<p  className="mb-0 pb-0">Confirm batch & timings</p>
 </div>

</div>





<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">4</h5>
</div>
<p  className="mb-0 pb-0">Start learning & build your career</p>
 </div>

</div>


</div>
 

</div>

<div className="col-lg-4 p-3 hacker-bg bg-dark text-light col-sm-4 col-12 d-flex align-items-center justify-content-center">
<div className="text-center">
    <h3 className="text-light">Don't know how to start ?</h3>
    <button className="btn btn-primary  rounded-pill mt-3" onClick={()=>setdemo(true)}><PlayCircle/> Watch Demo</button>
</div>
</div>


</div>

 





        <div className="container d-none">
         <AlumniSection/>
   
        </div>
        

        <div className="pb-5">
        <ContactSection/>
        </div>

</div>
        
        </div>
  )
}

export default HackingScholarship