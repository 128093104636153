import React, { useContext, useState } from 'react';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { AuthContext } from '../../context/AuthContext';
import IDCardRequest from './IDCardRequest';
import {Toast } from 'react-bootstrap'

const UploadStudentIdCard = () => {
    const { user } = useContext(AuthContext);
    const [IdPhoto, setIdPhoto] = useState('');
    const [ToastTitle,setToastTitle] = useState(null);
    const [ToastMessage,setToastMessage] = useState(null)
    const [toast,setToast] = useState(false)
   

    const UploadPhoto = async () => {
        try {
            const data = new FormData();
            data.append('session', user.SessionId);
            data.append('id_photo', IdPhoto);

            const res = await axios.post(ENDPOINT + "upload-student-photo.php", data, SecurityHeaders);
            if (res.data) {
               
               if(res.data.status ==="success"){
                setToast(true)
                setToastTitle("Successfully Upload Id")
               }
                
            }
            else{
                setToast(true)
                setToastTitle("Error Upload Id") 
                setToastMessage("Something went wrong")
            }
        } catch (error) {
            console.log("Error upload student id photo", error);
        }
    }
    

    return (
        <div>

<div className="toast-container">
  
  <Toast show={toast} onClose={()=>setToast(false)} delay={4000} autohide className="space-between bg-warning">
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">{ToastTitle}</strong>
         
        </Toast.Header>
        <Toast.Body>{ToastMessage}</Toast.Body>
      </Toast>


  </div>
            <div className='bg-light   bg-white border-dashed rounded p-3 col-12'>
                <h3 className='text-center'>Get Your Id Card Now !</h3>
                <hr />
                <p className='text-center'>Upload passport size photo to get Your id card</p>

                <div className='text-center p-2'>
                  <IDCardRequest/>
                            {IdPhoto? 
                      <></>    :null
                           }
                  
              
                </div>
            </div>
        </div>
    );
}

export default UploadStudentIdCard;
