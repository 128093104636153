import { Avatar } from '@mui/material'
import React from 'react'
import { useState,useEffect } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom'
import * as timeago from 'timeago.js';
import Box from '@mui/material/Box';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../context/AuthContext';
import ModalStyle from '../../data/ModalStyle';
import { Close } from '@material-ui/icons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RedirectNext from '../../actions/RedirectNext';
import FormatViews from './FormatViews';
import StudentAccessModal from '../../components/modals/StudentAccessModal';
const VideoItem = ({video,account}) => {

const {user} = useContext(AuthContext)
// has no dependency - this will be called on-component-mount



const [alert,setalert] = useState(false)

const [access,setaccess] = useState(false)


useEffect(()=>{
if(account){
  if(account.purchases){
    if(account.purchases.length>0){
      setaccess(true)
    }
  }
}
},[account])

const CloseStudentAccessModal = ()=>{

  setalert(false)
  }
  return (

    <>



<StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal}/>












    {account?

















    <>
    {access?
    <>
    <Link  to={"/watch/"+video.video_id} >

<div id={video.video_id} className="video-item bg-white">

<div className="video-cover-container">
<img src={video.cover_image} className="w-100 "/>
 
<div className="duration-count">
  <p  className="sm-12">{video.duration} </p>
</div>
</div>

<div className="info mt-2 text-dark">

<div className="d-flex">

</div>
<div className="d-flex">
<Avatar src={video.profile_pic}/>
<div className="ml-15">
<h6 className="b-0 pb-0 sm-14 truncate truncate2">{video.title}</h6>
<div className="d-flex align-items-center justify-content-between">
<p  className="extra-small">{video.posted_by}</p>
<p  className="extra-small">{FormatViews({views:video.views})} views</p>
<p className="extra-small">{timeago.format(video.upload_date,"dS mmm")}</p>
</div>

</div>

</div>

</div>

</div>
</Link>
    
    </>:
    <>
    <Link onClick={()=>{setalert(true);RedirectNext({payload:"/watch/"+video.video_id})}} >

<div id={video.video_id} className="video-item bg-white">

<div className="video-cover-container">
<img src={video.cover_image} className="w-100 "/>
 
<div className="duration-count">
  <p  className="sm-12">{video.duration} </p>
</div>

</div>

<div className="info mt-2 text-dark">

<div className="d-flex">

</div>
<div className="d-flex">
<Avatar src={video.profile_pic}/>
<div className="ml-15">
<h6 className="b-0 pb-0 sm-14 truncate truncate2">{video.title}</h6>
<div className="d-flex align-items-center justify-content-between">
<p  className="extra-small">{video.posted_by}</p>
<p  className="extra-small">{FormatViews({views:video.views})} views</p>
<p className="extra-small">{timeago.format(video.upload_date,"dS mmm")}</p>
</div>

</div>

</div>

</div>

</div>
</Link>
    
    
    </>}
     
    </>:
    <>
    
    <div className="click" onClick={()=>{setalert(true)}}  >

<div id={video.video_id} className="video-item">
<div className="video-cover-container">
<img src={video.cover_image} className="w-100 "/>
 

<div className="duration-count">
  <p className="sm-12">{video.duration} </p>
</div>
</div>

<div className="info mt-2 text-dark">

<div className="d-flex">

</div>
<div className="d-flex">
<Avatar src={video.profile_pic}/>
<div className="ml-15">
<h6 className="b-0 pb-0 sm-14 truncate truncate2">{video.title}</h6>
<div className="d-flex align-items-center justify-content-between">
<p  className="extra-small">{video.posted_by}</p>
<p  className="extra-small">{FormatViews({views:video.views})} views</p>
<p className="extra-small">{timeago.format(video.upload_date,"dS mmm")}</p>
</div>

</div>

</div>

</div>

</div>
</div>
    
    </>}
    
    </>
   
  )
}

export default VideoItem