import React, { useEffect, useState } from "react";

import divider from "../../assets/bg/divider.png";
import DetectDevice from "../../data/DetectDevice";
import mouse from "../../assets/icons/mouse.svg";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ContactSection from "../../components/ContactSection";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import student1 from "../../assets/images/student1.png";
import student2 from "../../assets/images/student2.png";
import student3 from "../../assets/images/student3.png";
import student4 from "../../assets/images/student4.png";
import Rating from '@mui/material/Rating';
import student5 from "../../assets/images/student5.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRightAlt";
import GetVideos from "../../actions/GetVideos";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import heroimage from "../../assets/images/homepage/hero.png";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ModalStyle from "../../data/ModalStyle";
import Close from "@mui/icons-material/Close";
import SetPage from "../../data/SetPage";
import AlumniSection from "./AlumniSection";
import ENDPOINT from "../../data/ENDPOINT";
import axios from "axios";
import CoursesCategory from "../courses/CoursesCategory";
import UserEngagement from "./UserEngagement";
import MobileHome from "./MobileHome";
import PlacementCompanies from "./PlacementCompanies";
import SuccessStories from "./SuccessStories";
import NavbarCourseSearch from "../../components/NavbarCourseSearch";
import Gallery from "../gallery/Gallery";
import ReelsCta from "../studentpass/ReelsCta";
import { Helmet } from "react-helmet";
import SaveUserEvent from "../../actions/SaveUserEvent";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import CustomVideoPlayer from "../../components/elements/CustomVideoPlayer";
import CollegesBusinessCta from "./CollegesBusinessCta";
import { useActionsContext } from "../../context/ActionsContext";
import { VolumeUp } from "@material-ui/icons";
const HomePage = ({ account }) => {
  const [results, setresults] = useState(null);
  const [mobile, setmobile] = useState(false);
  const device = DetectDevice();
  const { user } = useContext(AuthContext);
  const {search,ShowSearch,HideSearch} = useActionsContext()
  useEffect(() => {
    SetPage(null);

    //  //console.log(device)
    if (device === "mobile") {
      setmobile(true);
    } else {
      setmobile(false);
    }
    NewVideos();
  }, []);

  const [scrollsuggestion, setscrollsuggestion] = useState(false);

  const [carousel, setcarousel] = useState(false);

  const [videos, setvideos] = useState(null);
  const NewVideos = async () => {
    const res = await GetVideos({ payload: { limit: 2 } });
    if (res) {
      //  //console.log("got "+JSON.stringify(res))
    }
    if (res.status === "success") {
      setvideos(res.videos);
    }
  };

  const [demo, setdemo] = useState(false);
  const style = {
    width: 500,
  };

  const [query, setquery] = useState(null);

  const [statistics, setstatistics] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", TogglePrime);
  }, []);

  const TogglePrime = ()=>{
    const video = document.getElementById("prime-video1")
     if(video){
         const screenheight = window.innerHeight
         const videoposition = parseInt(video.getBoundingClientRect().top.toFixed())
   
         if(((videoposition+200)<screenheight) && videoposition>-100){
              video.play()
            
              if(video.onplaying){
                   video.removeAttribute("muted")  
              }
              if((video.onplaying)&&(parseInt(video.currentTime)>5)){
                  
              }
              
            }else{
              if(video.onplaying){
                   video.pause()
              }
             
            }
  }
}
  const NewOpenSearch = () => {};
  const [loginprompt, setloginprompt] = useState(false);
  const [displaysearch, setdisplaysearch] = useState(false);

  const [studentactivities, setstudentactivities] = useState(false);
  const [features, setfeatures] = useState(false);
  const [reviews, setreviews] = useState(false);

const [muted,setmuted] = useState(true)
  
  return (
    <>
      <Helmet>
        <title>
          Skills Uprise | India's Best Job guaranteed training programs
        </title>
        <meta
          name="description"
          content="Skills Uprise is India's no1 training platform with guaranteed placements | online training | offline training | pre-recorded courses | online courses | offline courses"
        />
      </Helmet>

      <div className="content home-page">
   
        <Modal open={demo} onClose={() => setdemo(false)}>
          <Box
            sx={ModalStyle}
            className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2"
          >
            <div className="d-flex justify-content-between">
              <h4>Introduction</h4>
              <Close
                className="text-danger"
                type="button"
                onClick={() => setdemo(false)}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <video
                className="w-100 bg-dark"
                controls
                autoPlay
                src="https://skillsuprise.com/data/videos/demo.mp4"
              ></video>
            </Typography>
          </Box>
        </Modal>

        <Modal open={features} onClose={() => setfeatures(false)}>
          <Box
            sx={ModalStyle}
            className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2"
          >
            <div className="d-flex justify-content-between">
              <h4>Features</h4>
              <Close
                className="text-danger"
                type="button"
                onClick={() => setfeatures(false)}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <video
                className="w-100 bg-dark"
                controls
                poster="https://skillsuprise.com/data/images/features.png"
                autoPlay
                src="https://skillsuprise.com/data/videos/features.mp4"
              ></video>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={studentactivities}
          onClose={() => setstudentactivities(false)}
        >
          <Box
            sx={ModalStyle}
            className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2"
          >
            <div className="d-flex justify-content-between">
              <h4>Student Activities</h4>
              <Close
                className="text-danger"
                type="button"
                onClick={() => setstudentactivities(false)}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <video
                className="w-100 bg-dark"
                controls
                poster="https://skillsuprise.com/data/images/student-activities.png"
                autoPlay
                src="https://skillsuprise.com/data/videos/student-activities.mp4"
              ></video>
            </Typography>
          </Box>
        </Modal>

        <Modal open={reviews} onClose={() => setreviews(false)}>
          <Box
            sx={ModalStyle}
            className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2"
          >
            <div className="d-flex justify-content-between">
              <h4>Students Reviews</h4>
              <Close
                className="text-danger"
                type="button"
                onClick={() => setreviews(false)}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <video
                className="w-100 bg-dark"
                controls
                poster="https://skillsuprise.com/data/images/student-reviews.png"
                autoPlay
                src="https://skillsuprise.com/data/videos/student-reviews.mp4"
              ></video>
            </Typography>
          </Box>
        </Modal>


        <section>
          <div className="w-100 hero sm-pt-40">
            <div className="container ">
              <div className="flex-wrap d-flex justify-content-between">
                <div className="col-lg-6">
                  <h1 className="prime-title home-title">
                    Learn, Intern & Get a Job
                  </h1>
                  <h5 className="prime-description">
                    Take your career to next level through our personalised
                    training programs.
                  </h5>

                  <div className="home-search  mt-3">
                    <Paper
                    
                      onClick={() => {
                        SaveUserEvent({
                          payload: {
                            session:
                              user != null || user != undefined
                                ? user.SessionId
                                : null,

                            page: window.location.pathname,
                            action: "HomePage-search",
                          },
                        });
                        ShowSearch()
                   
                      }}
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                  <div className="d-flex w-100 align-content-center justify-content-between">
                  <p className="text-secondary m-0 p-0 d-flex align-items-center ps-1">Search Courses</p>
                      <IconButton
                        type="submit"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                  </div>
                    </Paper>
                  </div>

                  <div className="d-flex mt-5 align-items-center">
                    <div class="d-flex align-items-center mr-15"></div>

                    <div className="p-0">
                      <div className="mt-3 d-flex justify-content-start">
                        <AvatarGroup>
                          <Avatar alt="Remy Sharp" src={student1} />
                          <Avatar alt="Travis Howard" src={student2} />
                          <Avatar alt="Trevor Henderson" src={student5} />
                          <Avatar alt="Cindy Baker" src={student3} />
                          <Avatar alt="Agnes Walker" src={student4} />
                        </AvatarGroup>
                      </div>
                      <h5 className="mt-4 text-white">
                        Kick-start your career with skillsuprise !{" "}
                      </h5>
                      {
                        user!= null || user != undefined ?
                      
                        <Link
                        onClick={() => {
                          SaveUserEvent({
                            payload: {
                              session:
                                user != null || user != undefined
                                  ? user.SessionId
                                  : null,

                              page: window.location.pathname,
                              action: "HomePage-courses",
                            },
                          });
                        }}
                        to="/courses"
                        className=" btn d-flex align-items-center w-fit btn-warning mt-3 rounded-pill sm-14 btn-sm"
                      >
                        BROWSE COURSES
                        <ArrowRightIcon
                          className="text-dark"
                          fontSize="large"
                        />
                      </Link>
                      :
                      <Link
                      onClick={() => {
                        SaveUserEvent({
                          payload: {
                            session:
                              user != null || user != undefined
                                ? user.SessionId
                                : null,
                            page: window.location.pathname,
                            action: "HomePage-registerbutton",
                          },
                        });
                      }}
                      to="/signup"
                      className=" btn d-flex align-items-center w-fit btn-warning mt-3 rounded-pill sm-14 btn-sm"
                    >
                      REGISTER FOR FREE
                      <ArrowRightIcon
                        className="text-dark"
                        fontSize="large"
                      />
                    </Link>

                      }
                      
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 sm-mt-20 sm-mb-20">
                  <img src={heroimage} className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5">
            <PlacementCompanies />
          </div>
        </section>

        {device === "mobile" ? (
          <>
            <div className="mt-5">
              <MobileHome />
            </div>
          </>
        ) : (
          <div className="mt-5 ">
            <CoursesCategory
              offline={true}
              live={true}
              selfpaced={true}
              specialization={true}
            />
          </div>
        )}

        {device === "mobile" ? (
          <></>
        ) : (
          <>
            <section>
              <div className="mt-5 mb-5 pt-5">
                <UserEngagement videos={videos} />
              </div>

              <div className="pt-5  pb-5 stories-bg">
                <div className="mt-5 pt-5 pb-5 results-driven container">
                  <SuccessStories />
                </div>
              </div>
            </section>

            <section>
              <div className="container mt-5 mb-5 pt-5 text-center">
                <h2 className="">Sudents Love, Trust & Respect Us</h2>
                <p className="col-lg-6 col-md-6 col-sm-9 col-12 mx-auto  sm-mb-20">
                  We offer the most interactive and competetive environment with team
                  challenges, competetions and many more to make learning a
                  loving experience
                </p>

                <div className="divider">
                  <img src={divider} />
                </div>
              </div>

              <div className="container d-flex flex-wrap justify-content-evenly animate">


                <div className="col-lg-3">
<video className="bg-dark border rounded w-100 h-100" controls src="https://skillsuprise.com/data/videos/home-review-main.mp4" poster="https://skillsuprise.com/data/videos/home-review-main.png"></video>
                  
                </div>


<div className="col-lg-7 flex-wrap d-flex ">
<div className="col-lg-6 col-md-6 col-6 p-2">
  <video className='w-100 rounded border bg-dark'  controls src='https://skillsuprise.com/data/videos/home-review1.mp4' poster='https://skillsuprise.com/data/videos/home-review1.png'></video>

   </div>

   <div className="col-lg-6 col-md-6 col-6 p-2">
  <video className='w-100 rounded border bg-dark'  controls src='https://skillsuprise.com/data/videos/home-review2.mp4' poster='https://skillsuprise.com/data/videos/home-review2.png'></video>

   </div>

   <div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
  <video className='w-100 rounded border bg-dark'  controls src='https://skillsuprise.com/data/videos/home-review3.mp4' poster='https://skillsuprise.com/data/videos/home-review3.png'></video>

   </div>


   <div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
  <video className='w-100 rounded border bg-dark'  controls src='https://skillsuprise.com/data/videos/home-review4.mp4' poster='https://skillsuprise.com/data/videos/home-review4.png'></video>

   </div>





</div>





</div>

              {/* <div className=" mt-3 container flex-wrap justify-content-evenly">
                <div className="col-lg-7   mx-auto">
                  <div className="shadow premier bg-dark br-4 p-3">
                  <div className="position-relative h-100 w-100">
        {muted?
        <>
          
          <div onClick={()=>setmuted(false)} className="d-flex click justify-content-center muted-block h-100 w-100 ">
<div className='muted-speaker'>
<VolumeUp />
<h5>Tap Here to Unmute</h5>
</div>
        </div>
        </>:
        <>
      
        </>}
    <video
    preload={true}
    id="prime-video1"
    className="rounded w-100 position-relative"
     controlsList="play pause volume seeking nodownload foobar" loop
    onClick={()=>setmuted(false)}  controls poster="https://skillsuprise.com/data/images/poster2.png" 
    src="https://skillsuprise.com/data/videos/placement-training.mp4" 
     muted={muted} ></video>
    </div>
                 
                  </div>
                </div>
              </div> */}
            </section>

            {/* <div className="mt-5 pt-5">
              <ReelsCta />
            </div> */}

            <div className="mt-5  ">
              <AlumniSection />
            </div>
          </>
        )}

        {device === "mobile" ? (
          <>
            <div className="mt-0">
              <AlumniSection />
            </div>
          </>
        ) : null}
       
        <div className={device === "mobile" ? "" : "pt-5 pb-5"}>
          <div
            className={device === "mobile" ? "" : "mt-0  pb-0  sm-mb-0 sm-mt-0"}
          >
            <Gallery limit={device==="mobile" ?6:9} />
          </div>
        </div>
        <div className="pb-0 pb-sm-5 mb-md-5 pt-0 pt-md-4 pt-lg-5">
          <CollegesBusinessCta />
        </div>
        <div className="pb-5 mb-5">
          <ContactSection />
        </div>
      </div>
    </>
  );
};

export default HomePage;
