import React, { useEffect, useState } from 'react'
import AlumniSection from '../home/AlumniSection'
import divider from '../../assets/bg/divider.png'
import freeicon from './free.svg'
import projecticon from './project.svg'
import mastericon from './beginner.svg'
import certificateicon from './certificate.svg'
import trainericon from './support.svg'
import placementicon from './placement.svg'
import trainingicon from './placement-training.svg'
import internshipicon from './internship.svg'
import labicon from './lab.svg'
import communityicon from './community.svg'
import rewardsicon from './rewards.svg'
import  videosicon from './videos.svg'
import honourimage from './honour.svg'
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ContactSection from '../../components/ContactSection'
import ModalStyle from '../../data/ModalStyle'
import SetPage from '../../data/SetPage'
import SuccessStories from '../home/SuccessStories'
import UserEngagement from '../home/UserEngagement'
const FeaturesPage = () => {


     useEffect(()=>{
          SetPage("Features")
window.scrollTo(0,0)
     },[])
     const [modal,setmodal] = useState(false)
  return (
    <div className="w-100">
     



     <Modal
  open={modal}
  onClose={()=>setmodal(false)}
>
  <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2 br-5" sx={ModalStyle}>
   <div className="modal-header">
     <h4>Features</h4>
     <CloseIcon onClick={()=>setmodal(false)} className="click "/>
   </div>
   <video autoPlay className="w-100 bg-dark" controls src="https://skillsuprise.com/data/videos/features.mp4"></video>
  </Box>
</Modal>





     <div className="w-100 all-courses-bg bg-dark  pb-5 ">
     <div className="container   pt-5 text-center ">     
     <div className="text-center">
<h3 className="text-light sm-14">Expertised Trainers & Beginner Friendly Trainings</h3>
        
        
</div>
     
     <h1 className="text-light sm-22">Making Learning a Loving Experience</h1>

   

          <div className="mx-auto mt-3">
               <button className="btn btn-warning" onClick={()=>setmodal(true)}><PlayCircleIcon/> Watch Demo</button>
          </div>
     </div>

     </div>
    
<div className="mt-5 pt-3">
<SuccessStories/>
</div>


<div className="w-100 mt-5 mb-5 pt-5">
<div className="text-center">
  
     <h5 className="text-primary">Cracking a Job Made Easy With Our</h5>
     <h2 className="">Job Oriented Practical Trainings</h2>
     <img src={divider}/>
     
</div>

<div className="container">
     <UserEngagement/>
</div>



</div>










<div className="container pt-5 mt-5">
     <div className="text-center">
          <h2>Enjoyable Benifits </h2>
          <img src={divider}/>
     </div>


     <div className="container student-pass-features d-flex flex-wrap ">

     <div className="col-lg-4 p-2">

<div className="shadow p-3">
     
          <img className=" mx-auto" src={videosicon}/>
    
<h4>Pre-Recorded Videos</h4>
<p>Learn anywhere, anytime with beginner friendly practical course content to help you learn easily.</p>
</div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
          
               <img className=" mx-auto" src={freeicon}/>
         
     <h4>Lifetime Access</h4>
<p>Learn throughout your life with unlimited access to our premium courses and resources</p>
     </div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={trainericon}/>
  
     <h4>Dedicated trainer support</h4>
<p>Experts support to help you clear your doubts and make your learning a lovable experience</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow text-center p-3">

               <img className="mx-auto"  src={projecticon}/>
  
     <h4>Real world projects</h4>
<p>Get industry exposure by working with real world projects and gain better knowledge</p>
     </div>

</div>







<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={certificateicon}/>
  
     <h4>Certificate for each training</h4>
<p>Earn professional certificates for each training and add extra value to your resume</p>
     </div>

</div>









<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={placementicon}/>
  
     <h4>Placement Trainings</h4>
<p>Resume building, communication development, mock interviews, reasoning & aptitude training and many more</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow  p-3">
     <img className="mx-auto"  src={internshipicon}/>
  
     <h4>Guaranteed Internship</h4>
<p>Learn the right skills with us and get an internship. Get placed in your favourite dream jobs</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={communityicon}/>
  
     <h4>Students Community</h4>
<p>Learn with 5k+ students who have already joined us and build your professional connections</p>
     </div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={labicon}/>
  
     <h3>Online Practise Lab</h3>
<p>Master every concept with our practise lab that guides you through every step of you learning journey</p>
     </div>

</div>









     </div>
</div>



<AlumniSection/>




<div className="lg-mb-50 sm-mb-0">
<ContactSection/>

</div>

     </div>
  )
}

export default FeaturesPage