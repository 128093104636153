import React from 'react'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useEffect } from 'react';
import CheckLogin from '../../actions/LoginStatus';
import { useState } from 'react';
import RedirectNext from '../../actions/RedirectNext';
import LoginStatus from '../../actions/LoginStatus';
const CourseItem = ({course,slider,index}) => {
const [loggedin,setloggedin] = useState(LoginStatus())
//console.log("login status is "+LoginStatus())

  return (
    <Link  to={"/course/"+course.course_url+"/"+course.mode} className={slider?"col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container fade-in-bottom "+"delay"+index+"s":"col-lg-3 col-md-4 col-sm-6 col-12 course-container col-md-3 col-sm-4 col-12 p-3 fade-in-bottom "+"delay"+index+"s"}>
  
  <div  className="course-content">

 
  <img src={course.course_cover_url} className="course-cover"/>

<div className="info">
<h5 className="title course-title truncate truncate2">{course.course_title}</h5>

<div className="d-flex mt-2 align-items-center">
<Rating
        name="simple-controlled"
        value={course.rating}
        readOnly
     
      />
      <p className="pb-0 mb-0 sm-14">({course.course_students})</p>
</div>
{course.mode==="free"?
<>
<div className="d-flex justify-content-between">

<p className="text-orangered course-price">Free  </p>

</div>
</>:
<>
<div className="d-flex">
<p className="text-orangered course-price mr-15"> ₹ {course.course_price}  </p>
<s className="mb-0 mt-1 text-dark">₹ {course.course_cutoff_price}</s>
</div>

</>}



</div>



<div className="d-none d-sm-flex buttons-holder">
     <button className="btn">{course.seats_left} Seats left</button>
     <vr/>
     <button className="btn">View Details</button>
</div>

  </div>
 
    </Link>
  )
}

export default CourseItem