import React, { useEffect, useRef, useState } from 'react';
import ShortItem from './ShortItem';

const ShortsList = ({ videos, account }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const videoElements = container.querySelectorAll('video');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (entry.isIntersecting) {
            video.play();
            setCurrentIndex(Array.from(videoElements).indexOf(video));
          } else {
            video.pause();
          }
        });
      },
      { threshold: 1.0 }
    );

    videoElements.forEach((video) => observer.observe(video));

    return () => {
      videoElements.forEach((video) => observer.unobserve(video));
    };
  }, [videos]);

  return (
    <div className="reels-list">
      <div className="video-container" ref={containerRef}>
        {videos.map((video, index) => (
          <ShortItem key={index} account={account} video={video} />
        ))}
      </div>
    </div>
  );
};

export default ShortsList;
