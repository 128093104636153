import React from 'react'
import vasu from "../../assets/about/images/vasu.jpg"
import linkedin from '../../assets/about/images/linkedin.png'
import hema from '../../assets/about/images/hemachandra.jpg';
import satya from '../../assets/about/images/satya.jpeg';


const Ourteam = () => {
  return (
    <div>
          <div className='container-fluid col-lg-12 '>
                        <div className='text-center'>
                            <h1><b>Our Awesome technology</b></h1>
                            <h2 className='growth mb-5'><b>Team Members</b></h2>
                        </div>
                        <div className='container-fluid '>
                            <div className=' d-flex align-items-center flex-wrap'>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src={satya} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>SATYA</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Social media Manager</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container text-center'>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                     <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                     <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div> 
                </div>
            </div>
            {/* <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button> 
        </div> */}
                                    </div>
                                </div>
                                <div className='col-lg-3  col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src={hema} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>HEMA CHANDRA</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-3  col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className="position-relative col-11" id='demodiv1'>

                                        <img src={satya} alt="" className='img w-100 col-12' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3 ' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>Satya</h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Socialmediamanager</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className=" position-relative col-11" id='demodiv1'>

                                        <img src={vasu} alt="" className='img w-100' />

                                    <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>VASU </h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex flex-wrap'>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src={vasu} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>VASU </h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>


                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0 '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src={vasu} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14'>VASU </h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src={vasu} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className='p-0 m-0 sm-14 '>VASU </h5>
                                            <h6 className='text-primary p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-center p-0  '>
                                    <div className="overflow-hidden position-relative col-11" id='demodiv1'>

                                        <img src={vasu} alt="" className='img w-100' />

                                        <div className='z-2 text-white position-absolute text-center w-100 py-1 py-md-3 py-lg-3' id='name'>
                                            <h5 className=' p-0 m-0 sm-14 '>VASU </h5>
                                            <h6 className='text-primary  p-0 m-0 sm-12'>Software Developer</h6>
                                        </div>
                                        {/* <div id="hidden_div"
            className='w-100 position-absolute  start-0 py-5 d-flex align-items-center justify-content-center flex-column text-center'>
            <p className='px-3'>Lead the team of passionate designers, developers and the strategists with a thoughts.</p>
            <div className='container '>
                <div className='col-10 d-flex justify-content-center mx-auto py-3'>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={facebook} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={twitter} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={instagram} alt="" />
                        </div>
                    </div>
                    <div className='col-3 '>
                        <div className='col-8'>
                            <img className='w-100' src={linkedin} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <button className='btn btn-sm bg-white px-3 py-2'>Read More +</button>
        </div> */}
                                    </div>
                                </div>

                            </div>


                            {/* <div className='text-center mx-auto col-lg-4 col-md-8 col-sm-12 col-12   mt-2  '>
                                <button className='btn btn-primary p-2 w-50 col-12'>View all</button>
                            </div> */}

                        </div>

                    </div>

    </div>
  )
}

export default Ourteam

