import React from 'react'
import studentsgroup from '../../assets/images/reviews/students-group.png'
import  { useState } from 'react'
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Close from '@mui/icons-material/Close'
import ModalStyle from '../../data/ModalStyle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DetectDevice from '../../data/DetectDevice';
const Reviews1 = () => {


    const [demo,setdemo] = useState(false)

const device = DetectDevice()


  return (
    <div className="reviews1 container sm-pt-0 container-fluid pt-5 pb-5">
<Modal
  open={demo}
  onClose={()=>setdemo(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Students Reviews</h4>
     <Close className="text-danger" type="button" onClick={()=>setdemo(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100" autoPlay src="https://skillsuprise.com/data/videos/student-review.mp4"></video>
    </Typography>
  </Box>
</Modal>
<div className=" d-flex flex-wrap justify-content-between">


<div className="col-lg-5">
<h1 className="font-weight-bold">Students <b className="secondary-text">
  Love</b>, <b className="text-danger">Trust </b> 
  & {device==="mobile"?<><br/></>:null} <b className="primary-text">Respect Us</b></h1>
<p>99% of the students say that our trainers 
     care every student like their brother/sister 
     to help them acheive their goals and fullfill 
     their dreams by being a better guide throughout 
     the training program and after the course completion
      as well to
      help them get into better opportunities.</p>

      <p className="mt-3 primary-text">See what our students say about us.</p>

<div className="d-flex flex-wrap align-items-center h-50px mt-3">
  

<img src={studentsgroup} className="mr-10 h-100"/>
<button onClick={()=>setdemo(true)} className="btn rounded-pill btn-lg btn-sm h-fit btn-warning "><PlayCircleIcon/> Watch Video</button>

</div>
</div>

<div className="col-lg-5 sm-mt-50 ">

<img onClick={()=>setdemo(true)}  src="https://skillsuprise.com/resources/images/testimonial-group.png" className="w-100 click"/>

</div>

</div>




    </div>
  )
}

export default Reviews1