import CloseIcon from '@mui/icons-material/Close';
import React from 'react'
import { Form, Modal, Toast } from 'react-bootstrap'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../../data/ENDPOINT'
import DuoIcon from '@mui/icons-material/Duo';
import VideoIcon from '@mui/icons-material/Videocam';
import { Chat, PhoneAndroid, PhoneCallback, VideoCall, WhatsApp } from '@material-ui/icons'
import { Chip, CircularProgress } from '@material-ui/core'
import { useEffect } from 'react'
import { PlayCircle } from '@mui/icons-material'
import { Alert } from '@mui/material'
import StarIcon from '@mui/icons-material/Star';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@material-ui/core'
import { Rating } from '@mui/material'
import AddIcCallIcon from '@mui/icons-material/AddIcCall';


const StudentSupport = ({ props, account }) => {
  const { user } = useContext(AuthContext)

  const params = useParams();


  const courseurl = params.course_url;
  const batchid = params.batch_id;

  const [chat, setchat] = useState(false);
  const [subject, setsubject] = useState(null);
  const [message, setmessage] = useState(null);
  const [messageerror, setmessageerror] = useState(null);
  const [subjecterror, setsubjecterror] = useState(null);
  const [category, setcategory] = useState(null);
  const [callback, setcallback] = useState(false)
  const [discussions, setdiscussions] = useState(null)










  const [loading, setloading] = useState(false)

  const sendRequest = async (cat) => {
    setloading(true)
    setcategory(cat);
    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);


    formData.append('course', courseurl);
    formData.append('batch_id', batchid);
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('category', cat);


    const res = await axios.post(ENDPOINT + "whatsapp-chat.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
      setloading(false)
      //  //  //  //console.log("doubt support"+JSON.stringify(res.data))
      if (res.data.status === "success") {
        setchat(false);
        setcallback(false)
        getDoubts();
        if (cat === "chat") {
          window.open("https://wa.me/919490174876?text=*Discussion%20ID*:%20%20"
            + res.data.discussion_id + "%20%20*Course%20ID*:" + courseurl + "%20%20*Batch%20ID*:" + batchid);
        }
      }
      else {

      }
    }
  }

  // console.log(res.data.data)



  const [statistics, setstatistics] = useState(null)

  const [read, setread] = useState(false)
  const [watch, setwatch] = useState(false)





  const getDoubts = async () => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);


    formData.append('course', courseurl);
    formData.append('batch_id', batchid);


    const res = await axios.post(ENDPOINT + "get-discussions.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      if (res.data.status === "success") {
        setdiscussions(res.data.doubts)

      }
      else {

      }
    }
  }



  useEffect(() => {
    window.scrollTo(0, 0)
    //  //console.log("getting doubts")
    getDoubts()
  }, [account])


  const [query, setquery] = useState(null)

  const [feedback, setfeedback] = useState(false)
  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null)
  const [doubtid, setdoubtid] = useState(null)
  const [toastmessage, settoastmessage] = useState(null)

  const [studentfeedback, setstudentfeedback] = useState(null);
  const [rating, setrating] = useState(3)
  const SubmitFeedback = async () => {

    const formData = new FormData();
    formData.append('session', user.SessionId);
    formData.append('doubt_id', doubtid);
    formData.append('rating', rating);
    formData.append('feedback', studentfeedback);

    const res = await axios.post(ENDPOINT + "submit-doubt-feedback.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

    }

    if (res.data.status === "success") {
      setfeedback(false)
      settoasttitle("Feedback submitted");
      settoastmessage("Thank you for submitting your feedback")
      setstudentfeedback(null)
      setrating(3)
      settoast(true)
      getDoubts()

    }
    else {
      settoasttitle("Something went wrong !");
      settoastmessage("Please try after sometime")
      settoast(true)
    }
  }

  const labels = {
    0.5: 'Useless',
    1: 'Bad',
    1.5: 'Poor',
    2: 'Ok',
    2.5: 'Below Average',
    3: 'Average',
    3.5: 'Good',
    4: 'Better',
    4.5: 'Best',
    5: 'Excellent',
  };

  const [hover, setHover] = React.useState(-1);

  const [note, setnote] = useState(null)
  const [duobtsubject, setdoubtsubject] = useState(null)
  const [doubtqsn, setdoubtqsn] = useState(null)
  const ReadResolution = ({ index }) => {
    setread(true)
    setnote(discussions[index].note || null)
    setdoubtsubject(discussions[index].subject || null)
    setdoubtqsn(discussions[index].message || null)

  }
  const [responsevideo, setresponsevideo] = useState(null)

  const WatchResolution = ({ index }) => {

    setnote(discussions[index].note || null)
    setdoubtsubject(discussions[index].subject || null)
    setdoubtqsn(discussions[index].message || null)
    setresponsevideo(discussions[index].recording_link)
    setwatch(true)
  }

  return (
    <>


      <div className="w-100 doubt-sup bg-white">
        <div className="toast-container">


          <Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
            <Toast.Header>

              <strong className="me-auto">{toasttitle}</strong>

            </Toast.Header>
            <Toast.Body>{toastmessage}</Toast.Body>
          </Toast>
        </div>
        <Modal
          {...props}

          aria-labelledby="contained-modal-title-vcenter"
          centered

          show={callback}
          onHide={() => { setcallback(false) }}
        >

          <Modal.Body>

            <div className="container-fluid">
              <div className="flex space-between mb-4 w-100">
                <h5>Request</h5>
                <CloseIcon type="button" onClick={() => { setcallback(false) }} className="text-danger" />
              </div>


              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Subject </Form.Label>
                <Form.Control type="text" required value={subject ? subject : null} onChange={(e) => { setsubject(e.target.value) }} placeholder="Example: I have doubts on a topic" />
                {subjecterror ?
                  <Form.Text className="text-danger">
                    {subjecterror}
                  </Form.Text>
                  : null}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Message / Doubt</Form.Label>
                <Form.Control type="text" required value={message ? message : null} onChange={(e) => { setmessage(e.target.value) }} placeholder="Example: what is AI ?" />
                {messageerror ?
                  <Form.Text className="text-danger">
                    {messageerror}
                  </Form.Text>
                  : null}
              </Form.Group>

              <div className=" mt-4">
                <button className="btn btn-primary w-100" type="submit" disabled={loading} onClick={() => { sendRequest(category) }}> {loading ? "Confirming..." : "Confirm Request"}</button>

              </div>





            </div>





          </Modal.Body>

        </Modal>




        <Modal
          {...props}

          aria-labelledby="contained-modal-title-vcenter"
          centered

          show={feedback}
          onHide={() => { setfeedback(false) }}
        >

          <Modal.Body>

            <div className="container-fluid">
              <div className="flex space-between mb-4 w-100">
                <h5>Support Feedback</h5>
                <CloseIcon type="button" onClick={() => { setfeedback(false) }} className="text-danger" />
              </div>


              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >

                <Rating
                  name="hover-feedback"
                  value={rating}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    setrating(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                {rating !== null && (
                  <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
                )}
              </Box>


              <Form.Group className="mb-3" >
                <Form.Label>Feedback</Form.Label>
                <Form.Control type="text" required value={studentfeedback ? studentfeedback : null} onChange={(e) => { setstudentfeedback(e.target.value) }} placeholder="Feedback" />
                {messageerror ?
                  <Form.Text className="text-danger">
                    {messageerror}
                  </Form.Text>
                  : null}
              </Form.Group>

              <div className=" mt-4">
                <button className="btn btn-primary w-100" type="submit" onClick={() => { SubmitFeedback() }}> Submit Feedback</button>

              </div>





            </div>





          </Modal.Body>

        </Modal>


        <Modal
          {...props}

          animation={false}
          centered
          size="sm"
          className="bg-transparent"
          show={chat}
          onHide={() => { setchat(false) }}
        >

          <Modal.Body className="bg-transparent">
            <div className="text-center">
              <p>Connecting...</p>
              <CircularProgress color="success" />
            </div>

          </Modal.Body>

        </Modal>




        <Modal
          {...props}

          animation={false}
          centered
          size="lg"
          className="bg-transparent"
          show={read}
          onHide={() => { setread(false) }}
        >

          <Modal.Body className="bg-transparent">
            <div className="flex space-between">
              <h4 className="text-primary">Summary</h4>
              <CloseIcon className="text-danger" type="button" onClick={() => setread(false)} />
            </div>
            <hr />
            <div className="t">

              <p><b>Subject : </b>{duobtsubject}</p>
              <p><b>Question : </b>{doubtqsn}</p>
              {note ?
                <>
                  <b>Discussion Summary : </b>
                  <p>{note}</p>
                </> :
                <>
                  <p className="text-secondary">No discussion summary for this support request</p>
                </>}
            </div>

          </Modal.Body>

        </Modal>









        <Modal
          {...props}

          animation={false}
          centered
          size="lg"
          className="bg-transparent"
          show={watch}
          onHide={() => { setwatch(false) }}
        >

          <Modal.Body className="bg-transparent">
            <div className="t">
              <div className="flex-wrap p-2">
                <h3>Trainer Response Summary</h3>
              </div>
              <hr />
              <p><b>Subject : </b>{duobtsubject}</p>
              <p><b>Question : </b>{doubtqsn}</p>

              <video className="w-100" src={responsevideo}></video>


            </div>

          </Modal.Body>

        </Modal>
















        <div className="w-100 position-relative pt-5 pb-5 doubt-support">


          <div className="text-center mb-4 p-2 p-md-0 ">
            <h2 className="text-light">24/7 Doubt Support</h2>


            <p className="sm-22 light-grey">We are ready to help you clear your doubts the right way at the right time</p>


          </div>
          <div className="flex-wrap container d-flex align-items-center justify-content-start justify-content-md-evenly">

            <div className="col-lg-4 col-6 text-center p-1 p-md-3">


              <div className="shadow bg-white br-5">
                <img src="https://skillsuprise.com/resources/images/chatsupport.png" className='w-100 rounded rounded-top rounded-bottom-0'></img>
                <div className="p-1 p-md-3">
                  <h4 className="sm-16">Discuss over a chat</h4>
                  <p className='d-none d-md-block'>Start a WhatsApp chat to discuss doubts with your trainer</p>
                  <button className="btn my-1 mt-md-3 rounded-pill btn-primary sm-12 " disabled={loading} onClick={() => { setcategory("callback"); sendRequest("chat"); }} ><WhatsApp />  {loading ? "Starting chat..." : "Start a Chat"}</button>

                </div>

              </div>

            </div>


            <div className="col-lg-4 col-6 p-1 p-md-3 text-center ">
              <div className="shadow   bg-white br-5">
                <img src="https://skillsuprise.com/resources/images/callback.png" className='w-100 rounded rounded-top rounded-bottom-0'></img>
                <div className="p-1 p-md-3">
                  <h4 className="sm-16">Receive a Callback</h4>
                  <p className='d-none d-md-block'>Request a phone call to discuss doubts with your trainer.</p>


                  <button disabled={loading} className="btn  my-1 mt-md-3 sm-12 rounded-pill btn-primary" onClick={() => { setcallback(true); setcategory("callback") }}><PhoneAndroid /> {loading ? "Requesting..." : "Request"}</button>

                </div>
              </div>
            </div>



            <div className="col-lg-4 col-6  text-center p-1 p-md-3">
              <div className="shadow   bg-white br-5">
                <img src="https://skillsuprise.com/resources/images/zoomcall.png" className='w-100 rounded rounded-top rounded-bottom-0'></img>

                <div className="p-1 p-md-3">
                  <h4 className="sm-16">Request a Zoom Call</h4>
                  <p className='d-none d-md-block'>Have a complex doubt/problem, request a zoom call session.</p>


                  <button className="btn my-1 mt-md-3 sm-12  btn-primary rounded-pill" disabled={loading} onClick={() => { setcallback(true); setcategory("zoom") }}><VideoIcon /> Schedule a Meet</button>

                </div>

              </div>
            </div>



          </div>






        </div>


        <div className="container mt-5  sm-p-15">
          <div className="">
            <div className="flex align-items-center space-between">

              <h3 className="sm-22">Recent Discussions </h3>



            </div>


            <hr />
            {statistics ?
              <>
                {statistics.resolved > 0 ?

                  <>
                    <>
                      <p>Average Response Time : {statistics.avg_response_time >= 60 ?
                        <>
                          {(statistics.avg_response_time / 60)} Hours
                        </>
                        :
                        <>
                          {(statistics.avg_response_time)} Minutes
                        </>
                      }</p>








                      <Alert variant="outlined" severity="info">
                        On an average,<b> we took {statistics ? statistics.avg_response_time : null} minutes to resolve most of your doubts</b>. Please submit a feedback of all your support requests to help us serve you better.
                      </Alert>

                    </>
                  </>
                  : null
                }
              </>
              : null
            }


          </div>


          <div className="pb-5">
            {discussions ?

              <>
                {discussions.map((discussion, index) => (

                  <>
                    <>
                      <div className="container-fluid border rounded mt-3 mb-3 p-3">
                        <div className="flex-wrap align-items-center">

                          <div className='col-lg-8'>


                            <div className="col-lg-12">
                              <div className="table-responsive">
                                <table className="table table-borderless text-center mb-0">

                                  <thead className="table-light">
                                    <tr>
                                      <th scope="col">Support ID</th>
                                      <th scope="col">Date</th>
                                      <th scope='col'>Request</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>

                                  <>
                                    <tbody className='overflow text-center'>

                                      <>
                                        <tr>
                                          <td>
                                            <div className="d-flex align-items-center">

                                              <div className="ms-2 flex-grow-1">
                                                <div>
                                                  <h6>{discussion.id}</h6>
                                                </div>

                                              </div>
                                            </div>
                                          </td>
                                          <td>{<div className='p-0 m-0'>
                                            <p>{dateFormat(discussion.create_datetime, "dS mm yy hh:mm tt")}</p>
                                          </div>}</td>

                                          <div className='ms-5'>

                                            <h6 className='m-0 p-0'>{discussion.category === "chat" ?

                                              <>
                                                <div className="flex align-items-center">
                                                  <WhatsApp className="text-success" />

                                                </div>
                                              </>
                                              : <>

                                                {discussion.category === "zoom" ?

                                                  <>
                                                    <div className="flex align-items-center">
                                                      <DuoIcon className="text-primary" />

                                                    </div>
                                                  </>
                                                  : <>
                                                    <div className="flex align-items-center">
                                                      <AddIcCallIcon className="text-primary" />

                                                    </div>
                                                  </>
                                                }
                                              </>
                                            }</h6>


                                          </div>
                                         
                                          <td>
                                            <div className="col-lg-5 col-12 col-md-1 col-sm-12 ms-5">
                                              {discussion.status === "resolved" ?

                                                <>

<div className='bg-success w-100 text-light'><b>Success</b></div>
                                                </>
                                                :
                                                discussion.status === "unresolved" ?
                                                  <>
                                                   <div className='bg-warning w-100 col-12  text-light'><b>Pending</b></div>  

                                                  </>
                                                  :
                                                  <>
                                                    <div className='bg-danger w-100 col-12 text-light'><b>Rejected</b></div>
                                                  </>}
                                            </div>
                                          </td>

                                        </tr>
                                      </>

                                    </tbody>

                                  </>
                                </table>
                              </div>

                            </div>

                          </div>


                         



                          {/* <div className="col-lg-1 col-12 col-md-1 col-sm-12">
                            {discussion.status === "resolved" ?

                              <>

                                <Chip size="small" className="bg-success text-light" label="Resolved" />
                              </>
                              :
                              discussion.status === "unresolved" ?
                                <>
                                  <Chip size="small" className="bg-warning text-light" label="Pending" />

                                </>
                                :
                                <>
                                  <Chip size="small" className="bg-danger text-light" label="Rejected" />
                                </>}
                          </div> */}
                          {/*status ends*/}

                          <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <h6>{discussion.subject !== "null" ? discussion.subject : null}</h6>
                            <p className="mb-0 text-secondary">{discussion.message !== "null" ? discussion.message : null}</p>
                          </div>
                          {/*subject ends*/}


                          <div className="col-lg-2 col-md-2 col-sm-12 flex align-items-center">
                            {discussion.status === "resolved" ?
                              <>
                                <div type="button" className="text-primary mr-3">
                                  <Chat type="button" onClick={() => ReadResolution({ index: index })} />
                                </div>
                                <div type="button" className="text-primary">
                                  <PlayCircle type="button" onClick={() => setwatch(true)} />
                                </div>
                                {discussion.rating ?
                                  <>

                                  </>
                                  :
                                  <>
                                    <div type="button" onClick={() => { setdoubtid(discussion.id); setfeedback(true) }} className="text-primary ml-3">
                                      Submit Feedback
                                    </div>
                                  </>
                                }

                              </> :
                              <></>}
                          </div>

                        </div>


                      </div>
                    </>
                  </>

                ))}
              </>
              :

              <div className="container-fluid mb-5 text-center mt-3 text-secondary">

                <h4>No discussions were made</h4>

              </div>

            }
          </div>
        </div>

      </div >

    </>
  )
}

export default StudentSupport
