import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetSacVideos = async ({payload})=>{

const data = new FormData()
if(payload.limit){
     data.append("limit",payload.limit)
}
if(payload.query){
     data.append("query",payload.query)
}
const res = await 
axios.post(ENDPOINT+`get-sac-videos.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetSacVideos

