import React, { useEffect, useState } from 'react'

import divider from '../assets/bg/divider.png'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import GetContacts from '../actions/support/GetContacts';
import { Avatar, CircularProgress, TextareaAutosize } from '@mui/material';
import Contact from '../actions/support/Contact';
import {Toast } from 'react-bootstrap'
import contactcover from '../assets/images/contact-cover.svg'
import DetectDevice from '../data/DetectDevice';
import SimpleNotificationModal from './modals/SimpleNotificationModal';
const ContactSection = () => {



  const NewContacts =  async()=>{

const res = await GetContacts({
  payload:{
    category:"default"
  }
})
if(res){
  if(res.status==="success"){
    setcontacts(res.data)

  }

   //  //console.log("contacts are "+JSON.stringify(res))
}
  }

  const [ToastTitle,setToastTitle] = useState(null);
  const [ToastMessage,setToastMessage] = useState(null)
  const [toast,settoast] = useState(false)


  const fname = document.getElementById("fname")
  const lname = document.getElementById("lname")
  const email = document.getElementById("email")
  const phone = document.getElementById("phone")
  const [subject,setsubject] = useState("contact")   
  const [message ,setMessage] =  useState(null)
    const [contact,setcontact] = useState(false)
  const [loading,setloading] = useState(false)
  const NewContact = async (e) => {
    e.preventDefault();
    setloading(true);
  
    
    if (!fname.value || fname.value.trim() === '' ||
    !lname.value || lname.value.trim() === '' ||
    !email.value || email.value.trim() === '' ||
    !phone.value || phone.value.trim() === '' ||
    !subject || subject.trim() === '' ||
    !message || message.trim() === '') {
  
  setloading(false);
  setToastTitle("All fields are required!");
  setToastMessage("Please fill in all the fields before submitting.");
  settoast(true);
  return;
}
  
    try {
      const res = await Contact({
        payload: {
          username: `${fname.value} ${lname.value}`,
          phone: phone.value,
          email: email.value,
          subject: subject,
          message: message
        }
      });
  
      setloading(false);
  
      if (res.status === "success") {
        setToastTitle("Message sent successfully!");
        setToastMessage("Our support team will reach you within the next 24 hours.");
      } else {
        setToastTitle("Something went wrong!");
        setToastMessage("Please try again after some time.");
      }
      settoast(true);
    } catch (error) {
      setloading(false);
      setToastTitle("Network error!");
      setToastMessage("Please check your connection and try again.");
      settoast(true);
    }
  };
  

const [contacts,setcontacts] = useState(null)


  useEffect(()=>{
NewContacts()
  },[])
const device = DetectDevice()
 

  const handleClose = ()=>{
  settoast(false)
  }
  console.log(message);
  return (
    <div className="contact-section w-100 ">
     

     <div className="toast-container">
  <SimpleNotificationModal 
  visibility={toast}
  handleClose={handleClose}
 
  data={{title:ToastTitle,description:ToastMessage,button_text:"Got It"}}


  />
     <Toast show={false} onClose={()=>settoast(false)} delay={6000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
             <strong className="me-auto">{ToastTitle}</strong>
            
           </Toast.Header>
           <Toast.Body className='text-center'>{ToastMessage}</Toast.Body>
         </Toast>


     </div>
<div className="col-lg-9 sm-p-0 col-md-10 col-sm-12 col-12 mx-auto br-5 sm-br-0  p-0 md-p-3">

<div className="text-center contact-page-light mb-5 d-none d-sm-block">
<h1>Contact Us </h1>
<p>Write us a message or give us a missed call</p>

<div className="divider">
      <img src={divider}/>
    </div>


</div>

<div className="flex-wrap  justify-content-around  d-flex">

<div className="col-lg-6 col-12 contact-cover  pt-5 md-pt-3 shadow-none shadow-md position-relative col-md-6 col-sm-12  br-5 sm-br-0 text-white p-3 ">
<div className="">
<h3>Contact Information</h3>
<p  className="text-light d-none d-sm-block">Fill up the form and our team will reach you in 24 hours or call us for immediate support.</p>
   

</div>
  
<div className="mt-2">
  <hr/>
  {contacts?
  <>
  {contacts.map((contact,index)=>(
    <>
<div key={index} className={"mt-3 mb-3 fade-in-bottom delay"+(index+1)+"s"}>
<div className="d-flex">
  <img className="mr-15 contact-person" src={contact.profile_pic}/>
  <div className="text-light">
    <h5 className="text-light pb-0">{contact.first_name+" "+contact.last_name}</h5>
    <p  className="light-grey">{contact.designation}</p>

    <a href={"tel:91"+contact.phone} target="_blank"  className="light-grey">{contact.phone}</a>
  </div>
<div>
  
</div>
  <div>


  </div>
</div>
</div>
    </>
  ))}
  </>:null}
</div>
   
     </div>
     <div className="col-lg-5 animate sm-mt-50 col-md-6 col-sm-12 col-12 sm-shadow-none shadow br-5 text-field p-3">

<form onSubmit={(e)=>NewContact(e)} className="w-100">
<div className="d-block d-sm-none text-align-left">
<h3>Send us a message</h3>
<p  >Fill up the form and our team will reach you in 24 hours or call us for immediate support.</p>
   

</div>
<div className="d-flex sm-mt-20 justify-content-between">
   
   
   <div className="col p-1" >
   <TextField id="fname"  autoComplete={false} type="text"  required label={'First Name'}  />

    </div>   
    <div className="col p-1" >
    <TextField id="lname" autoComplete={false} type="text"  required label={'Last Name'}  />
     
    </div>
    
   
     </div>
     <div className="d-flex mt-3 justify-content-between">
   
   
   <div className="p-1 col" >
   <TextField id="email" autoComplete={false} type="email"    required label={'Email'}  />
   

   </div>

   <div className="col p-1" >

   <TextField id="phone"  autoComplete={false} type="number"  required label={'Phone'}  />
     
   </div>
     
     </div>
     
  <div className="mt-3">

<div className="p-1">
<TextareaAutosize id="message" 
onChange={(e)=>setMessage(e.target.value)}
  minRows={3} 
  type="text"
  placeholder="Message..."
 className="h-100 w-100 p-2"
/>
</div>

   </div>
  <div className="mt-3">
{loading?
<>
<button disabled type="submit" className="btn btn-primary w-100"><CircularProgress size={18} className="mr-5 text-white"/> Sending...</button>

</>:
<>
<button  type="submit" className="btn btn-primary w-100">Send Message</button>

</>}

     </div>

</form>

     </div>

</div>
    
</div>

    </div>
  )
}

export default ContactSection