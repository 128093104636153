import React, { useContext, useEffect, useState } from 'react'
import GetSingleShort from '../../actions/GetSingleShort'
import { AuthContext } from '../../context/AuthContext'
import { Link, useParams } from 'react-router-dom'
import { Avatar, Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import Likeicon from '@mui/icons-material/ThumbUp';
import Dislikeicon from '@mui/icons-material/ThumbDown';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SuggestionItem from './SuggestionItem'
import SetPage from '../../data/SetPage'
import dateFormat from 'dateformat'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
     EmailIcon,
     EmailShareButton,
   
     LinkedinIcon,
     LinkedinShareButton,
   
    
     TelegramIcon,
   
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
   
     WhatsappIcon,
   
     WhatsappShareButton,
   
   } from "react-share";
import ModalStyle from '../../data/ModalStyle'
import ENDPOINT from '../../data/ENDPOINT'
import axios from 'axios'
import DetectDevice from '../../data/DetectDevice'
import Defaults from '../../data/Defaults'
import ShortSuggestionItem from './ShortSuggestionItem'
const WatchShort = () => {
     const params = useParams()
     const video_id = params.video_id
     const [access,setaccess] = useState(false)
     const {user} = useContext(AuthContext)
const [suggestions,setsuggestions] = useState(null)
     const [video,setvideo] = useState(null)
     const NewSingleTutorial = async ()=>{

          const res = await GetSingleShort(

user?{payload:{
     user:user.SessionId,
     video_id:video_id}}:{payload:{
          video_id:video_id}}
          )
          if(res.status==="success"){
               setvideo(res.current_video)
               setaccess(res.access)
               setsuggestions(res.suggestions)
                //  //console.log("watch video "+JSON.stringify(res))
          }
     }
     const [currenturl,setcurrenturl] = useState(null)
     useEffect(()=>{
          setcurrenturl(window.location.href)
          window.scrollTo(0,0)
          SetPage("Watch Shorts")
NewSingleTutorial()
     },[])


     const [share,setshare] = useState(false)
const device = DetectDevice()

 useEffect(()=>{


if(device==="mobile"){

}
else{

     const suggestionscontent = document.getElementById("suggestions-content")
     if(suggestionscontent){
suggestionscontent.style.overflowY="scroll";
const defaults = Defaults()
suggestionscontent.style.height = defaults.ViewHeight+"px"
     }
}

},[video_id,suggestions])



const [favourite,setfavourite] = useState(false)
const [liked,setliked] = useState(false)

const ToggleLike = async ()=>{
        
setliked(!liked)
     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId);
     
       formData.append('video_id',video_id);
     
     
     const res = await axios.post(ENDPOINT+"short-like.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
       //  //console.log("favouri "+JSON.stringify(res))
      if(res.data.status==="success"){
        
       
  setliked(res.data.liked)
       
      
      }
      else{
     
      }
     }
     }
     
     


     const AddFavourite = async ()=>{
        
          setfavourite(true)
               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
               
                 formData.append('video_id',video_id);
               
               
               const res = await axios.post(ENDPOINT+"short-add-favourite.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){
                 //  //console.log("favouri "+JSON.stringify(res))
                if(res.data.status==="success"){
                  
                
            setfavourite(res.data.liked)
                  
                
                }
                else{
               
                }
               }
               }
               
const [currentduration,setcurrentduration] = useState(0)

               const durationChange = (e)=>{



 
                    const video = document.getElementById("player");
                 
                    video.ontimeupdate = function() {
                        setcurrentduration(Math.round(video.currentTime))
        //  //console.log("rounded div"+Math.round(video.currentTime))
                        if(Math.round(video.currentTime)%10===0){
                          //  //console.log(Math.round(video.currentTime))
                     
                 
                        }
                        };
                   
                
                
                
                
                  
                
                  
                        
                
                }
                



  return (
    <div className="pb-5 mb-5">










      <Modal
        open={share}
        onClose={()=>setshare(false)}
     
      >
        <Box sx={ModalStyle} className="p-3 col-lg-3 col-md-3 col-sm-4 col-11">
          <div className="modal-header">
          <h4 className="mb-0">Share this video</h4>
          <CloseIcon onClick={()=>setshare(false)} className="click"/>
          </div>
    
<div className="flex mt-4 mb-2">
<WhatsappShareButton size={30}   round={true} url={currenturl}><WhatsappIcon size={30}  type="button"  round={true} url={currenturl} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={currenturl}><LinkedinIcon size={30}  type="button"  round={true} url={currenturl} className="mr-2"></LinkedinIcon></LinkedinShareButton>
<TelegramShareButton size={30}   round={true} url={currenturl}><TelegramIcon size={30}  type="button"  round={true} url={currenturl} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={currenturl}><TwitterIcon size={30}  type="button"  round={true} url={currenturl} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={currenturl}><EmailIcon size={30}  type="button"  round={true} url={currenturl} className="mr-2"/></EmailShareButton>


</div>
        </Box>
      </Modal>









































     {video?

<>
<div className="container-fluid pt-3 d-flex justify-content-around flex-wrap">

<div className="col-lg-7 col-md-12 col-sm-12 col-12 video-content">
{access?
<>
<video id="player"  onDurationChange={durationChange} poster={video.poster} src={video.url} controls autoPlay className="w-100"></video>

</>:
<>
<video id="player" poster="https://skillsuprise.com/data/images/video-access-denied.png"   controls autoPlay className="w-100"></video>

<div className="bg-dark w-100">

</div>
</>}

<div className="video-info">
<h5 className="pb-0">{video.title}</h5>
<div className="d-flex justify-content-between align-items-center mt-2">
<p className="sm-12">{dateFormat(video.upload_date,"dS mmmm yyyy HH:MM tt")}</p>

<div className="d-flex">
     <div className="d-flex align-items-center mr-15">
     {liked?
     <>
     <Likeicon onClick={()=>ToggleLike()} className="text-primary click"/>
     </>:
     <>
     <Likeicon className="click" onClick={()=>ToggleLike()} />
     </>}
     </div>

     <div onClick={()=>setshare(true)} className="d-flex click align-items-center mr-15">
     <ShareIcon className="click" />
     <p className="mb-0 pb-0">Share</p>
     </div>


     
</div>
</div>
            
<hr/>
     <div className="d-flex justify-content-between">
         <div className="d-flex ">
         <Avatar src={video.profile_pic}/>
          <div className="ml-15">
               <h5 className="pb-0">{video.first_name+" "+video.last_name}</h5>
               <p>{video.username}</p>

          </div>
         </div>
         {video.notes?
         <>
            <Link to={video.notes} target="_blank" rel="noreferrer" className="btn btn-primary btn-sm"><FileDownloadIcon/> Download Notes</Link>
   
         </>:null}
        </div>









     {video.description?
     <>
     <div className="pt-4 pb-5">
          <h5>Description : </h5>
{video.description}
</div>
     </>:
     <>
     
     </>}








</div>
</div>

<div id="suggestions-content" className="col-lg-4">


{device==="mobile"?
<>
<hr></hr>
</>:
<>
<h5>Suggestions</h5>

</>}

<div className="d-flex scroll-navigator">
     <Link to="/favourite-videos" className="">
     <Chip size="small" label="Favourite"/>
     </Link>
<Link to="/watch-later">
<Chip size="small" label="Watch later"/>
</Link>

<Link to="/watch-history">
<Chip size="small" label="Watch history"/>
</Link>


</div>
<hr className="mt-2"/>
{suggestions&&video?
<>
{suggestions.map((suggestion)=>(

     <>
<ShortSuggestionItem access={access} suggestion={suggestion}/>
</>

))}
</>:null}
</div>

     </div>
</>:null}


    </div>
  )
}

export default WatchShort