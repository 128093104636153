import React, { useContext } from 'react'
import { useEffect,useState } from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'
import rewardduplicate from '../../assets/reward-duplicate.png'
import ENDPOINT from '../../data/ENDPOINT';
import { AuthContext } from '../../context/AuthContext';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Lock from '@mui/icons-material/Lock';
import Localbase from 'localbase'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import DoneIcon from '@mui/icons-material/Done';
import RedeemIcon from '@mui/icons-material/Redeem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const  ScratchCards = () => {
const {user} = useContext(AuthContext)
   
     const [badges,setbadges] = useState(null);
    

let db = new Localbase('db')

     useEffect(()=>{
        
     
       db.collection('paths').doc('personal_rewards').get().then(rewards=>{
            setbadges(rewards.personal_rewards)
       }).catch(error=>{
            
       })
     getbadges();
            
     }
     
     ,[])
     

     const  getbadges = async ()=>{
          const result = await axios.post(`${ENDPOINT}get-scratch-cards.php?session=${user.SessionId}`)
           if(result.data!==null){
              setbadges(result.data);

              db.collection('paths').add({
                   personal_rewards:result.data
              },'personal_rewards')
             
          }
                            
                
              
                
                }    ;
     
     const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        };

const [modal,setmodal] = useState(false)


     return (
          <Styled> 
               <>
               <Helmet>‍
        <title>Scratch Cards - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet> 
      
      
            <div className='container-fluid p-5 col-lg-12'>

                <div className='bg text p-3'>
                    <div className='d-flex flex-wrap justify-content-evenly '>

                        <div className='card col-xl-3 col-lg-6 col-md-6 col-sm-8 col-10 bg-white rounded-4 border m-3 p-3 '>
                            <h2 className='text-center mt-3'>ONLINE COURSE</h2>
                            <p className='text-center mt'>Everything you need to create your website</p>


                            <div className='mt-3'>
                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>100</b></p>
                                    <p className='ms-1'>Websites</p>
                                </div>

                                <div className='d-flex '>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>-25 000</b></p>
                                    <p className='ms-1'>Visits Monthly</p>
                                </div>

                                <div className='d-flex '>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>100 GB</b></p>
                                    <p className='ms-1'>NVNs Storage</p>
                                </div>

                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>400 000</b></p>
                                    <p className='ms-1'>Files and Directories (Inodes)</p>
                                </div>
                            </div>

                            <div className='bg1 mt-3 ps-3'>
                                <div className='d-flex mb-2'>
                                    <p className='m-0'>They pay</p>
                                    <div className='border border-dark rounded rounded-3 ms-2 '>-20%</div>
                                </div>
                                <div className='d-flex'>
                                    <s>₹2,628.00</s>
                                    <p className='ms-1'><b>₹2,102.40</b></p>
                                </div>
                            </div>

                            <div className='d-flex text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                <RedeemIcon color="success" />
                                <p className='ms-2'>Your commission</p>
                                <h5 className='ms-5'>₹420.48</h5>
                            </div>

                            <div className='d-flex mt-4'>
                                <button className='border rounded bg-white p-2 copyb'>
                                    <ContentCopyIcon />
                                </button>
                                <button className='border ms-2 w-100 text-center bg-white rounded refer pt-2 copyb'>
                                    Refer to Friend</button>
                            </div>
                        </div>





                        <div className='card col-xl-3 col-lg-6 col-md-6 col-sm-8 col-10 bg-white rounded-4 border bd m-3 p-3'>
                           <div className='bg-primary text-white rounded-4 w-50 text-center mt28 mx-auto p-1'>MOST POPULAR</div>
                           
                            <h2 className='text-center mt-3'>SPECIALIZATION COURSE</h2>
                            <p className='text-center mt'>Everything you need to create your website</p>

                            <div className='mt-3'>
                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>100</b></p>
                                    <p className='ms-1'>Websites</p>
                                </div>

                                <div className='d-flex '>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>-100 000</b></p>
                                    <p className='ms-1'>Visits Monthly</p>
                                </div>

                                <div className='d-flex '>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>200 GB</b></p>
                                    <p className='ms-1'>NVNs Storage</p>
                                </div>

                                <div className='d-flex '>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>600 000</b></p>
                                    <p className='ms-1'>Files and Directories (Inodes)</p>
                                </div>
                            </div>

                            <div className='bg1 mt-3 ps-3'>
                                <div className='d-flex mb-2'>
                                    <p className='m-0'>They pay</p>
                                    <div className='border border-dark rounded rounded-3 ms-2'>-20%</div>
                                </div>
                                <div className='d-flex'>
                                    <s>₹4,540.00</s>
                                    <p className='ms-1'><b>₹3,638.40</b></p>
                                </div>
                            </div>

                            <div className='d-flex text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                <RedeemIcon color="success" />
                                <p className='ms-2'>Your commission</p>
                                <h5 className='ms-5'>₹727.68</h5>
                            </div>

                            <div className='d-flex mt-4'>
                                <button className=' rounded p-2 bg3 btn'>
                                    <ContentCopyIcon sx={{ color: 'white' }} />
                                </button>
                                <button className=' ms-2 w-100 text-center btn text-light rounded refer pt-2 bg3'>
                                    Refer to Friend</button>
                            </div>

                        </div>






                        <div className='card col-xl-3 col-lg-5 col-md-6 col-sm-8 col-10 bg-white border rounded-4 m-3 p-3'>
                            <h2 className='text-center mt-3'>OFFLINE COURSE</h2>
                            <p className='text-center mt'>Everything you need to create your website</p>

                            <div className='mt-3'>
                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>300</b></p>
                                    <p className='ms-1'>Websites</p>
                                </div>

                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>-200 000</b></p>
                                    <p className='ms-1'>Visits Monthly</p>
                                </div>

                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>200 GB</b></p>
                                    <p className='ms-1'>NVNs Storage</p>
                                </div>

                                <div className='d-flex'>
                                    <DoneIcon color="success" />
                                    <p className='ms-2'><b>800 000</b></p>
                                    <p className='ms-1'>Files and Directories (Inodes)</p>
                                </div>
                            </div>

                            <div className='bg1 mt-3 ps-3'>
                                <div className='d-flex mb-2'>
                                    <p className='m-0'>They pay</p>
                                    <div className='border border-dark rounded rounded-3 ms-2'>-20%</div>
                                </div>
                                <div className='d-flex'>
                                    <s>₹10,188.00</s>
                                    <p className='ms-1'><b>₹8,150.40</b></p>
                                </div>
                            </div>

                            <div className='d-flex text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                <RedeemIcon color="success" />
                                <p className='ms-2'>Your commission</p>
                                <h5 className='ms-5'>₹2,852.64</h5>
                            </div>

                            <div className='d-flex mt-4'>
                                <button className='border rounded p-2 bg-white'>
                                    <ContentCopyIcon />
                                </button>
                                <button className='border ms-2 w-100 text-center bg-white rounded pt-2 refer'>
                                    Refer to Friend</button>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

               </> 
      
</Styled>

  
          
     )
}
export default  ScratchCards
const Styled = styled.div`
.bg{
   background-color:#c5cde926}
.bg1{ 
   background-color:#fafbff}

 .bg2{ 
   background-color:#D6EAF8}
.text{ 
   color:#2f1c6a}
.refer{
  color:#673de6} 
.bg3{
   background-color:#673de6}  
.bd{
  border: 1px solid #673de6 !important;
}
.mt28{
   margin-top:-28px;}  

`
