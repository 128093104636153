import { Chip } from '@material-ui/core'
import { Avatar } from '@mui/material'
import React from 'react'
import divider from '../../assets/images/bg/divider.png'
const RecentEarners = () => {
  return (
    <div>
        
        <div className="text-center">
            <h4 className="secondary-text">Our Recent Ambassadors</h4>
            <h1 className="text-primary">Proudly Worked With Us !</h1>
<div className="divider">
<img src={divider}/>

    </div>

        </div>

        <div className="container mt-3 d-flex flex-wrap">

        <div className="col-lg-3 p-3 col-md-3 col-sm-6 col-12">

<div className="shadow rounded">

<div className="px-3 pt-1 pb-3 ">
<div className="text-center">
    <Avatar className="mx-auto" sx={{height:90,width:90}} src="https://skillsuprise.com/resumes150199/667794-whatsapp-image-2022-06-30-at-7.27.59-pm.jpeg"/>

    </div>
<div className="text-center">

   <h4>Eepuru Lakshmi</h4>
<Chip label={"Earned ₹ 10,000"} size="small"/>
</div>
<hr></hr>
<p>This internship has helped me a lot to understand myself and also helped me to meet new people in my college. I also overcame stagefear while giving seminars about skillsuprise.</p>
</div>
</div>

</div>


            <div className="col-lg-3 p-3 col-md-3 col-sm-6 col-12">

<div className="shadow rounded">

<div className="px-3 pt-1 pb-3">
<div className="text-center">
    <Avatar className="mx-auto" sx={{height:90,width:90}} src="https://skillsuprise.com/resources/images/ajay.png"/>

    </div>
<div className="text-center">

   <h4>Ajay Panyam</h4>
<Chip label={"Earned ₹ 8,000"} size="small"/>
</div>
<hr></hr>
<p>I am so glad that i did this internship, along with earning some pocket money for my own, i came to understand the working culture maintained at companies. I became fluent in english speaking also.</p>
</div>
</div>

</div>

<div className="col-lg-3 p-3 col-md-3 col-sm-6 col-12">

<div className="shadow rounded">

<div className="px-3 pt-1 pb-3">
<div className="text-center">
    <Avatar className="mx-auto" sx={{height:90,width:90}} src="https://skillsuprise.com/resources/images/sowmyaswini.png"/>

    </div>
<div className="text-center">

   <h4>Sowmyaswini</h4>
<Chip label={"Earned ₹ 8,000"} size="small"/>
</div>
<hr></hr>
<p>My communication and public speaking skills helped me a lot in succeeding this internship, and with this internship i got to build so many new connections. I am grateful beacuse it helped me increase my standards. </p>
</div>
</div>

</div>


<div className="col-lg-3 p-3 col-md-3 col-sm-6 col-12">

<div className="shadow rounded">

<div className="px-3 pt-1 pb-3">
<div className="text-center">
    <Avatar className="mx-auto" sx={{height:90,width:90}} src="https://skillsuprise.com/resources/images/ramyasree.png"/>

    </div>
<div className="text-center">

   <h4>Ramya Sree</h4>
<Chip label={"Earned ₹ 8,000"} size="small"/>
</div>
<hr></hr>
<p>I feel very happy for doing this internship. I improved my speaking capacity fearlessly. My presentation skills also improved and i am able to look after my own needs with the stipend I earned.</p>
</div>
</div>

</div>

        </div>
        </div>
  )
}

export default RecentEarners